import React from 'react';
import { s } from '../../../strings/strings';
import { GridColDef } from '@mui/x-data-grid';
import { CustomGrid } from '../../common/CustomGrid';
import { useNavigate } from 'react-router-dom';

interface DashboardOutstandingREXOfTheMonthProps {
  style?: React.CSSProperties;
}

export const DashboardOutstandingREXOfTheMonth: React.FC<
  DashboardOutstandingREXOfTheMonthProps
> = ({ style }) => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: s.common.grid.id,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.id;
      },
    },
    {
      field: 'title',
      headerName: s.common.grid.title,
      flex: 5,
    },
    {
      field: 'description',
      headerName: s.common.grid.description,
      flex: 5,
    },
  ];

  return (
    <>
      <div style={{ ...style }}>
        <CustomGrid
          headerTitle={s.dashboard.grids.outstandingRexOfTheMonth}
          rows={[]}
          columns={columns}
          onRowClick={(row) => navigate('/rex/' + row.id)}
        />
      </div>
    </>
  );
};
