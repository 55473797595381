import { Tooltip } from '@mui/material';
import { smallMargin, tinyMargin } from '../../styles/styles';
import informationTooltip from '../../assets/icons/information-tooltip.svg';
import { colors, palette } from '../../styles/colors';
import { fonts } from '../../styles/fonts';

interface Props {
  text: string;
  children?: React.ReactNode;
}

export const CustomTooltip: React.FC<Props> = ({ text, children }) => {
  return (
    <>
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: palette.white,
              color: palette.black,
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: 1,
            },
          },
        }}
        title={
          <p
            style={{
              ...fonts.regular16,
              color: colors.textLight,
              margin: 0,
              padding: smallMargin,
              whiteSpace: 'pre-line',
            }}
          >
            {text}
          </p>
        }
      >
        <div style={{ display: 'flex' }}>
          {children || (
            <img
              src={informationTooltip}
              alt="Information"
              style={{ marginLeft: tinyMargin }}
            />
          )}
        </div>
      </Tooltip>
    </>
  );
};
