import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { s } from '../../strings/strings';
import { tinyMargin } from '../../styles/styles';
import informationTooltip from '../../assets/icons/information-tooltip.svg';
import React, { useState } from 'react';

interface BasicInformationDialogDialogProps {
  title?: string;
  content: React.ReactNode;
}

export const BasicInformationDialog: React.FC<
  BasicInformationDialogDialogProps
> = ({ title, content }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <img
        src={informationTooltip}
        alt=""
        style={{ marginRight: tinyMargin, cursor: 'pointer' }}
        onClick={() => {
          setOpen(true);
        }}
      />
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {s.common.button.ok}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
