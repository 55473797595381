import React, { useCallback, useEffect, useState } from 'react';
import { StatsItem, StatsOptionsDto } from '../../types/common/api';
import { DashboardStatsCard } from '../dashboard/common/DashboardStatsCard';
import { ReportType, StatsRange } from '../../types/common/main';
import { CircularProgress } from '@mui/material';
import { palette } from '../../styles/colors';
import { AxiosResponse } from 'axios';
import { largeMargin, smallMargin } from '../../styles/styles';
import { CustomBarChart } from '../common/charts/CustomBarChart';
import { s } from '../../strings/strings';
import { fonts } from '../../styles/fonts';
import { convertObjectToQueryParams } from '../../utils/main';

interface DashboardBarChartProps {
  title: string;
  fetchFunction: (queryParams: string) => Promise<AxiosResponse<any, any>>;
  entitiesIdx?: string[];
  width?: number;
  disableRexType?: boolean;
}

interface Options {
  [x: string]: boolean;
}

export const DashboardBarChart: React.FC<DashboardBarChartProps> = ({
  title,
  fetchFunction,
  entitiesIdx,
  width,
  disableRexType,
}) => {
  const [stats, setStats] = useState<StatsItem[]>([]);
  const [statsRange, setStatsRange] = useState<StatsRange>('total');
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<Options>();

  const getOptionsParamsQuery = useCallback(
    (
      range: StatsRange,
      options?: {
        [x: string]: boolean;
      }
    ) => {
      const dto: StatsOptionsDto = {
        entities_idx: entitiesIdx,
      };

      if (options) {
        const anomaly = options[ReportType.Anomaly];
        const goodPratice = options[ReportType.GoodPractice];
        if (anomaly && !goodPratice) dto.rex_type = ReportType.Anomaly;
        if (!anomaly && goodPratice) dto.rex_type = ReportType.GoodPractice;
      }
      if (range === 'twelveMonths') {
        const date = new Date();
        date.setMonth(date.getMonth() - 12);
        dto.rex_date_min = date.toISOString()?.split('T')?.shift();
      }

      return convertObjectToQueryParams(dto);
    },
    [entitiesIdx]
  );

  const fetchStats = useCallback(
    (
      range: StatsRange,
      options?: {
        [x: string]: boolean;
      }
    ) => {
      setLoading(true);
      fetchFunction(getOptionsParamsQuery(range, options))
        .then(({ data }) => {
          setStats(data);
        })
        .finally(() => setLoading(false));
    },
    [fetchFunction, getOptionsParamsQuery]
  );

  useEffect(() => {
    fetchStats(statsRange, options);
  }, [statsRange, options, fetchStats]);

  const values = stats?.map((item) => item.value) || [];
  const xLabels = stats?.map((item) => item.label) || [];

  return (
    <>
      <DashboardStatsCard
        title={title}
        rangeState={[statsRange, setStatsRange]}
        onChangeRexType={(options) => {
          setOptions(options);
        }}
        disableRexType={disableRexType}
      >
        <div style={{ padding: `${largeMargin}px ${smallMargin}px` }}>
          {loading ? (
            <CircularProgress
              style={{ color: palette.darkBlue600 }}
              size={40}
            />
          ) : values?.length > 0 && xLabels.length > 0 ? (
            <CustomBarChart data={stats} width={width} />
          ) : (
            <>
              <p style={{ ...fonts.semiBold18 }}>{s.dashboard.charts.noData}</p>
            </>
          )}
        </div>
      </DashboardStatsCard>
    </>
  );
};
