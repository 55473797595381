import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { s } from '../../strings/strings';
import table1 from '../../assets/images/matrix/table1.png';
import table2 from '../../assets/images/matrix/table2.png';

interface RiskMatrixDialogProps {
  state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

export const RiskMatrixDialog: React.FC<RiskMatrixDialogProps> = ({
  state,
}) => {
  const [open, setOpen] = state;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>{s.common.matrix.tooltipTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span style={{ whiteSpace: 'pre-line' }}>
            {s.common.matrix.tooltipPart1}
          </span>
          {/* image uri ../../assets/images/matrix/table1.png */}
          <img src={table1} alt="" style={{ width: '100%' }} />
          <span style={{ whiteSpace: 'pre-line' }}>
            {s.common.matrix.tooltipPart2}
          </span>
          <img src={table2} alt="" style={{ width: '100%' }} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          {s.common.button.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
