import { DateRange, RiskRange } from "../types/common/main";

export const getDisplayableStringFromDateRange = (range: DateRange) => {
  const beginning = range?.min?.split("-")?.reverse()?.join("/");
  const end = range?.max?.split("-")?.reverse()?.join("/");

  return `${beginning || "N/A"} - ${end || "N/A"}`;
};

export const getDisplayableStringFromIsoString = (isoString: string) => {
  return isoString.split("T")?.shift()?.split("-")?.reverse()?.join("/") || "";
};

export const getDisplayableStringFromRiskRange = (range: RiskRange) => {
  const beginning = range?.min;
  const end = range?.max;

  return beginning && end
    ? `${beginning} - ${end}`
    : beginning
    ? `>=${beginning}`
    : `<=${end}`;
};

export const dateToString = (date: Date) => {
  return date.toLocaleDateString();
  // return date
  //   ?.toISOString()
  //   ?.split("T")
  //   ?.shift()
  //   ?.split("-")
  //   ?.reverse()
  //   ?.join("/");
};
