import { colors } from "./colors";

//const fontScale = 1; //PixelRatio.getFontScale();

export const REGULAR = "Regular";
export const MEDIUM = "Medium";
export const SEMI_BOLD = "SemiBold";
export const BOLD = "Bold";
export const LIGHT = "Light";
export const ITALIC = "Italic";

const DEFAULT_TEXT_COLOR = colors.textBlack;

interface FontStyle {
  fontFamily?: string;
  fontSize?: number;
  color?: string;
}

const getFont = (
  fontFamily?: string,
  fontSize?: number,
  color = DEFAULT_TEXT_COLOR
) => {
  const font: FontStyle = {};
  if (fontFamily) font.fontFamily = fontFamily;
  if (fontSize) font.fontSize = fontSize;
  if (color) font.color = color;
  return font;
};

const regular = ({ fontSize, color }: FontStyle) =>
  getFont(REGULAR, fontSize, color);
const medium = ({ fontSize, color }: FontStyle) =>
  getFont(MEDIUM, fontSize, color);
const semiBold = ({ fontSize, color }: FontStyle) =>
  getFont(SEMI_BOLD, fontSize, color);
const bold = ({ fontSize, color }: FontStyle) => getFont(BOLD, fontSize, color);
const italic = ({ fontSize, color }: FontStyle) =>
  getFont(ITALIC, fontSize, color);

const fonts = {
  // BOLD
  bold32: bold({ fontSize: 32 }),
  bold30: bold({ fontSize: 30 }),
  bold24: bold({ fontSize: 24 }),
  bold22: bold({ fontSize: 22 }),
  bold20: bold({ fontSize: 20 }),
  bold18: bold({ fontSize: 18 }),
  bold16: bold({ fontSize: 16 }),
  bold14: bold({ fontSize: 14 }),
  bold12: bold({ fontSize: 12 }),

  // ITALIC
  italic16: italic({ fontSize: 16 }),
  italic14: italic({ fontSize: 14 }),

  // MEDIUM
  medium18: medium({ fontSize: 18 }),
  medium16: medium({ fontSize: 16 }),
  medium15: medium({ fontSize: 15 }),
  medium14: medium({ fontSize: 14 }),
  medium12: medium({ fontSize: 12 }),

  // REGULAR
  regular24: regular({ fontSize: 24 }),
  regular20: regular({ fontSize: 20 }),
  regular18: regular({ fontSize: 18 }),
  regular16: regular({ fontSize: 16 }),
  regular14: regular({ fontSize: 14 }),
  regular12: regular({ fontSize: 12 }),

  // SEMI BOLD
  semiBold24: semiBold({ fontSize: 24 }),
  semiBold20: semiBold({ fontSize: 20 }),
  semiBold18: semiBold({ fontSize: 18 }),
  semiBold16: semiBold({ fontSize: 16 }),
  semiBold14: semiBold({ fontSize: 14 }),
  semiBold12: semiBold({ fontSize: 12 }),

  // WHITE
  whiteBold26: bold({ fontSize: 26, color: colors.textWhite }),
  whiteMedium16: medium({ fontSize: 16, color: colors.textWhite }),
  whiteSemiBold18: semiBold({ fontSize: 18, color: colors.textWhite }),
  whiteSemiBold14: semiBold({ fontSize: 14, color: colors.textWhite }),

  // PRIMARY
  primaryBold12: bold({ fontSize: 12, color: colors.primary }),
};

export { fonts };
