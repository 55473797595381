import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api } from '../utils/api';
import { AxiosResponse } from 'axios';
import { Action } from '../types/common/api';
import { useMainData } from '../providers/MainDataProvider';

export const useFavoriteActionMutations = () => {
  const { isTestEnvironment } = useMainData();

  const favoriteActionsKey = [
    'favoriteActions',
    ...(isTestEnvironment ? ['test'] : []),
  ];

  const queryClient = useQueryClient();

  const { data: favoriteActions, refetch: refetchFavoriteActions } = useQuery(
    favoriteActionsKey,
    async () =>
      await api
        .get('user/favorite/action')
        .then(({ data }: AxiosResponse<Action[], any>) => data)
  );

  // Mutations
  const addFavoriteAction = useMutation(
    (id: number) => api.post(`user/favorite/action/${id}`),
    {
      onSuccess: ({ data }: AxiosResponse<Action, any>) => {
        queryClient.setQueryData(
          favoriteActionsKey,
          (oldData: Action[] | undefined) =>
            oldData ? [...oldData, data] : [data]
        );
      },
    }
  );

  const deleteFavoriteActions = useMutation(
    (ids: number[]) =>
      api.put(`user/favorite/action/delete-many`, {
        action_ids: ids,
      }),
    {
      onSuccess: ({ data }: AxiosResponse<Action[], any>, ids) => {
        queryClient.setQueryData(
          favoriteActionsKey,
          (oldData: Action[] | undefined) =>
            oldData?.filter((action) => !ids.includes(action?.id)) || []
        );
      },
    }
  );

  const deleteFavoriteAction = useMutation(
    (id: number) => api.delete(`user/favorite/action/${id}`),
    {
      onSuccess: ({ data }: AxiosResponse<Action, any>, id) => {
        queryClient.setQueryData(
          favoriteActionsKey,
          (oldData: Action[] | undefined) =>
            oldData ? oldData?.filter((element) => element.id !== id) : []
        );
      },
    }
  );

  return {
    favoriteActions,
    refetchFavoriteActions,
    addFavoriteAction,
    deleteFavoriteActions,
    deleteFavoriteAction,
  };
};
