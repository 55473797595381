import { Stack } from '@mui/material';
import { AdvancedSearchItem } from '../../../components/search/AdvancedSearchItem';
import { s } from '../../../strings/strings';
import { smallMargin } from '../../../styles/styles';
import React from 'react';
import { SearchFilters } from '../../../types/common/api';
import { getDisplayableStringFromDateRange } from '../../../utils/date';

interface CresagInfoFiltersProps {
  searchParamsState: [
    SearchFilters,
    React.Dispatch<React.SetStateAction<SearchFilters>>
  ];
  colors: { main?: string; background?: string };
}

export const CresagInfoFilters: React.FC<CresagInfoFiltersProps> = ({
  searchParamsState,
  colors,
}) => {
  const [searchParams, setSearchParams] = searchParamsState;

  return (
    <>
      <Stack
        direction="row"
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: `${smallMargin}px`,
        }}
      >
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.cresagInfo.id}
          type="number"
          value={searchParams.cresag_id}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              cresag_id: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.cresagInfo.sendDate}
          type="dateRange"
          value={searchParams.cresag_date}
          displayString={
            searchParams?.cresag_date
              ? getDisplayableStringFromDateRange(searchParams.cresag_date)
              : undefined
          }
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              cresag_date: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.cresagInfo.investigation}
          type="yesNo"
          value={searchParams.investigation}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              investigation: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.cresagInfo.createdBy}
          type="text"
          value={searchParams.cresag_created_by}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              cresag_created_by: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
      </Stack>
    </>
  );
};
