import React, { useMemo } from 'react';
import { s } from '../../../strings/strings';
import { GridColDef } from '@mui/x-data-grid';
import { CustomGrid } from '../../common/CustomGrid';
import { useQuery } from 'react-query';
import { Action } from '../../../types/common/api';
import { AxiosResponse } from 'axios';
import { api } from '../../../utils/api';
import { useNavigate } from 'react-router-dom';
import { getEntitiesStatsParam } from '../../../utils/stats';
import { convertObjectToQueryParams } from '../../../utils/main';
import { useMainData } from '../../../providers/MainDataProvider';

interface DashboardUncompleteActionsProps {
  style?: React.CSSProperties;
  selectedEntityIdx?: string;
  entityChildren?: string[];
}

export const DashboardUncompleteActions: React.FC<
  DashboardUncompleteActionsProps
> = ({ style, selectedEntityIdx, entityChildren }) => {
  const { isTestEnvironment } = useMainData();
  const navigate = useNavigate();

  const entities = useMemo(() => {
    return getEntitiesStatsParam(selectedEntityIdx, entityChildren);
  }, [selectedEntityIdx, entityChildren]);

  const uncompletedActionsKey = [
    'uncompletedActions',
    ...(isTestEnvironment ? ['test'] : []),
    ...(entities?.length ? entities : []),
  ];

  const { data: uncompletedActions } = useQuery(
    uncompletedActionsKey,
    async () =>
      api
        .get(
          `action/unfinished${convertObjectToQueryParams({
            entities_idx: entities,
          })}`
        )
        .then(({ data }: AxiosResponse<Action[], any>) => data)
  );

  const columns: GridColDef[] = [
    {
      field: 'description',
      headerName: s.common.grid.description,
      flex: 4,
    },
    {
      field: 'carrier_name',
      headerName: s.common.grid.carrier,
      flex: 1,
    },
    {
      field: 'associated_rexs',
      headerName: s.common.grid.rexCount,
      flex: 1,
      valueGetter: (params) => params?.row?._count?.rexs,
    },
  ];

  return (
    <>
      <div style={{ ...style }}>
        <CustomGrid
          headerTitle={s.dashboard.grids.uncompleteActions}
          rows={uncompletedActions || []}
          columns={columns}
          onRowClick={(row) => navigate('/action/' + row.id)}
        />
      </div>
    </>
  );
};
