import React from "react";
import { mediumRadius, smallMargin, tinyMargin } from "../../styles/styles";
import { palette } from "../../styles/colors";
import { fonts } from "../../styles/fonts";
import { s } from "../../strings/strings";
import { getRiskColor } from "../../utils/main";

interface RiskDisplayProps {
  label: string;
  severity: number;
  probability: number;
}

export const RiskDisplay: React.FC<RiskDisplayProps> = ({
  label,
  severity,
  probability,
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          gap: smallMargin,
          maxWidth: 250,
        }}
      >
        {label && (
          <p
            style={{
              ...fonts.medium14,
              color: palette.gray700,
              margin: 0,
              marginBottom: tinyMargin,
              borderColor: palette.gray200,
            }}
          >
            {label}
          </p>
        )}
        <div
          style={{
            display: "flex",
            gap: smallMargin,
            flexGrow: 1,
          }}
        >
          <LabelItem
            title={s.common.matrix.severity}
            value={severity?.toString() || ""}
          />
          <LabelItem
            title={s.common.matrix.probability}
            value={probability?.toString() || ""}
          />
        </div>
        <div
          style={{
            height: 16,
            width: "100%",
            backgroundColor: getRiskColor(severity, probability),
            borderRadius: mediumRadius,
          }}
        ></div>
      </div>
    </>
  );
};

interface LabelItemProps {
  title: string;
  value: string;
}

const LabelItem: React.FC<LabelItemProps> = ({ title, value }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: mediumRadius,
          backgroundColor: palette.gray100,
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          flexBasis: 1,
        }}
      >
        <p
          style={{
            ...fonts.regular14,
            color: palette.gray700,
            marginBottom: 0,
          }}
        >
          {title}
        </p>
        <p style={{ ...fonts.regular20, marginTop: tinyMargin }}>{value}</p>
      </div>
    </>
  );
};
