import { CircularProgress } from '@mui/material';
import { colors, palette } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import { buttonPadding, mediumRadius, tinyMargin } from '../../styles/styles';
import { useMainData } from '../../providers/MainDataProvider';

interface Props {
  title: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  iconSrc?: string | undefined;
  iconPosition?: 'left' | 'right';
  disabled?: boolean;
  loading?: boolean;
  loaderColor?: string;
  style?: React.CSSProperties | undefined;
}

export const CustomButton: React.FC<Props> = ({
  title,
  onClick,
  iconSrc,
  iconPosition = 'left',
  disabled,
  loading,
  loaderColor = 'white',
  style,
}) => {
  const { primaryColors } = useMainData();

  return (
    <>
      <button
        onClick={(e) => {
          if (onClick) {
            onClick(e);
            e.stopPropagation();
          }
        }}
        disabled={disabled}
        style={{
          ...fonts.whiteSemiBold14,
          padding: buttonPadding,
          borderRadius: mediumRadius,
          border: 'none',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          ...style,
          color: disabled ? palette.gray400 : style?.color || colors.textWhite,
          backgroundColor: disabled
            ? palette.gray200
            : style?.backgroundColor || primaryColors.primary,
        }}
      >
        {loading ? (
          <CircularProgress style={{ color: loaderColor }} size={14} />
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {iconSrc && iconPosition === 'left' && (
              <img
                src={iconSrc}
                alt=""
                style={{ marginRight: title ? tinyMargin : 0 }}
              />
            )}
            {title}
            {iconSrc && iconPosition === 'right' && (
              <img
                src={iconSrc}
                alt=""
                style={{ marginLeft: title ? tinyMargin : 0 }}
              />
            )}
          </div>
        )}
      </button>
    </>
  );
};
