import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api } from '../utils/api';
import { AxiosResponse } from 'axios';
import { Cause } from '../types/common/api';
import { useMainData } from '../providers/MainDataProvider';

export const useFavoriteCauseMutations = () => {
  const { isTestEnvironment } = useMainData();

  const favoriteCausesKey = [
    'favoriteCauses',
    ...(isTestEnvironment ? ['test'] : []),
  ];

  const queryClient = useQueryClient();

  const { data: favoriteCauses, refetch: refetchFavoriteCauses } = useQuery(
    favoriteCausesKey,
    async () =>
      await api
        .get('user/favorite/cause')
        .then(({ data }: AxiosResponse<Cause[], any>) => data)
  );

  // Mutations
  const addFavoriteCause = useMutation(
    (id: number) => api.post(`user/favorite/cause/${id}`),
    {
      onSuccess: ({ data }: AxiosResponse<Cause, any>) => {
        queryClient.setQueryData(
          favoriteCausesKey,
          (oldData: Cause[] | undefined) =>
            oldData ? [...oldData, data] : [data]
        );
      },
    }
  );

  const deleteFavoriteCauses = useMutation(
    (ids: number[]) =>
      api.put(`user/favorite/cause/delete-many`, {
        cause_ids: ids,
      }),
    {
      onSuccess: ({ data }: AxiosResponse<Cause[], any>, ids) => {
        queryClient.setQueryData(
          favoriteCausesKey,
          (oldData: Cause[] | undefined) =>
            oldData?.filter((cause) => !ids.includes(cause?.id)) || []
        );
      },
    }
  );

  const deleteFavoriteCause = useMutation(
    (id: number) => api.delete(`user/favorite/cause/${id}`),
    {
      onSuccess: ({ data }: AxiosResponse<Cause, any>, id) => {
        queryClient.setQueryData(
          favoriteCausesKey,
          (oldData: Cause[] | undefined) =>
            oldData ? oldData?.filter((element) => element.id !== id) : []
        );
      },
    }
  );

  return {
    favoriteCauses,
    refetchFavoriteCauses,
    addFavoriteCause,
    deleteFavoriteCauses,
    deleteFavoriteCause,
  };
};
