import React from 'react';
import { DashboardCharts } from './common/DashboardCharts';
import { Grid } from '@mui/material';
import { DashboardCPSStatsCard } from './cps/DashboardCPSStatsCard';
import { DashboardPerimeterCard } from './cps/DashboardPerimeterCard';
import { DashboardUnpublishedRexs } from './cps/DashboardUnpublishedRexs';
import { DashboardUncompleteActions } from './cps/DashboardUncompleteActions';

interface DashboardCPSSectionProps {
  selectedEntityIdx?: string;
  entityChildren?: string[];
}

export const DashboardCPSSection: React.FC<DashboardCPSSectionProps> = ({
  selectedEntityIdx,
  entityChildren,
}) => {
  return (
    <>
      <Grid container rowSpacing={1.5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={8}>
          <DashboardCPSStatsCard
            selectedEntityIdx={selectedEntityIdx}
            entityChildren={entityChildren}
          />
        </Grid>
        <Grid item xs={4}>
          <DashboardPerimeterCard
            selectedEntityIdx={selectedEntityIdx}
            entityChildren={entityChildren}
          />
        </Grid>
        <Grid item xs={12}>
          <DashboardUnpublishedRexs
            selectedEntityIdx={selectedEntityIdx}
            entityChildren={entityChildren}
          />
        </Grid>
        <Grid item xs={12}>
          <DashboardUncompleteActions
            selectedEntityIdx={selectedEntityIdx}
            entityChildren={entityChildren}
          />
        </Grid>
        <DashboardCharts
          selectedEntityIdx={selectedEntityIdx}
          entityChildren={entityChildren}
        />
      </Grid>
    </>
  );
};
