import React, { useState } from 'react';
import { ReportTypeChip } from '../common/chip/ReportTypeChip';
import { FlightPhaseChip } from '../common/chip/FlightPhaseChip';
import { FlightPhase } from '../../types/common/api';
import { s } from '../../strings/strings';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { CustomGrid } from '../common/CustomGrid';
import { useFavoriteRexMutations } from '../../hooks/useFavoriteRexMutations';
import { getRiskColor } from '../../utils/main';
import { mediumRadius } from '../../styles/styles';
import bookmarkSlashIcon from '../../assets/icons/bookmark-slash.svg';
import { CustomButton } from '../common/CustomButton';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { useNavigate } from 'react-router-dom';
import { useMainData } from '../../providers/MainDataProvider';
import { CustomTooltip } from '../common/CustomTooltip';

interface FavoritesRexsSectionProps {
  style?: React.CSSProperties;
  apiRef: React.MutableRefObject<GridApiCommunity>;
  rowSelectionState: [
    GridRowSelectionModel,
    React.Dispatch<React.SetStateAction<GridRowSelectionModel>>
  ];
}

export const FavoritesRexsSection: React.FC<FavoritesRexsSectionProps> = ({
  style,
  apiRef,
  rowSelectionState,
}) => {
  const { primaryColors } = useMainData();
  const navigate = useNavigate();
  const { favoriteRexs, deleteFavoriteRex } = useFavoriteRexMutations();
  const [isLoadingBookmarkId, setIsLoadingBookmarkId] = useState<number>();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: s.common.grid.id,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.id;
      },
    },
    {
      field: 'title',
      headerName: s.common.grid.title,
      flex: 2,
    },
    {
      field: 'rex_type',
      headerName: s.common.grid.rexType,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.rex_type ? (
          <ReportTypeChip reportType={row.rex_type} />
        ) : (
          <p>N/A</p>
        );
      },
    },
    {
      field: 'event_date',
      headerName: s.common.grid.date,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        const eventDateString = row?.event_date;
        const date = eventDateString?.split('-')?.reverse()?.join('/');
        return date;
      },
    },
    {
      field: 'flight_phase',
      headerName: s.rex.reportToProcess.grid.flightPhase,
      renderCell: (params) => {
        const row = params.row;
        const flightPhase: FlightPhase = row?.flight_phase;
        return flightPhase ? (
          <FlightPhaseChip flightPhase={flightPhase} />
        ) : (
          <p>N/A</p>
        );
      },
      flex: 1,
    },
    {
      field: 'flight_kind',
      headerName: s.common.grid.flightKind,
      flex: 1,
    },
    {
      field: 'flight_type',
      headerName: s.common.grid.flightType,
      flex: 1,
    },
    {
      field: 'event_kind',
      headerName: s.common.grid.classification,
      flex: 1,
    },
    {
      field: 'initial_risk_probability',
      headerName: s.common.grid.risk,
      renderCell: (params) => {
        const row = params.row;
        const severity: number = row?.initial_risk_severity;
        const probability: number = row?.initial_risk_probability;
        return (
          <>
            <div
              style={{
                display: 'flex',
                backgroundColor: getRiskColor(severity, probability),
                borderRadius: mediumRadius,
                height: 18,
                width: 18,
              }}
            ></div>
          </>
        );
      },
      flex: 0.5,
    },
    {
      field: 'actions',
      headerName: '',
      flex: 0.5,
      renderCell: (params) => {
        const row = params.row;
        const rexId = row?.id;

        return (
          <CustomTooltip text={s.common.tooltip.removeFromFavorites}>
            <CustomButton
              title={''}
              style={{
                backgroundColor: 'transparent',
              }}
              loading={isLoadingBookmarkId === rexId}
              loaderColor={primaryColors.primary}
              iconSrc={bookmarkSlashIcon}
              iconPosition="right"
              onClick={() => {
                setIsLoadingBookmarkId(rexId);
                deleteFavoriteRex.mutateAsync(rexId).finally(() => {
                  setIsLoadingBookmarkId(undefined);
                });
              }}
            />
          </CustomTooltip>
        );
      },
    },
  ];

  return (
    <>
      <div style={{ ...style }}>
        <CustomGrid
          apiRef={apiRef}
          rows={favoriteRexs || []}
          columns={columns}
          rowSelectionState={rowSelectionState}
          onRowClick={(row) => navigate('/rex/' + row.id)}
        />
      </div>
    </>
  );
};
