import { GridColDef } from '@mui/x-data-grid';
import { BasicPage } from '../../components/templates/BasicPage';
import { s } from '../../strings/strings';
import { palette } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import {
  cardPadding,
  defaultMargin,
  largeMargin,
  mediumRadius,
  smallMargin,
  tinyMargin,
} from '../../styles/styles';
import { CustomButton } from '../../components/common/CustomButton';
import editIcon from '../../assets/icons/pen-and-paper.svg';
import arrowRightIcon from '../../assets/icons/arrow-narrow-right.svg';
import bellIcon from '../../assets/icons/bell.svg';
import greenBellIcon from '../../assets/icons/green-bell.svg';
import penWritingIcon from '../../assets/icons/pen-writing.svg';
import classicPenIcon from '../../assets/icons/classic-pen.svg';
import sendIcon from '../../assets/icons/send.svg';
import { Stack } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { CustomGrid } from '../../components/common/CustomGrid';
import { FlightPhaseChip } from '../../components/common/chip/FlightPhaseChip';
import { FlightPhase, RexHomeStatsDto } from '../../types/common/api';
import { ReportTypeChip } from '../../components/common/chip/ReportTypeChip';
import { useModifiedRexsMutations } from '../../hooks/useModifiedRexsMutations';
import { useEffect, useState } from 'react';
import { api } from '../../utils/api';
import { AxiosResponse } from 'axios';
import { useMainData } from '../../providers/MainDataProvider';
import { useAuth } from '../../providers/AuthProvider';
import { RexStatusList } from '../../types/common/main';
import { CustomTooltip } from '../../components/common/CustomTooltip';

export const RexHomePage = () => {
  const { hasCPSPermission } = useAuth();
  const { primaryColors, isTestEnvironment } = useMainData();
  const { modifiedRexs } = useModifiedRexsMutations();
  const navigate = useNavigate();

  const [homeStats, setHomeStats] = useState<RexHomeStatsDto>();

  useEffect(() => {
    api
      .get(`rex/home-stats`)
      .then(({ data }: AxiosResponse<RexHomeStatsDto, any>) =>
        setHomeStats(data)
      );
  }, []);

  const getRowClickDestination = (row: any) => {
    const rexId = row?.id;
    const status = row?.rex_status?.name;
    const isReady =
      status === RexStatusList.WaitingForValidation ||
      status === RexStatusList.Ready;

    return isReady ? `${rexId}` : `complete/${rexId}`;
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: s.common.grid.id,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.id;
      },
    },
    {
      field: 'title',
      headerName: s.common.grid.title,
      flex: 2,
    },
    {
      field: 'event_date',
      headerName: s.common.grid.date,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        const eventDateString = row?.event_date;
        const date = eventDateString?.split('-')?.reverse()?.join('/');
        return date;
      },
    },
    {
      field: 'flight_phase',
      headerName: s.rex.reportToProcess.grid.flightPhase,
      renderCell: (params) => {
        const row = params.row;
        const flightPhase: FlightPhase = row?.flight_phase;
        return flightPhase ? (
          <FlightPhaseChip flightPhase={flightPhase} />
        ) : (
          <p>N/A</p>
        );
      },
      flex: 1,
    },
    {
      field: 'rex_type',
      headerName: s.common.grid.rexType,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.rex_type ? (
          <ReportTypeChip reportType={row.rex_type} />
        ) : (
          <p>N/A</p>
        );
      },
    },
    {
      field: 'flight_type',
      headerName: s.common.grid.flightType,
      flex: 1,
    },
    {
      field: 'event_kind',
      headerName: s.common.grid.classification,
      flex: 1,
    },
    {
      field: 'edit',
      headerName: '',
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return (
          <>
            <CustomTooltip text={s.common.tooltip.edit}>
              <img
                src={classicPenIcon}
                alt="Edit icon"
                onClick={() => navigate(getRowClickDestination(row))}
                style={{ cursor: 'pointer', padding: smallMargin }}
              />
            </CustomTooltip>
          </>
        );
      },
    },
  ];

  return (
    <>
      <BasicPage style={{ backgroundColor: palette.gray50 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ ...fonts.bold30, margin: 0 }}>
              {s.rex.home.rexManagement}
            </p>
            <p
              style={{
                ...fonts.regular16,
                color: palette.gray600,
                marginTop: tinyMargin,
              }}
            >
              {s.rex.home.subtitle}
            </p>
          </div>
          {hasCPSPermission && (
            <CustomButton
              title={s.rex.home.createRex}
              onClick={() => {
                navigate('new');
              }}
              iconSrc={editIcon}
            />
          )}
        </div>
        <div
          style={{
            marginTop: largeMargin,
            marginLeft: largeMargin,
            marginRight: largeMargin,
          }}
        >
          <Stack
            spacing={`${defaultMargin}px`}
            direction="row"
            style={{ alignItems: 'center' }}
          >
            <Card
              value={`${homeStats?.assignedToMe ?? '-'}`}
              description={s.rex.home.reportToProcess}
              iconSrc={isTestEnvironment ? greenBellIcon : bellIcon}
              color={primaryColors.primary}
              iconBackgroundColor={
                isTestEnvironment ? palette.success50 : palette.darkBlue50
              }
              linkTo="process"
            />
            <Card
              value={`${homeStats?.rexsToComplete ?? '-'}`}
              description={s.rex.home.rexToComplete}
              iconSrc={penWritingIcon}
              color={palette.warning600}
              iconBackgroundColor={palette.warning50}
              linkTo="complete"
            />
            <Card
              value={`${homeStats?.rexsToPublish ?? '-'}`}
              description={s.rex.home.rexToPublish}
              iconSrc={sendIcon}
              color={palette.violet600}
              iconBackgroundColor={palette.violet50}
              linkTo="publish"
            />
          </Stack>
          {hasCPSPermission && (
            <>
              <div
                style={{ marginTop: largeMargin, marginBottom: largeMargin }}
              >
                <p style={{ ...fonts.bold20, margin: 0 }}>
                  {s.rex.home.myLastsUpdatedRex}
                </p>
              </div>
              <CustomGrid
                rows={modifiedRexs || []}
                columns={columns}
                onRowClick={(row) => {
                  navigate(getRowClickDestination(row?.row));
                }}
              />
            </>
          )}
        </div>
      </BasicPage>
    </>
  );
};

interface CardProps {
  value: string;
  description: string;
  iconSrc: string | undefined;
  iconBackgroundColor: string;
  color: string;
  linkTo: string;
}

const Card: React.FC<CardProps> = ({
  value,
  description,
  iconSrc,
  iconBackgroundColor,
  color,
  linkTo,
}) => {
  return (
    <Link
      to={linkTo}
      style={{
        border: `1px solid ${palette.gray200}`,
        borderRadius: smallMargin,
        display: 'flex',
        padding: cardPadding,
        width: '100%',
        textDecoration: 'none',
        cursor: 'pointer',
        backgroundColor: palette.white,
      }}
    >
      <div
        style={{
          backgroundColor: iconBackgroundColor,
          width: 56,
          height: 56,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: mediumRadius,
          marginRight: cardPadding,
        }}
      >
        <img src={iconSrc} alt="" />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          flexGrow: 1,
          marginRight: cardPadding,
        }}
      >
        <p style={{ ...fonts.bold32, color: color, margin: 0 }}>{value}</p>
        <p
          style={{
            ...fonts.medium14,
            color: color,
            margin: 0,
            marginTop: tinyMargin,
          }}
        >
          {description}
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          alignSelf: 'flex-end',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            ...fonts.bold12,
            color: palette.gray400,
            margin: 0,
            marginRight: tinyMargin,
          }}
        >
          {s.common.button.see?.toUpperCase()}
        </p>
        <img src={arrowRightIcon} alt="" style={{ marginRight: tinyMargin }} />
      </div>
    </Link>
  );
};
