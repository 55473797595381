import { s } from '../../strings/strings';
import { palette } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import {
  buttonPadding,
  largerMargin,
  mediumRadius,
  smallMargin,
  tinyMargin,
} from '../../styles/styles';
import React, { useState } from 'react';
import { CustomTooltip } from './CustomTooltip';
import eyeIcon from '../../assets/icons/eye.svg';
import eyeCrossedIcon from '../../assets/icons/eye-crossed.svg';

interface Props {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  optional?: boolean;
  optionalText?: string;
  numberOfLines?: number;
  maxLength?: number;
  multiline?: boolean;
  state: [any, React.Dispatch<React.SetStateAction<any>>];
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  type?: React.HTMLInputTypeAttribute;
  name?: string;
  isError?: boolean;
  min?: string | number;
  max?: string | number;
  tooltip?: string;
  autoComplete?: string;
  columnGap?: number;
}

export const CustomTextInput: React.FC<Props> = ({
  label,
  placeholder = '',
  disabled,
  optional,
  optionalText,
  multiline,
  maxLength,
  numberOfLines,
  state,
  style,
  inputStyle,
  type,
  name,
  min,
  max,
  isError,
  tooltip,
  autoComplete,
  columnGap,
}) => {
  const [value, setValue] = state;
  const [showPassword, setShowPassword] = useState(false);

  const paddingRight = buttonPadding + (maxLength ? largerMargin : 0);
  const paddingLeft = buttonPadding;

  return (
    <>
      <div style={{ width: '100%', position: 'relative', ...style }}>
        {label && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              columnGap: columnGap !== undefined ? columnGap : smallMargin,
              marginBottom: tinyMargin,
              position: 'relative',
            }}
          >
            <p
              style={{
                ...fonts.medium14,
                color: palette.gray700,
                margin: 0,
                marginBottom: 0,
                borderColor: isError ? palette.error300 : palette.gray200,
              }}
            >
              {label}
              {optional && (
                <span
                  style={{
                    ...fonts.italic14,
                    color: palette.gray500,
                    margin: 0,
                    marginBottom: 0,
                    borderColor: isError ? palette.error300 : palette.gray200,
                  }}
                >
                  {` (${optionalText ? optionalText : s.common.optional})`}
                </span>
              )}
            </p>
            {tooltip && <CustomTooltip text={tooltip} />}
          </div>
        )}
        {multiline ? (
          <textarea
            value={value || ''}
            onChange={(e) => setValue(e.target.value)}
            disabled={disabled}
            style={{
              ...styles.input,
              borderColor: isError ? palette.error300 : palette.gray200,
              resize: 'vertical',
              paddingLeft: paddingLeft,
              paddingRight: paddingRight,
              width: `calc(100% - ${paddingLeft + paddingRight}px)`,
              ...inputStyle,
            }}
            placeholder={placeholder}
            rows={numberOfLines}
            maxLength={maxLength}
            name={name}
            autoComplete={autoComplete}
          />
        ) : (
          <div style={{ position: 'relative' }}>
            <input
              name={name}
              autoComplete={autoComplete}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              disabled={disabled}
              style={{
                ...styles.input,
                borderColor: isError ? palette.error300 : palette.gray200,
                color: disabled ? palette.gray500 : palette.black,
                paddingLeft: paddingLeft,
                paddingRight: paddingRight,
                width: `calc(100% - ${paddingLeft + paddingRight}px)`,
                ...inputStyle,
              }}
              type={showPassword ? 'text' : type}
              placeholder={placeholder}
              maxLength={maxLength || 175}
              min={min}
              max={max}
            />
            {type === 'password' && (
              <div
                style={{
                  height: 20,
                  position: 'absolute',
                  right: smallMargin,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                }}
              >
                <CustomTooltip
                  text={
                    showPassword
                      ? s.common.tooltip.hidePassword
                      : s.common.tooltip.showPassword
                  }
                >
                  <img
                    src={showPassword ? eyeCrossedIcon : eyeIcon}
                    alt="Toggle password visibility"
                    style={{ height: 20 }}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </CustomTooltip>
              </div>
            )}
          </div>
        )}
        {maxLength && (
          <p
            style={{
              ...fonts.regular14,
              backgroundColor: palette.white,
              position: 'absolute',
              margin: 0,
              right: tinyMargin,
              bottom: smallMargin,
              color: palette.gray400,
              alignSelf: 'flex-end',
            }}
          >
            {value?.length}/{maxLength}
          </p>
        )}
      </div>
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  input: {
    ...fonts.regular16,
    padding: buttonPadding,
    borderRadius: mediumRadius,
    backgroundColor: palette.white,
    borderWidth: 1,
    borderStyle: 'solid',
  },
};
