// export const vw = (value) => (windowWidth / 100) * value;
// export const vh = (value) => (windowHeight / 100) * value;
// //const pixelRatio = PixelRatio.get();
// export const windowWidth = Dimensions.get("window").width;
// export const windowHeight = Dimensions.get("window").height;

export const stdHMargin = 15;
export const stdVMargin = 20;
export const smallRadius = 5;
export const mediumRadius = 10;
export const largeRadius = 20;
export const largerRadius = 40;
export const margin5 = 5;
export const margin10 = 10;
export const margin15 = 15;
export const margin20 = 20;
export const tinyMargin = 5;
export const smallMargin = 10;
export const defaultMargin = 20;
export const mediumMargin = 30;
export const largeMargin = 40;
export const largerMargin = 60;
export const marginAboveTitle = 30;
export const marginBelowConfirmButton = 40;
export const buttonPadding = 14;
export const cardPadding = 16;
