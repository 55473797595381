import { Modal } from '@mui/material';
import React from 'react';
import { palette } from '../../styles/colors';
import { defaultMargin, mediumRadius } from '../../styles/styles';
import closeIcon from '../../assets/icons/close.svg';

interface CustomModalProps {
  open: boolean;
  onClose: () => void;
  closeCross?: boolean;
  children: any;
  style?: React.CSSProperties;
}

export const CustomModal: React.FC<CustomModalProps> = ({
  open,
  onClose,
  closeCross = true,
  children,
  style,
}) => {
  return (
    <>
      <Modal open={open} onClose={onClose}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: palette.white,
            borderRadius: mediumRadius,
            padding: defaultMargin,
            ...style,
          }}
        >
          <div style={{ position: 'relative' }}>
            {closeCross && (
              <img
                src={closeIcon}
                alt="close"
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  cursor: 'pointer',
                }}
                onClick={onClose}
              />
            )}
            {children}
          </div>
        </div>
      </Modal>
    </>
  );
};
