import React, { useState } from 'react';
import { fonts } from '../../styles/fonts';
import { palette } from '../../styles/colors';
import { mediumRadius, smallMargin, tinyMargin } from '../../styles/styles';
import greenCloseIcon from '../../assets/icons/green-close.svg';
import blueCloseIcon from '../../assets/icons/blue-close.svg';
import yellowCloseIcon from '../../assets/icons/yellow-close.svg';
import purpleCloseIcon from '../../assets/icons/purple-close.svg';
import { CustomButton } from '../common/CustomButton';
import { CustomSearchFilterModal } from './CustomSearchFilterModal';
import { SearchFilterType } from '../../types/common/main';
import { useMainData } from '../../providers/MainDataProvider';
import { CustomTooltip } from '../common/CustomTooltip';
import { s } from '../../strings/strings';

interface AdvancedSearchItemProps {
  title: string;
  value?: any;
  displayString?: string;
  onClose?: (value: any) => void;
  type?: SearchFilterType;
  inputModalPlaceholder?: string;
  color?: string;
  backgroundColor?: string;
}

export const AdvancedSearchItem: React.FC<AdvancedSearchItemProps> = ({
  title,
  value,
  displayString,
  onClose,
  type,
  inputModalPlaceholder,
  color = palette.darkBlue600,
  backgroundColor = palette.darkBlue50,
}) => {
  const { isTestEnvironment } = useMainData();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const valueToDisplay = displayString || value;

  return (
    <>
      <div
        style={{
          display: 'flex',
          padding: `${smallMargin}px ${smallMargin + tinyMargin}px`,
          borderRadius: mediumRadius,
          backgroundColor: valueToDisplay ? backgroundColor : palette.white,
          border: `1px solid ${valueToDisplay ? color : palette.gray200}`,
          cursor: 'pointer',
        }}
        onClick={() => setIsModalOpen(true)}
      >
        {valueToDisplay ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div>
              <p
                style={{
                  ...(valueToDisplay ? fonts.semiBold12 : fonts.regular14),
                  color: color,
                  margin: 0,
                }}
              >
                {title}
              </p>
              {valueToDisplay && (
                <p
                  style={{
                    ...fonts.regular14,
                    color: color,
                    margin: 0,
                  }}
                >
                  {valueToDisplay}
                </p>
              )}
            </div>
            {valueToDisplay && (
              <CustomTooltip text={s.common.tooltip.deleteFilter}>
                <CustomButton
                  title=""
                  iconSrc={
                    color === palette.warning600
                      ? yellowCloseIcon
                      : color === palette.violet600
                      ? purpleCloseIcon
                      : isTestEnvironment
                      ? greenCloseIcon
                      : blueCloseIcon
                  }
                  onClick={() => {
                    if (onClose) onClose(null);
                  }}
                  style={{
                    marginLeft: tinyMargin,
                    backgroundColor: 'transparent',
                    padding: 0,
                  }}
                />
              </CustomTooltip>
            )}
          </div>
        ) : (
          <p style={{ ...fonts.regular14, color: palette.gray800, margin: 0 }}>
            {title}
          </p>
        )}
      </div>
      <CustomSearchFilterModal
        title={title}
        open={isModalOpen}
        defaultValue={value || ''}
        onClose={(value: any) => {
          if (onClose) onClose(value);
          setIsModalOpen(false);
        }}
        type={type}
        placeholder={inputModalPlaceholder}
      />
    </>
  );
};
