import { Stack } from "@mui/material";
import { AdvancedSearchItem } from "../AdvancedSearchItem";
import { s } from "../../../strings/strings";
import { smallMargin } from "../../../styles/styles";
import { SearchFilters } from "../../../types/common/api";
import React from "react";

interface CauseInfoFiltersProps {
  searchParamsState: [
    SearchFilters,
    React.Dispatch<React.SetStateAction<SearchFilters>>
  ];
  colors: { main?: string; background?: string };
}

export const CauseInfoFilters: React.FC<CauseInfoFiltersProps> = ({
  searchParamsState,
  colors,
}) => {
  const [searchParams, setSearchParams] = searchParamsState;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: `${smallMargin}px`,
        }}
      >
        <Stack
          direction="row"
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: `${smallMargin}px`,
          }}
        >
          <AdvancedSearchItem
            title={s.search.advancedSearchParams.causeInfo.number}
            type="number"
            value={searchParams.cause_id}
            onClose={(value: any) => {
              setSearchParams((oldParams) => ({
                ...oldParams,
                cause_id: value,
              }));
            }}
            backgroundColor={colors.background}
            color={colors.main}
          />
          <AdvancedSearchItem
            title={s.search.advancedSearchParams.causeInfo.type}
            type="causeType"
            inputModalPlaceholder={s.common.placeholders.type}
            value={searchParams.cause_type}
            onClose={(value: any) => {
              setSearchParams((oldParams) => ({
                ...oldParams,
                cause_type: value,
              }));
            }}
            backgroundColor={colors.background}
            color={colors.main}
          />
          <AdvancedSearchItem
            title={s.search.advancedSearchParams.causeInfo.title}
            type="text"
            inputModalPlaceholder={s.common.placeholders.title}
            value={searchParams.cause_title}
            onClose={(value: any) => {
              setSearchParams((oldParams) => ({
                ...oldParams,
                cause_title: value,
              }));
            }}
            backgroundColor={colors.background}
            color={colors.main}
          />
          <AdvancedSearchItem
            title={s.search.advancedSearchParams.causeInfo.description}
            type="text"
            inputModalPlaceholder={s.common.placeholders.description}
            value={searchParams.cause_description}
            onClose={(value: any) => {
              setSearchParams((oldParams) => ({
                ...oldParams,
                cause_description: value,
              }));
            }}
            backgroundColor={colors.background}
            color={colors.main}
          />
        </Stack>
      </div>
    </>
  );
};
