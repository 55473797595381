import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth } from '../providers/AuthProvider';
import { api } from '../utils/api';
import { Rex, RexDraft } from '../types/common/api';
import { AxiosResponse } from 'axios';
import { RexStatusList } from '../types/common/main';
import { useMainData } from '../providers/MainDataProvider';

export const useRexsToCompleteMutations = () => {
  const { isTestEnvironment } = useMainData();
  const { user } = useAuth();

  const rexsToCompleteKey = [
    'rexsToCompleteKey',
    user?.id,
    ...(isTestEnvironment ? ['test'] : []),
  ];

  const queryClient = useQueryClient();

  const { data: rexsToComplete, refetch: refetchReportsToComplete } = useQuery(
    rexsToCompleteKey,
    async () =>
      await api
        .get('rex/unfinished')
        .then(({ data }: AxiosResponse<Rex[], any>) => data)
  );

  // Mutations
  const createRexToComplete = useMutation(
    (rex: RexDraft) => {
      const formData = new FormData();

      if (rex?.images) {
        let uploaded_images: string[] = []; // Images qui ont déjà étaient upload une fois sur le serveur
        rex?.images?.forEach((image) => {
          const fileUri = image.uri?.replace(/\\/g, '/');

          if (fileUri.startsWith('uploads/')) {
            uploaded_images.push(fileUri);
          } else {
            if (image?.file) {
              formData.append('images', image?.file as any);
            }
          }
        });

        if (uploaded_images && uploaded_images?.length > 0) {
          formData.append('uploaded_images', JSON.stringify(uploaded_images));
        }

        delete rex.images;
      }

      if (rex.causes_data) {
        formData.append('causes_data', JSON.stringify(rex.causes_data));
        delete rex.causes_data;
      }

      Object.entries(rex)?.forEach(([key, value]) => {
        if (value) formData.append(key, value);
      });

      return api.post(`/rex`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    {
      onSuccess: ({ data: rex }: AxiosResponse<Rex, any>) => {
        queryClient.setQueryData(rexsToCompleteKey, (rexs: Rex[] | undefined) =>
          rexs ? [...rexs, rex] : [rex]
        );
      },
    }
  );

  const updateRexToComplete = useMutation(
    async ({ id, data }: { id: number; data: RexDraft }) => {
      const formData = new FormData();

      if (data?.images) {
        let uploaded_images: string[] = []; // Images qui ont déjà étaient upload une fois sur le serveur
        data?.images?.forEach(async (image) => {
          const fileUri = image.uri?.replace(/\\/g, '/');

          if (fileUri.startsWith('uploads/')) {
            uploaded_images.push(fileUri);
          } else {
            if (image?.file) {
              formData.append('images', image?.file as any);
            }
          }
        });

        if (uploaded_images && uploaded_images?.length > 0) {
          formData.append('uploaded_images', JSON.stringify(uploaded_images));
        }

        delete data.images;
      }

      if (data.causes_data) {
        formData.append('causes_data', JSON.stringify(data.causes_data));
        delete data.causes_data;
      }

      Object.entries(data)?.forEach(([key, value]) => {
        if (value) formData.append(key, value);
      });

      return api.put(`rex/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    {
      onSuccess: ({ data: updatedRex }: AxiosResponse<Rex, any>) => {
        // Update local data
        queryClient.setQueryData(
          rexsToCompleteKey,
          (rexs: Rex[] | undefined) =>
            rexs
              ?.map((rex) =>
                rex.id === updatedRex.id ? Object.assign(rex, updatedRex) : rex
              )
              ?.filter(
                (rex) => !(rex?.rex_status?.name === RexStatusList.Published)
              ) || []
        );
      },
    }
  );

  const completeRex = useMutation(
    (id: number) => api.get(`rex/${id}/complete`),
    {
      onSuccess: ({ data: updatedRex }: AxiosResponse<Rex, any>, id) => {
        // Update local data
        queryClient.setQueryData(
          rexsToCompleteKey,
          (rexs: Rex[] | undefined) =>
            rexs?.filter((rex) => rex?.id !== id) || []
        );
      },
    }
  );

  const reopenRex = useMutation((id: number) => api.get(`rex/${id}/reopen`), {
    onSuccess: ({ data: updatedRex }: AxiosResponse<Rex, any>, id) => {
      // Update local data
      queryClient.setQueryData(rexsToCompleteKey, (rexs: Rex[] | undefined) =>
        rexs ? [...rexs, updatedRex] : [updatedRex]
      );
    },
  });

  const removeRexToComplete = useMutation(
    (id: number) => api.delete(`rex/${id}`),
    {
      onSuccess: ({ data: deletedRex }: AxiosResponse<Rex, any>) => {
        // Update local data
        queryClient.setQueryData(rexsToCompleteKey, (rexs: Rex[] | undefined) =>
          rexs ? rexs?.filter((rex) => rex.id !== deletedRex.id) : []
        );
      },
    }
  );

  return {
    rexsToComplete,
    refetchReportsToComplete,
    createRexToComplete,
    updateRexToComplete,
    completeRex,
    reopenRex,
    removeRexToComplete,
  };
};
