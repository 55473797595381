import { useEffect, useState } from 'react';
import { SearchFilters } from '../types/common/api';
import { SearchType } from '../types/common/main';
import { useMainData } from '../providers/MainDataProvider';

interface CreateSavedSearch {
  params: SearchFilters;
  title: string;
  type: SearchType;
}

interface SavedSearch {
  id: number;
  params: SearchFilters;
  title: string;
  type: SearchType;
}

export const useSavedSearches = () => {
  const { isTestEnvironment } = useMainData();
  const [savedSearches, setSavedSearches] = useState<SavedSearch[]>([]);

  const TOKEN_KEY =
    'rexffa_saved_searches' + (isTestEnvironment ? '_test' : '');

  useEffect(() => {
    getSavedSearches();
  }, []);

  const getSavedSearches = () => {
    const searches = window.localStorage.getItem(TOKEN_KEY);
    if (searches) {
      const parsedSearches: SavedSearch[] = JSON.parse(searches);
      setSavedSearches(parsedSearches);
      return parsedSearches;
    }
    return [];
  };

  const addSavedSearch = (data: CreateSavedSearch) => {
    const updatedData = { ...data, id: new Date().getTime() };
    const searches = getSavedSearches();
    let updatedSearches = [];

    if (searches?.length > 0) updatedSearches = [...searches, updatedData];
    else updatedSearches = [updatedData];

    window.localStorage.setItem(TOKEN_KEY, JSON.stringify(updatedSearches));
    setSavedSearches(updatedSearches);
  };

  const removeSavedSearch = (id: number) => {
    const searches = getSavedSearches();
    const updatedSearches = searches.filter((search) => search.id !== id);
    window.localStorage.setItem(TOKEN_KEY, JSON.stringify(updatedSearches));
    setSavedSearches(updatedSearches);
  };

  return { savedSearches, getSavedSearches, addSavedSearch, removeSavedSearch };
};
