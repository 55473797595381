import { useEffect, useState } from "react";
import { FlightPhase } from "../types/common/api";
import { api } from "../utils/api";
import { AxiosResponse } from "axios";

export const useFlightPhases = () => {
  const [flightPhases, setFlightPhases] = useState<FlightPhase[]>();

  useEffect(() => {
    api
      .get(`/flight-phase`)
      .then(({ data }: AxiosResponse<FlightPhase[], any>) => {
        setFlightPhases(data);
      });
  }, []);

  return { flightPhases };
};
