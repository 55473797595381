import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { ReportType } from "../../types/common/main";
import { fonts } from "../../styles/fonts";
import { palette } from "../../styles/colors";
import { defaultMargin, mediumRadius, tinyMargin } from "../../styles/styles";
import { s } from "../../strings/strings";

interface RexTypeSelectorProps {
  state: [string, React.Dispatch<React.SetStateAction<string>>];
  style?: React.CSSProperties;
}

export const RexTypeSelector: React.FC<RexTypeSelectorProps> = ({
  state,
  style,
}) => {
  const [value, setValue] = state;

  return (
    <>
      <div
        style={{
          width: "100%",
          position: "relative",
          ...style,
        }}
      >
        <p
          style={{
            ...fonts.medium14,
            color: palette.gray700,
            margin: 0,
            marginBottom: tinyMargin,
          }}
        >
          {s.rex.newRex.generalInformation.rexType}
        </p>
        <FormControl
          style={{
            display: "flex",
            flexGrow: 1,
            border: `1px solid ${palette.gray200}`,
            borderRadius: mediumRadius,
            padding: `2px ${defaultMargin}px`,
          }}
        >
          <RadioGroup
            row
            name="rex-type-selector"
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setValue((event.target as HTMLInputElement).value);
            }}
          >
            <FormControlLabel
              value={ReportType.Anomaly}
              control={<Radio />}
              label={ReportType.Anomaly}
            />
            <FormControlLabel
              value={ReportType.GoodPractice}
              control={<Radio />}
              label={ReportType.GoodPractice}
            />
          </RadioGroup>
        </FormControl>
      </div>
    </>
  );
};
