import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRowIdGetter,
  GridRowSelectionModel,
  frFR,
} from '@mui/x-data-grid';
import React from 'react';
import { fonts } from '../../styles/fonts';
import { palette } from '../../styles/colors';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { Stack } from '@mui/material';
import { s } from '../../strings/strings';
import { smallMargin } from '../../styles/styles';

function NoRowsOverlay() {
  return (
    <Stack
      style={{ height: '100%' }}
      alignItems="center"
      justifyContent="center"
    >
      <p
        style={{
          ...fonts.regular14,
          color: palette.gray600,
        }}
      >
        {s.common.grid.noDataToShow}
      </p>
    </Stack>
  );
}

interface CustomGridProps {
  headerTitle?: string;
  rows: readonly any[];
  columns: GridColDef<any>[];
  style?: React.CSSProperties;
  onRowClick?: GridEventListener<'rowClick'> | undefined;
  rowSelectionState?: [
    GridRowSelectionModel,
    React.Dispatch<React.SetStateAction<GridRowSelectionModel>>
  ];
  toolbar?: React.JSXElementConstructor<any> | null | undefined;
  getRowId?: GridRowIdGetter<any> | undefined;
  apiRef?: React.MutableRefObject<GridApiCommunity>;
}

export const CustomGrid: React.FC<CustomGridProps> = ({
  headerTitle,
  rows,
  columns,
  style,
  onRowClick,
  rowSelectionState,
  toolbar,
  getRowId,
  apiRef,
}) => {
  const headerComponent = headerTitle
    ? () => (
        <div
          style={{
            display: 'flex',
            marginTop: 0,
            marginRight: smallMargin,
            marginBottom: 0,
            marginLeft: smallMargin,
          }}
        >
          <p style={{ ...fonts.semiBold18 }}>{headerTitle}</p>
        </div>
      )
    : undefined;

  return (
    <>
      <DataGrid
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        apiRef={apiRef}
        slots={{
          toolbar: toolbar || headerComponent,
          noRowsOverlay: NoRowsOverlay,
        }}
        rows={rows}
        columns={columns}
        disableColumnFilter
        disableRowSelectionOnClick
        checkboxSelection={!!rowSelectionState}
        onRowSelectionModelChange={
          rowSelectionState
            ? (newRowSelectionModel) => {
                rowSelectionState[1](newRowSelectionModel);
              }
            : undefined
        }
        getRowId={getRowId}
        keepNonExistentRowsSelected
        rowSelectionModel={rowSelectionState ? rowSelectionState[0] : undefined}
        sx={{
          '& .MuiDataGrid-cell': {
            ...fonts.regular14,
            color: palette.gray600,
          },
          '& .MuiDataGrid-columnHeaders': {
            ...fonts.medium12,
            color: palette.gray600,
            backgroundColor: palette.gray50,
          },
          '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within':
            {
              outline: 'none',
            },
          '.MuiDataGrid-overlayWrapper': {
            height: 50,
          },
        }}
        style={{ ...style }}
        onRowClick={onRowClick}
        hideFooter
      />
    </>
  );
};
