import { Stack } from "@mui/material";
import { AdvancedSearchItem } from "../../../components/search/AdvancedSearchItem";
import { s } from "../../../strings/strings";
import { smallMargin } from "../../../styles/styles";
import React from "react";
import { SearchFilters } from "../../../types/common/api";
import { getDisplayableStringFromRiskRange } from "../../../utils/date";

interface RexAnalysisFiltersProps {
  searchParamsState: [
    SearchFilters,
    React.Dispatch<React.SetStateAction<SearchFilters>>
  ];
  colors: { main?: string; background?: string };
}

export const RexAnalysisFilters: React.FC<RexAnalysisFiltersProps> = ({
  searchParamsState,
  colors,
}) => {
  const [searchParams, setSearchParams] = searchParamsState;

  return (
    <>
      <Stack
        direction="row"
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: `${smallMargin}px`,
        }}
      >
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexAnalysis.eventSummary}
          // value="Perte de contrôle de..."
          type="text"
          inputModalPlaceholder={s.common.placeholders.summary}
          value={searchParams.event_summary}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              event_summary: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexAnalysis.instructorTips}
          type="text"
          inputModalPlaceholder={s.common.placeholders.tips}
          value={searchParams.safety_education}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              safety_education: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexAnalysis.riskProbability}
          type="riskRange"
          displayString={
            searchParams?.risk_probability
              ? getDisplayableStringFromRiskRange(searchParams.risk_probability)
              : undefined
          }
          value={searchParams.risk_probability}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              risk_probability: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexAnalysis.riskImpact}
          type="riskRange"
          displayString={
            searchParams?.risk_impact
              ? getDisplayableStringFromRiskRange(searchParams.risk_impact)
              : undefined
          }
          value={searchParams.risk_impact}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              risk_impact: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
      </Stack>
    </>
  );
};
