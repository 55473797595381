import { Stack } from '@mui/material';
import { AdvancedSearchItem } from '../AdvancedSearchItem';
import { s } from '../../../strings/strings';
import { smallMargin } from '../../../styles/styles';
import { SearchFilters } from '../../../types/common/api';
import React from 'react';
import { getDisplayableStringFromDateRange } from '../../../utils/date';

interface RexInfoFiltersProps {
  searchParamsState: [
    SearchFilters,
    React.Dispatch<React.SetStateAction<SearchFilters>>
  ];
  colors: { main?: string; background?: string };
}

export const RexInfoFilters: React.FC<RexInfoFiltersProps> = ({
  searchParamsState,
  colors,
}) => {
  const [searchParams, setSearchParams] = searchParamsState;

  return (
    <>
      <Stack
        direction="row"
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: `${smallMargin}px`,
        }}
      >
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexInfo.rexType}
          type="rexType"
          value={searchParams.rex_type}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              rex_type: value === s.common.noObject ? undefined : value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexInfo.rexSubject}
          type="rexSubject"
          value={searchParams?.rex_subject}
          displayString={searchParams?.rex_subject?.name}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              rex_subject: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexInfo.eventDate}
          type="dateRange"
          value={searchParams.event_date}
          displayString={
            searchParams?.event_date
              ? getDisplayableStringFromDateRange(searchParams.event_date)
              : undefined
          }
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              event_date: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexInfo.eventPlace}
          type="text"
          value={searchParams.event_place}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              event_place: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexInfo.flightPhase}
          type="flightPhase"
          value={searchParams?.flight_phase}
          displayString={searchParams?.flight_phase?.name}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              flight_phase: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexInfo.rexTitle}
          type="text"
          inputModalPlaceholder={s.common.placeholders.title}
          value={searchParams.rex_title}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              rex_title: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexInfo.rexDescription}
          type="text"
          inputModalPlaceholder={s.common.placeholders.description}
          value={searchParams.rex_description}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              rex_description: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexInfo.flightKind}
          type="flightKind"
          value={searchParams.flight_kind}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              flight_kind: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexInfo.flightType}
          type="flightType"
          value={searchParams.flight_type}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              flight_type: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexInfo.eventType}
          type="eventType"
          value={searchParams.event_type}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              event_type: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexInfo.aircraftType}
          type="text"
          inputModalPlaceholder={s.common.placeholders.aeronef}
          value={searchParams.aircraft_type}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              aircraft_type: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexInfo.aircraftRegistration}
          type="text"
          inputModalPlaceholder={s.common.placeholders.registration}
          value={searchParams.aircraft_registration}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              aircraft_registration: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexInfo.eventKind}
          type="eventKind"
          value={searchParams.event_kind}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              event_kind: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
      </Stack>
    </>
  );
};
