// import { BarChart } from "@mui/x-charts";
import React from 'react';
import { colors } from '../../../styles/colors';
import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';
import { useMainData } from '../../../providers/MainDataProvider';

interface CustomBarChartProps {
  data?: any[];
  width?: number;
}

export const CustomBarChart: React.FC<CustomBarChartProps> = ({
  data,
  width = 500,
}) => {
  const { primaryColors } = useMainData();

  return (
    <>
      {/* <BarChart
        width={400}
        height={200}
        colors={[colors.primary]}
        sx={{
          "--ChartsLegend-itemMarkSize": "10px",
          "--ChartsLegend-labelSpacing": "5px",
          "--ChartsLegend-rootSpacing": "10px",
          "--ChartsLegend-rootOffsetY": "0px",
          "& .MuiChartsLegend-label": {
            ...fonts.regular14,
            color: palette.gray600,
          },
          "& .MuiChartsAxis-tickLabel": {
            transform: "rotate(-90deg)",
            textAnchor: "end",
          },
        }}
        {...props}
      /> */}
      <BarChart
        width={width}
        height={200}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid strokeDasharray="0" /> */}
        <XAxis dataKey="label" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="value" fill={primaryColors.primary} />
      </BarChart>
    </>
  );
};
