import { useEffect, useState } from 'react';
import { EditOrCreateRex } from '../../components/rex/EditOrCreateRex';
import { BasicPage } from '../../components/templates/BasicPage';
import { s } from '../../strings/strings';
import { colors, palette } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import {
  defaultMargin,
  largeMargin,
  largerMargin,
  smallMargin,
} from '../../styles/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from '../../utils/api';
import { AxiosResponse } from 'axios';
import { RexDraft, Report } from '../../types/common/api';
import { useAuth } from '../../providers/AuthProvider';
import { CustomButton } from '../../components/common/CustomButton';
import { DeleteDialog } from '../../components/common/DeleteDialog';
import { ConfirmDialog } from '../../components/common/ConfirmDialog';
import { useReportsMutations } from '../../hooks/useReportsMutations';

export const NewRexFromReportPage = () => {
  const { reportId } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [report, setReport] = useState<Report>();
  const [initialRexData, setInitialRexData] = useState<RexDraft>();
  const { deleteReport, archiveReport } = useReportsMutations();

  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingArchive, setIsLoadingArchive] = useState(false);

  useEffect(() => {
    api
      .get(`report/${reportId}`)
      .then(({ data }: AxiosResponse<Report, any>) => setReport(data));
  }, [reportId]);

  useEffect(() => {
    const rex: RexDraft = {
      description: report?.description || undefined,
      rex_type: report?.report_type,
      rex_subject_id: report?.report_subject_id,
      event_date: report?.event_date,
      flight_phase_id: report?.flight_phase_id || undefined,
      report_id: report?.id,
      report: report,
    };

    setInitialRexData(rex);
  }, [report, user]);

  if (!reportId) {
    navigate('..');
    return <></>;
  }

  return (
    <>
      <BasicPage
        style={{
          paddingRight: 0,
          paddingLeft: 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            backgroundColor: palette.gray50,
            paddingRight: largerMargin,
            paddingLeft: largerMargin,
            paddingTop: defaultMargin,
            paddingBottom: defaultMargin,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <p style={{ ...fonts.bold24, margin: 0 }}>
              {s.rex.newRex.createRexFromReport.title}
            </p>
            <p style={{ ...fonts.medium16, margin: 0, marginTop: smallMargin }}>
              {s.rex.newRex.createRexFromReport.subtitle(reportId)}
            </p>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: largeMargin,
            marginLeft: largerMargin * 3,
            marginRight: largerMargin * 3,
          }}
        >
          <EditOrCreateRex initialRexData={initialRexData} />
        </div>
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            marginTop: largerMargin,
            marginLeft: largerMargin * 3,
            marginRight: largerMargin * 3 + largeMargin,
            justifyContent: 'flex-end',
            columnGap: largeMargin,
          }}
        >
          <DeleteDialog
            onDelete={() => {
              setIsLoadingDelete(true);
              deleteReport.mutateAsync(Number(reportId)).finally(() => {
                setIsLoadingDelete(false);
                navigate('./..');
              });
            }}
            title={s.rex.newRex.createRexFromReport.deleteReportConfirmTitle}
            description={
              s.rex.newRex.createRexFromReport.deleteReportConfirmDescription
            }
          >
            <CustomButton
              title={s.rex.newRex.createRexFromReport.deleteReport}
              style={{
                backgroundColor: 'transparent',
                color: colors.error,
              }}
              loading={isLoadingDelete}
            />
          </DeleteDialog>
          <ConfirmDialog
            title={s.rex.newRex.createRexFromReport.archiveReportConfirmTitle}
            description={
              s.rex.newRex.createRexFromReport.archiveReportConfirmDescription
            }
            onConfirm={() => {
              setIsLoadingArchive(true);
              archiveReport.mutateAsync(Number(reportId)).finally(() => {
                setIsLoadingArchive(false);
                navigate('./..');
              });
            }}
          >
            <CustomButton
              title={s.rex.newRex.createRexFromReport.archiveReport}
              style={{
                backgroundColor: colors.error,
                color: palette.white,
              }}
              loading={isLoadingArchive}
            />
          </ConfirmDialog>
        </div>
      </BasicPage>
    </>
  );
};
