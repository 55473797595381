import React, { useCallback, useEffect, useState } from 'react';
import {
  defaultMargin,
  mediumRadius,
  smallMargin,
} from '../../../styles/styles';
import { palette } from '../../../styles/colors';
import { fonts } from '../../../styles/fonts';
import { CircularProgress } from '@mui/material';
import { s } from '../../../strings/strings';
import { api } from '../../../utils/api';
import { PerimeterStatsDto, StatsOptionsDto } from '../../../types/common/api';
import { useMainData } from '../../../providers/MainDataProvider';
import { getEntitiesStatsParam } from '../../../utils/stats';
import { convertObjectToQueryParams } from '../../../utils/main';

interface DashboardPerimeterCardProps {
  selectedEntityIdx?: string;
  entityChildren?: string[];
}

export const DashboardPerimeterCard: React.FC<DashboardPerimeterCardProps> = ({
  selectedEntityIdx,
  entityChildren,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [stats, setStats] = useState<PerimeterStatsDto>();

  const getOptionsParamsQuery = useCallback(() => {
    const dto: StatsOptionsDto = {
      entities_idx: getEntitiesStatsParam(selectedEntityIdx, entityChildren),
    };

    return convertObjectToQueryParams(dto);
  }, [entityChildren, selectedEntityIdx]);

  useEffect(() => {
    if (!selectedEntityIdx) return;
    setLoading(true);
    api
      .get(`stats/${selectedEntityIdx}/perimeter${getOptionsParamsQuery()}`)
      .then(({ data }) => {
        setStats(data);
      })
      .finally(() => setLoading(false));
  }, [selectedEntityIdx, getOptionsParamsQuery]);

  return (
    <>
      <div
        style={{
          border: `1px solid ${palette.gray200}`,
          borderRadius: mediumRadius,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div
          style={{
            padding: defaultMargin,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <p style={{ ...fonts.semiBold20, color: palette.gray900, margin: 0 }}>
            {s.dashboard.perimeterMembers.title}
          </p>
        </div>
        <Divider />
        {loading ? (
          <div
            style={{
              display: ' flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: defaultMargin,
            }}
          >
            <CircularProgress
              style={{ color: palette.darkBlue600 }}
              size={40}
            />
          </div>
        ) : stats ? (
          <>
            <div
              style={{
                padding: defaultMargin + smallMargin,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                flexGrow: 1,
              }}
            >
              <CardItem
                title={s.dashboard.perimeterMembers.totalMembers}
                value={stats.totalMembers || '-'}
              />
              <CardItem
                title={
                  s.dashboard.perimeterMembers
                    .membersReceivingREXClubNotifications
                }
                value={stats.membersReceivingREXClubNotifications}
              />
              <CardItem
                title={
                  s.dashboard.perimeterMembers
                    .membersReceivingREXOthersNotifications
                }
                value={stats.membersReceivingREXOthersNotifications}
              />
              <CardItem
                title={
                  s.dashboard.perimeterMembers
                    .membersReceivingREXGeneralNotifications
                }
                value={stats.membersReceivingREXGeneralNotifications}
              />
              <CardItem
                title={s.dashboard.perimeterMembers.clubsInPerimeter}
                value={stats.clubsInPerimeter || '-'}
              />
            </div>
          </>
        ) : (
          <>
            <p
              style={{
                ...fonts.semiBold18,
                textAlign: 'center',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            >
              {s.dashboard.charts.noData}
            </p>
          </>
        )}
      </div>
    </>
  );
};

const Divider = () => {
  return (
    <>
      <div
        style={{
          height: 1,
          borderTop: `1px solid ${palette.gray200}`,
        }}
      />
    </>
  );
};

interface CardItemProps {
  title: string;
  value: number | string;
}

const CardItem: React.FC<CardItemProps> = ({ title, value }) => {
  const { primaryColors } = useMainData();

  return (
    <>
      <div>
        <p
          style={{
            ...fonts.regular14,
            color: palette.gray600,
            margin: 0,
            marginBottom: smallMargin,
          }}
        >
          {title}
        </p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p
            style={{
              ...fonts.semiBold24,
              color: primaryColors.primary,
              margin: 0,
            }}
          >
            {value}
          </p>
        </div>
      </div>
    </>
  );
};
