import React, { useCallback, useEffect, useState } from 'react';
import {
  defaultMargin,
  mediumRadius,
  smallMargin,
  tinyMargin,
} from '../../../styles/styles';
import { palette } from '../../../styles/colors';
import { fonts } from '../../../styles/fonts';
import {
  CircularProgress,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { StatsRange } from '../../../types/common/main';
import { s } from '../../../strings/strings';
import checkCircleIcon from '../../../assets/icons/check-circle.svg';
import editIcon from '../../../assets/icons/edit.svg';
import inboxIcon from '../../../assets/icons/inbox.svg';
import hourglassIcon from '../../../assets/icons/hourglass.svg';
import { api } from '../../../utils/api';
import { RexGeneralStatsDto, StatsOptionsDto } from '../../../types/common/api';
import { useMainData } from '../../../providers/MainDataProvider';
import { getEntitiesStatsParam } from '../../../utils/stats';
import { convertObjectToQueryParams } from '../../../utils/main';

interface DashboardGeneralStatsCardProps {
  selectedEntityIdx?: string;
  entityChildren?: string[];
}

export const DashboardGeneralStatsCard: React.FC<
  DashboardGeneralStatsCardProps
> = ({ selectedEntityIdx, entityChildren }) => {
  const [statsRange, setStatsRange] = useState<StatsRange>('total');
  const [loading, setLoading] = useState<boolean>(false);
  const [stats, setStats] = useState<RexGeneralStatsDto>();

  const getOptionsParamsQuery = useCallback(
    (range: StatsRange) => {
      const dto: StatsOptionsDto = {
        entities_idx: getEntitiesStatsParam(selectedEntityIdx, entityChildren),
      };

      if (range !== 'total') {
        const date = new Date();
        if (range === 'twelveMonths') date.setMonth(date.getMonth() - 12);
        if (range === 'threeMonths') date.setMonth(date.getMonth() - 3);
        dto.rex_date_min = date.toISOString()?.split('T')?.shift();
      }

      return convertObjectToQueryParams(dto);
    },
    [selectedEntityIdx, entityChildren]
  );

  useEffect(() => {
    setLoading(true);
    api
      .get(`stats/rex/general${getOptionsParamsQuery(statsRange)}`)
      .then(({ data }) => {
        setStats(data);
      })
      .finally(() => setLoading(false));
  }, [statsRange, getOptionsParamsQuery]);

  const handleDateRange = (
    _: React.MouseEvent<HTMLElement>,
    newRange: StatsRange
  ) => {
    setStatsRange(newRange);
  };

  return (
    <>
      <div
        style={{
          border: `1px solid ${palette.gray200}`,
          borderRadius: mediumRadius,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div
          style={{
            padding: defaultMargin,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <p style={{ ...fonts.semiBold20, color: palette.gray900, margin: 0 }}>
            {s.dashboard.charts.generalStats}
          </p>
          <ToggleButtonGroup
            value={statsRange}
            exclusive
            onChange={handleDateRange}
          >
            <ToggleButton
              value="threeMonths"
              style={{ textTransform: 'initial' }}
              size="small"
            >
              {'< 3 mois'}
            </ToggleButton>
            <ToggleButton
              value="twelveMonths"
              style={{ textTransform: 'initial' }}
              size="small"
            >
              {'< 12 mois'}
            </ToggleButton>
            <ToggleButton
              value="total"
              style={{ textTransform: 'initial' }}
              size="small"
            >
              {'Depuis le début'}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <Divider />
        {loading ? (
          <div
            style={{
              display: ' flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: defaultMargin,
            }}
          >
            <CircularProgress
              style={{ color: palette.darkBlue600 }}
              size={40}
            />
          </div>
        ) : stats ? (
          <>
            <div
              style={{
                padding: smallMargin,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                style={{
                  padding: defaultMargin,
                  flexGrow: 1,
                  alignItems: 'center',
                }}
              >
                <Grid item xs={6}>
                  <CardItem
                    title={s.dashboard.cpsStats.reportsReceived}
                    iconSrc={inboxIcon}
                    value={stats.reportsReceived}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CardItem
                    title={s.dashboard.cpsStats.rexCreated}
                    iconSrc={editIcon}
                    value={stats.rexCreated}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CardItem
                    title={s.dashboard.cpsStats.rexWaiting}
                    iconSrc={hourglassIcon}
                    value={stats.cresagCreated}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CardItem
                    title={s.dashboard.cpsStats.rexPublished}
                    iconSrc={checkCircleIcon}
                    value={stats.causesCreated}
                  />
                </Grid>
              </Grid>
            </div>
          </>
        ) : (
          <>
            <p
              style={{
                ...fonts.semiBold18,
                textAlign: 'center',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            >
              {s.dashboard.charts.noData}
            </p>
          </>
        )}
      </div>
    </>
  );
};

const Divider = () => {
  return (
    <>
      <div
        style={{
          height: 1,
          borderTop: `1px solid ${palette.gray200}`,
        }}
      />
    </>
  );
};

interface CardItemProps {
  title: string;
  iconSrc: string;
  value: number;
}

const CardItem: React.FC<CardItemProps> = ({ title, iconSrc, value }) => {
  const { primaryColors } = useMainData();

  return (
    <>
      <div>
        <p
          style={{
            ...fonts.regular14,
            color: palette.gray600,
            margin: 0,
            marginBottom: smallMargin,
          }}
        >
          {title}
        </p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={iconSrc} alt="" style={{ marginRight: tinyMargin }} />
          <p
            style={{
              ...fonts.semiBold24,
              color: primaryColors.primary,
              margin: 0,
            }}
          >
            {value}
          </p>
        </div>
      </div>
    </>
  );
};
