import { palette } from "../../../styles/colors";
import React from "react";
import { CustomChip } from "./CustomChip";
import { FlightPhase } from "../../../types/common/api";

interface FlightPhaseChipProps {
  flightPhase: FlightPhase;
}

export const FlightPhaseChip: React.FC<FlightPhaseChipProps> = ({
  flightPhase,
}) => {
  let colors: { backgroundColor: string; textColor: string };
  switch (flightPhase.id) {
    case 9:
      colors = {
        textColor: palette.error700,
        backgroundColor: palette.error50,
      };
      break;
    case 8:
      colors = {
        textColor: palette.warning700,
        backgroundColor: palette.warning50,
      };
      break;
    case 7:
      colors = {
        textColor: palette.purple700,
        backgroundColor: palette.purple50,
      };
      break;
    case 6:
      colors = {
        textColor: palette.blue700,
        backgroundColor: palette.blue50,
      };
      break;
    case 5:
      colors = {
        textColor: palette.gray700,
        backgroundColor: palette.gray50,
      };
      break;
    case 4:
      colors = {
        textColor: palette.darkBlue700,
        backgroundColor: palette.darkBlue50,
      };
      break;
    case 3:
      colors = {
        textColor: palette.success700,
        backgroundColor: palette.success50,
      };
      break;
    case 2:
      colors = {
        textColor: palette.gray900,
        backgroundColor: palette.gray100,
      };
      break;
    case 1:
    default:
      colors = {
        textColor: palette.darkBlue900,
        backgroundColor: palette.darkBlue100,
      };
      break;
  }

  return <CustomChip title={flightPhase.name} colors={colors} />;
};
