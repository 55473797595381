import { Link, useNavigate, useParams } from 'react-router-dom';
import { BasicPage } from '../../components/templates/BasicPage';
import { palette } from '../../styles/colors';
import {
  defaultMargin,
  largeMargin,
  largerMargin,
  mediumRadius,
  tinyMargin,
} from '../../styles/styles';
import { fonts } from '../../styles/fonts';
import { s } from '../../strings/strings';
import arrowLeftIcon from '../../assets/icons/arrow-narrow-left.svg';
import { useEffect, useState } from 'react';
import { Cause, FlightPhase, Rex } from '../../types/common/api';
import { api } from '../../utils/api';
import { AxiosResponse } from 'axios';
import bookmarkIcon from '../../assets/icons/blue-bookmark.svg';
import bookmarkFilledIcon from '../../assets/icons/blue-bookmark-filled.svg';
import yellowDownloadIcon from '../../assets/icons/yellow-download.svg';
import { CustomButton } from '../../components/common/CustomButton';
import { useFavoriteCauseMutations } from '../../hooks/useFavoriteCauseMutations';
import { GridColDef, useGridApiRef } from '@mui/x-data-grid';
import { ReportTypeChip } from '../../components/common/chip/ReportTypeChip';
import { FlightPhaseChip } from '../../components/common/chip/FlightPhaseChip';
import { getRiskColor, getSearchColor } from '../../utils/main';
import { CustomGrid } from '../../components/common/CustomGrid';
import { ExportResultsModal } from '../../components/search/ExportResultsModal';
import { useMainData } from '../../providers/MainDataProvider';
import { useAuth } from '../../providers/AuthProvider';
import { AddCauseModal } from '../../components/rex/AddCauseModal';

export const CauseResultPage = () => {
  const { isMemberOfClub, hasCPSPermission, hasCSPermission } = useAuth();
  const { primaryColors } = useMainData();
  const navigate = useNavigate();
  const { causeId } = useParams();
  const apiRef = useGridApiRef();
  const { addFavoriteCause, deleteFavoriteCause, favoriteCauses } =
    useFavoriteCauseMutations();

  const [cause, setCause] = useState<Cause>();
  const [associatedRexs, setAssociatedRexs] = useState<Rex[]>();
  const [isLoadingBookmark, setIsLoadingBookmark] = useState<boolean>(false);
  const [isBookmarked, setIsBookmarked] = useState<boolean>(false);
  const [isExportResultsModalOpen, setIsExportResultsModalOpen] =
    useState(false);
  const [isAddCauseModalOpen, setIsAddCauseModalOpen] = useState(false);
  const [needToRefresh, setNeedToRefresh] = useState(false);

  useEffect(() => {
    api.get(`cause/${causeId}`).then(({ data }: AxiosResponse<Cause, any>) => {
      setCause(data);
      setNeedToRefresh(false);
    });
    api
      .get(`cause/${causeId}/rexs`)
      .then(({ data }: AxiosResponse<Rex[], any>) => setAssociatedRexs(data));
  }, [causeId, needToRefresh]);

  useEffect(() => {
    setIsBookmarked(
      favoriteCauses?.find((e) => e.id.toString() === causeId) ? true : false
    );
  }, [favoriteCauses, causeId]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: s.common.grid.id,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.id;
      },
    },
    {
      field: 'title',
      headerName: s.common.grid.title,
      flex: 2,
    },
    {
      field: 'rex_type',
      headerName: s.common.grid.rexType,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.rex_type ? (
          <ReportTypeChip reportType={row.rex_type} />
        ) : (
          <p>N/A</p>
        );
      },
    },
    {
      field: 'event_date',
      headerName: s.common.grid.date,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        const eventDateString = row?.event_date;
        const date = eventDateString?.split('-')?.reverse()?.join('/');
        return date;
      },
    },
    {
      field: 'flight_phase',
      headerName: s.rex.reportToProcess.grid.flightPhase,
      renderCell: (params) => {
        const row = params.row;
        const flightPhase: FlightPhase = row?.flight_phase;
        return flightPhase ? (
          <FlightPhaseChip flightPhase={flightPhase} />
        ) : (
          <p>N/A</p>
        );
      },
      flex: 1,
    },
    {
      field: 'flight_kind',
      headerName: s.common.grid.flightKind,
      flex: 1,
    },
    {
      field: 'flight_type',
      headerName: s.common.grid.flightType,
      flex: 1,
    },
    {
      field: 'aircraft_registration',
      headerName: s.common.grid.registration,
      renderCell: (params) => {
        const row = params.row;
        const clubId = row?.club_id || '';

        return isMemberOfClub(clubId) ? row?.aircraft_registration : '';
      },
      flex: 1,
    },
    {
      field: 'event_kind',
      headerName: s.common.grid.classification,
      flex: 1,
    },
    {
      field: 'initial_risk_probability',
      headerName: s.common.grid.risk,
      renderCell: (params) => {
        const row = params.row;
        const severity: number = row?.initial_risk_severity;
        const probability: number = row?.initial_risk_probability;
        return (
          <>
            <div
              style={{
                display: 'flex',
                backgroundColor: getRiskColor(severity, probability),
                borderRadius: mediumRadius,
                height: 18,
                width: 18,
              }}
            ></div>
          </>
        );
      },
      flex: 0.5,
    },
  ];

  return (
    <>
      <BasicPage
        style={{
          paddingRight: 0,
          paddingLeft: 0,
          paddingTop: 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            backgroundColor: palette.gray50,
            paddingRight: largerMargin,
            paddingLeft: largerMargin,
            paddingTop: defaultMargin,
            paddingBottom: defaultMargin,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Link
              to={'..'}
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              <img
                src={arrowLeftIcon}
                alt=""
                style={{ marginRight: tinyMargin }}
              />
              <p
                style={{
                  ...fonts.medium14,
                  color: palette.gray600,
                  margin: 0,
                }}
              >
                {s.searchResult.backToResults}
              </p>
            </Link>
            <p style={{ ...fonts.bold24, margin: 0, marginTop: defaultMargin }}>
              {cause?.title}
            </p>
            <p style={{ ...fonts.medium16, margin: 0, marginTop: tinyMargin }}>
              {cause?.description}
            </p>
          </div>
          <div style={{ display: 'flex', gap: defaultMargin }}>
            {(hasCPSPermission || hasCSPermission) && (
              <CustomButton
                title={s.common.button.editCause}
                onClick={() => {
                  setIsAddCauseModalOpen(true);
                }}
                style={{
                  marginRight: 'auto',
                  color: palette.white,
                }}
                loading={isLoadingBookmark}
                loaderColor={primaryColors.primary}
              />
            )}
            <CustomButton
              title={''}
              onClick={() => {
                setIsLoadingBookmark(true);
                (isBookmarked ? deleteFavoriteCause : addFavoriteCause)
                  .mutateAsync(Number(causeId))
                  .finally(() => {
                    setIsLoadingBookmark(false);
                  });
              }}
              style={{
                ...fonts.medium14,
                border: `1px solid ${palette.darkBlue600}`,
                backgroundColor: palette.darkBlue50,
                color: palette.darkBlue600,
                flexGrow: 0,
              }}
              loading={isLoadingBookmark}
              loaderColor={primaryColors.primary}
              iconSrc={isBookmarked ? bookmarkFilledIcon : bookmarkIcon}
            />
          </div>
        </div>
        <div
          style={{
            paddingTop: largeMargin,
            paddingRight: largerMargin,
            paddingBottom: defaultMargin,
            paddingLeft: largerMargin,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 0,
              marginBottom: defaultMargin,
            }}
          >
            <p
              style={{
                ...fonts.bold18,
                margin: 0,
              }}
            >
              {s.searchResult.associatedRexs}
            </p>
            <CustomButton
              title={s.search.exportResults}
              onClick={() => setIsExportResultsModalOpen(true)}
              style={{
                marginLeft: defaultMargin,
                border: `1px solid ${getSearchColor('cause')?.border}`,
                backgroundColor: getSearchColor('cause')?.background,
                color: getSearchColor('cause')?.border,
              }}
              iconSrc={yellowDownloadIcon}
              iconPosition="right"
            />
          </div>
          <CustomGrid
            apiRef={apiRef}
            rows={associatedRexs || []}
            columns={columns}
            onRowClick={(row) => navigate('/rex/' + row.id)}
          />
        </div>
      </BasicPage>
      <ExportResultsModal
        open={isExportResultsModalOpen}
        onClose={() => setIsExportResultsModalOpen(false)}
        apiRef={apiRef}
      />
      <AddCauseModal
        open={isAddCauseModalOpen}
        onClose={() => setIsAddCauseModalOpen(false)}
        causeToEdit={cause}
        onBack={() => {
          setIsAddCauseModalOpen(false);
          setNeedToRefresh(true);
        }}
      />
    </>
  );
};
