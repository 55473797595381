import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { palette } from '../../styles/colors';
import {
  defaultMargin,
  largerMargin,
  mediumRadius,
  smallMargin,
  tinyMargin,
} from '../../styles/styles';
import { fonts } from '../../styles/fonts';
import { s } from '../../strings/strings';
import whiteCheckIcon from '../../assets/icons/white-check.svg';
import { getRiskColor } from '../../utils/main';
import { MAX_PROBABILITY, MAX_SEVERITY } from '../../constants';
import { RiskMatrixDialog } from '../dialog/RiskMatrixDialog';
import informationTooltip from '../../assets/icons/information-tooltip.svg';

interface CustomRiskMatrixProps {
  label?: string;
  severitySate: [
    number | undefined,
    React.Dispatch<React.SetStateAction<number | undefined>>
  ];
  probabilityState: [
    number | undefined,
    React.Dispatch<React.SetStateAction<number | undefined>>
  ];
  style?: React.CSSProperties;
}

export const CustomRiskMatrix: React.FC<CustomRiskMatrixProps> = ({
  label,
  severitySate,
  probabilityState,
  style,
}) => {
  const [isMatrixDialogOpen, setIsMatrixDialogOpen] = useState(false);
  const [selectedSeverity, setSelectedSeverity] = severitySate;
  const [selectedProbability, setSelectedProbability] = probabilityState;

  const height = MAX_SEVERITY;
  const width = MAX_PROBABILITY;

  const gridHeight = height + 1; // height + 1 (for labels)
  const gridWidth = width + 1; // width + 1 (for labels)

  const gridCellSize = 12 / gridWidth;
  const numberOfCells = gridWidth * gridHeight;
  const cells = [];

  const cellSize = 40;
  const cellSpacing = smallMargin;

  const renderGridContent = (i: number) => {
    if (i % gridWidth === 0) {
      // First column (labels)
      return (
        i !== numberOfCells - gridWidth && ( // Bottom left corner
          <p style={{ ...fonts.medium14, margin: 0, textAlign: 'center' }}>
            {s.common.matrix.severities[i / gridWidth]}
          </p>
        )
      );
    } else if (i >= numberOfCells - gridWidth) {
      // Last row (labels)
      return (
        // Display text vertically
        <div
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              top: 0,
              textAlign: 'center',
              width: '100%',
            }}
          >
            <p
              style={{
                width: '100%',
                ...fonts.medium14,
                margin: 0,
                textAlign: 'center',
              }}
            >
              {i % gridWidth}
            </p>
          </div>
        </div>
      );
    } else {
      const severity: number = height - Math.floor(i / gridWidth);
      const probability: number = i % gridWidth;

      const isSelected =
        severity === selectedSeverity && probability === selectedProbability;
      const selectedColor = getRiskColor(severity, probability);
      const unselectedColor = getRiskColor(severity, probability, 0.25);

      let watermarkLabel = '';
      if (selectedColor === palette.error500) watermarkLabel = 'R';
      else if (selectedColor === palette.warning500) watermarkLabel = 'O';
      else if (selectedColor === palette.warning300) watermarkLabel = 'J';
      else watermarkLabel = 'V';

      return (
        <div
          style={{
            ...styles.gridCell,
            border: `1px solid ${isSelected ? selectedColor : palette.gray300}`,
            backgroundColor: isSelected ? selectedColor : unselectedColor,
          }}
          onClick={() => {
            if (isSelected) {
              setSelectedSeverity(undefined);
              setSelectedProbability(undefined);
            } else {
              setSelectedSeverity(severity);
              setSelectedProbability(probability);
            }
          }}
        >
          {isSelected ? (
            <img src={whiteCheckIcon} width={24} height={24} alt="" />
          ) : (
            <p
              style={{
                ...fonts.regular16,
                color: palette.gray400,
                cursor: 'default',
              }}
            >
              {watermarkLabel}
            </p>
          )}
        </div>
      );
    }
  };

  for (let i = 0; i < numberOfCells; i++) {
    cells.push(
      <Grid
        key={i}
        item
        xs={gridCellSize}
        style={
          i % gridWidth === 0
            ? styles.gridYLabels
            : i >= numberOfCells - gridWidth
            ? styles.gridXLabels
            : styles.gridItem
        }
        width={`${cellSize + cellSpacing}px`}
        height={`${cellSize + cellSpacing}px`}
      >
        {renderGridContent(i)}
      </Grid>
    );
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          ...style,
        }}
      >
        {label && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              columnGap: smallMargin,
              marginBottom: tinyMargin,
            }}
          >
            <p
              style={{
                ...fonts.medium14,
                color: palette.gray700,
                margin: 0,
              }}
            >
              {label}
            </p>
            <img
              src={informationTooltip}
              alt=""
              style={{ marginRight: tinyMargin, cursor: 'pointer' }}
              onClick={() => setIsMatrixDialogOpen(true)}
            />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            border: `1px solid ${palette.gray200}`,
            borderRadius: mediumRadius,
            flexGrow: 1,
          }}
        >
          <div
            style={{
              position: 'relative',
              padding: defaultMargin + smallMargin,
            }}
          >
            <p
              style={{
                position: 'absolute',
                ...fonts.regular14,
                color: palette.gray700,
                height: `${height * (cellSize + cellSpacing) - cellSpacing}px`,
                margin: 0,
                textAlign: 'center',
                writingMode: 'vertical-rl',
                transform: 'rotate(180deg)',
                left: 0,
              }}
            >
              {s.common.matrix.severity}
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                marginBottom: 2 * largerMargin + defaultMargin,
              }}
            >
              <Grid
                container
                spacing={`${cellSpacing}px`}
                width={`${gridWidth * (cellSize + cellSpacing)}px`}
                height={`${gridHeight * (cellSize + cellSpacing)}px`}
              >
                {cells}
              </Grid>
              <div
                style={{
                  position: 'absolute',
                  ...fonts.regular14,
                  width: `${width * (cellSize + cellSpacing) - cellSpacing}px`,
                  textAlign: 'center',
                  bottom: 155,
                }}
              >
                <p
                  style={{
                    ...fonts.regular14,
                    color: palette.gray700,
                    width: `${
                      width * (cellSize + cellSpacing) - cellSpacing
                    }px`,
                    margin: 0,
                    marginBottom: smallMargin,
                    textAlign: 'center',
                  }}
                >
                  {s.common.matrix.probability}
                </p>
              </div>
              <div
                style={{
                  position: 'absolute',
                  ...fonts.regular14,
                  width: `${width * (cellSize + cellSpacing) - cellSpacing}px`,
                  textAlign: 'center',
                  bottom: 25,
                }}
              >
                <p
                  style={{
                    ...fonts.regular14,
                    color: palette.black,
                    width: '100%',
                    margin: 0,
                    textAlign: 'left',
                    marginBottom: smallMargin,
                  }}
                >
                  {s.common.matrix.legend}
                </p>
                {[1, 2, 3, 4, 5]?.map((value, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: smallMargin,
                      }}
                    >
                      <p
                        style={{
                          ...fonts.regular14,
                          color: palette.gray700,
                          textAlign: 'left',
                          margin: 0,
                        }}
                      >
                        <span
                          style={{
                            color: palette.black,
                            fontWeight: 'bold',
                          }}
                        >
                          {index + 1} -{' '}
                        </span>
                        {s.common.matrix.probabilities[index]}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <RiskMatrixDialog state={[isMatrixDialogOpen, setIsMatrixDialogOpen]} />
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  gridItem: {},
  gridCell: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridYLabels: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  gridXLabels: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
};
