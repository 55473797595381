import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { s } from '../../strings/strings';

interface ChangeEnvDialogProps {
  state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  onConfirm: () => void;
}

export const ChangeEnvDialog: React.FC<ChangeEnvDialogProps> = ({
  state,
  onConfirm,
}) => {
  const [open, setOpen] = state;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>{s.settings.changeEnv}</DialogTitle>
      <DialogContent>
        <DialogContentText>{s.settings.changeEnvDescription}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          {s.common.button.cancel}
        </Button>
        <Button
          onClick={() => {
            handleClose();
            onConfirm();
          }}
          autoFocus
        >
          {s.common.button.continue}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
