export const s = {
  common: {
    anonymous: 'Anonyme',
    button: {
      back: 'Retour',
      cancel: 'Annuler',
      validate: 'Valider',
      see: 'Voir',
      saveUpdates: 'Enregistrer les modifications',
      completeRex: 'Terminer le REX',
      sendCresag: 'Envoyer le CRESAG',
      editRex: 'Éditer le REX',
      editAction: "Éditer l'action",
      editCause: 'Éditer la cause',
      edit: 'Éditer',
      save: 'Enregistrer',
      reset: 'Réinitialiser',
      publishRex: 'Publier le REX',
      ok: 'Ok',
      continue: 'Continuer',
      confirm: 'Confirmer',
    },
    deleteDialog: {
      title: 'Êtes-vous sûr de vouloir effectuer cette suppression ?',
      description: 'Cette action est irréversible.',
    },
    display: 'Afficher',
    grid: {
      id: 'ID',
      title: 'Titre',
      description: 'Description',
      rexType: 'Type REX',
      date: 'Date',
      eventDate: 'Date évènement',
      creationDate: 'Date de création',
      statusDate: 'Date Statut',
      carrier: 'Porteur',
      flightPhase: 'Phase vol',
      flightType: 'Type Vol',
      flightKind: 'Nature vol',
      classification: 'Classe',
      registration: 'Immat',
      risk: 'Risque',
      associatedRexs: 'REXs associés',
      rexCount: 'Nbr de REX associés',
      noDataToShow: 'Aucune donnée à afficher',
    },
    matrix: {
      severity: 'Gravité',
      probability: 'Probabilité',
      tooltipTitle: 'Comment remplir la matrice de risque ?',
      tooltipPart1: `Il y a deux risques pour chaque REX :
    • Le risque initial : celui évalué au moment où le CPS fait l’analyse de l’évènement, et en tenant compte de ce qui s’est passé.
    • Le risque résiduel : celui estimé après que les actions correctrices choisies (ou proposées) aient porté leurs effets.

Chaque risque est défini dans une matrice à deux dimensions :
    • En ordonnée : l’impact de l’évènement (tel qu’il est évalué par le CPS)
    • En abscisse : la probabilité de l’évènement (qu’il se soit produit ou qu’il se reproduise)
Cette matrice permet ainsi de définir un niveau de risque, résultant de la combinaison de ces deux dimensions. Le niveau, représenté par des couleurs, permet de comparer les risques entre eux, mais aussi de se donner une idée sur la conduite à tenir.

L'impact se classe de 1 à 5. Vous pouvez vous aider du tableau suivant :\n\n`,
      tooltipPart2: `\n\nCette table n’est qu’une aide à la classification, et ne peut convenir dans tous les cas… Le sens commun, l’usage au niveau du club, les échanges avec d’autres personnes concernées, peuvent/doivent avoir une influence sur le choix du niveau d’impact. L’important est d’obtenir une évaluation de l’état du risque et de son évolution potentielle.

Les niveaux de risque sont alors automatiquement classés selon la matrice en 4 niveaux, chacun étant associé à une couleur.
Là encore, les décisions à prendre par chaque club en fonction du risque ne peuvent être pré-définies dans l’outil. C’est au CPS, et à l’équipe dirigeante, d’adapter le plan d’action proposé en fonction des moyens et des contraintes qui leur sont propres.
Néanmoins, on peut utiliser cette gradation pour se donner un ordre d'idées :\n\n`,
      severities: ['Très grave', 'Grave', 'Léger', 'Mineur', 'Aucun'],
      probabilities: [
        'Extrêmement improbable / impossible',
        'Très peu probable / improbable',
        'Peu probable',
        'Probable',
        'Très probable / presque certain',
      ],
      legend: 'Légende :',
    },
    next: 'Suivant',
    page: 'Page',
    previous: 'Précédent',
    placeholders: {
      title: 'Titre',
      date: 'Date',
      description: 'Description',
      oaciCode: 'Code OACI',
      selectValue: 'Sélectionner une valeur',
      summary: 'Résumé',
      type: 'Type',
      aeronef: 'Aéronef',
      registration: 'Immatriculation',
      club: 'Club',
      clubName: 'Nom de club',
      cps: 'CPS',
      name: 'Nom',
      number0000: '0000',
      tips: 'Conseils',
      beginningOfRange: 'Début de plage',
      endOfRange: 'Fin de plage',
    },
    optional: 'facultatif',
    between: 'entre',
    and: 'et',
    noObject: 'Sans objet',
    yes: 'Oui',
    no: 'Non',
    days: 'jours',
    rex: {
      rexType: 'Type de REX',
      rexSubject: 'Objet du REX',
      eventDate: "Date de l'évènement",
      eventPlace: 'Lieu évènement',
      place: 'Lieu Géographique',
      flightPhase: 'Phase de vol',
      rexTitle: 'Titre du REX',
      rexDescription: 'Description du REX',
      flightKind: 'Nature du vol',
      flightType: 'Type de vol',
      eventType: 'Type évènement',
      aircraftType: 'Type aéronef',
      aircraftRegistration: 'Immatriculation aéronef',
      eventKind: 'Classe évènement',
      causes: 'Causes',
      actions: 'Actions',
    },
    tooltip: {
      logout: 'Déconnexion',
      showPassword: 'Afficher le mot de passe',
      hidePassword: 'Masquer le mot de passe',
      edit: 'Editer',
      delete: 'Supprimer',
      publish: 'Publier',
      close: 'Fermer',
      addToFavorites: 'Ajouter aux favoris',
      removeFromFavorites: 'Retirer des favoris',
      closeAdvancedSearch: 'Fermer la recherche avancée',
      deleteFilter: 'Supprimer le filtre',
      deleteSelectedFavorites: 'Supprimer les favoris sélectionnés',
    },
  },
  // DASHBOARD
  dashboard: {
    title: (username: string) => `Bienvenue, ${username}`,
    tabs: {
      cs: 'Consultant sécurité',
      cps: 'CPS',
      licensee: 'Licencié',
    },
    charts: {
      generalStats: 'Statistiques générales',
      publicationLevel: 'Rex créés - Niveau de publication',
      rexSubject: 'Objet du REX',
      flightPhase: 'Phases de vol',
      urgency: 'Urgence',
      flightKind: 'Nature du vol',
      flightRegime: 'Régime de vol',
      flightType: 'Type de vol',
      eventKind: 'Classe d’évènement',
      eventType: 'Type d’évènement',
      associatedActions: 'Actions associées',
      noData: 'Aucune donnée',
      initialRisk: 'REX créés / Risque initial',
      residualRisk: 'REX créés / Risque résiduel',
    },
    cpsStats: {
      title: 'Statistiques du CPS',
      reportsReceived: 'Signalements reçus',
      rexCreated: 'REX créés',
      rexWaiting: 'REX en attente',
      rexPublished: 'REX publiés',
      cresagCreated: 'CRESAG crées',
      cresagFinished: 'CRESAG clôturés',
      rexOpenPendingInvestigation: 'REX ouverts en attente d’enquête',
      rexWithOfficialInvestigation: 'REX avec enquête officielle',
      rexOpenPendingInvestigationTooltip:
        "REX créés sur la période, non publiés, et nécessitant une enquête qui n'a pas encore abouti",
      rexWithOfficialInvestigationTooltip:
        "Tous les REX créés et qui ont fait l'objet d'une enquête (aboutie ou non)",
      clubPosts: 'Publications club',
      interClubPosts: 'Publications inter-club',
      platformPosts: 'Publications plateforme',
      cdaPosts: 'Publications CDA',
      craPosts: 'Publications CRA',
      ffaPosts: 'Publications FFA',
      averageReportProcessingTime: 'Temps moyen de traitement du signalement',
      averageRexProcessingTime: 'Temps moyen de traitement du REX',
      membersWhoMadeReport:
        'Membres différents ayant fait au moins un signalement',
    },
    perimeterMembers: {
      title: 'Membres du périmètre',
      totalMembers: 'Membres total',
      membersReceivingREXClubNotifications:
        'Membres recevant les notifications REX club',
      membersReceivingREXOthersNotifications:
        'Membres recevant les notifications REX autres',
      membersReceivingREXGeneralNotifications:
        'Membres recevant les notifications générales',
      clubsInPerimeter: 'Clubs dans le périmètre',
    },
    grids: {
      outstandingRexOfTheMonth: 'Les 5 REX marquants du mois',
      lastRexs: 'Derniers REX dans votre périmètre',
      unpublishedRex: 'REX non publiés',
      uncompleteActions: 'ACTIONS  non complétées',
    },
  },
  // FAVORITES
  favorites: {
    title: 'Mes favoris',
    tabs: {
      rexs: 'REX',
      causes: 'Causes',
      actions: 'Actions',
    },
    editColumns: 'Editer les colonnes',
    exportResults: 'Exporter les resultats',
  },
  // FOOTER
  footer: {
    privacyPolicy: 'Politique de confidentialité',
    TOS: 'CGU',
    copyright: `${new Date().getFullYear()} REXFFA. All right reserved.`,
  },
  // GCU
  gcu: {
    title: 'Conditions générales d’utilisation',
  },
  // LOGIN PAGE
  login: {
    welcome: 'Bienvenue',
    enterCredentials: 'Veuillez entrer vos identifiants de connexion Smile.',
    enterLogin: 'Veuillez entrer un identifiant',
    enterPassword: 'Veuillez entrer un mot de passe',
    errorIncorrectCredentials: 'Identifiant ou mot de passe incorrect!',
    smileLogin: 'Identifiant SMILE Licencié',
    login: 'Identifiant',
    loginTooltip:
      "Si votre numéro de licence commence par un zéro, assurez-vous de l'inclure lors de la saisie de votre identifiant.",
    password: 'Mot de passe',
    submitButton: 'M’identifier',
    needHelp: 'Besoin d’aide ?',
    privacyPolicy: 'Politique de confidentialité',
    TOS: 'CGU',
  },
  mfa: {
    code: 'Code',
    verifyMail: 'Vérifiez votre boîte mail !',
    enterCode:
      'Entrez le code de vérification envoyé à votre adresse mail de membre de la FFA.',
    errorEnterCode: 'Veuillez entrer un code',
    errorIncorrectCode: 'Code incorrect!',
    submitButton: 'Valider',
  },
  // Modals
  modals: {
    addCause: {
      title: 'Ajouter une cause au REX',
      titleEdit: 'Editer une cause',
      searchCauses: 'Rechercher des causes',
      createCause: 'Créer une nouvelle cause',
      filters: {
        title: 'Filtres',
        number: 'Numéro',
        type: 'Type',
        description: 'Description',
      },
      causes: 'Causes',
      causeCounter: (count: string) => `${count} causes`,
      grid: {
        number: 'Numéro',
        title: 'Titre',
        description: 'Description',
        causeType: 'Type de cause',
        rexCount: 'Nbr de REX associés',
      },
      chooseCauseType: 'Choisir un type de cause pour ce REX',
      mainCause: 'Cause Principale',
      contributingFactor: 'Facteur contributif',
      causeTitle: 'Titre de la cause',
      addCause: 'Ajouter la cause au REX',
      editCause: 'Editer la cause',
    },
    addAction: {
      title: 'Ajouter une action au REX',
      titleEdit: 'Editer une action',
      searchActions: 'Rechercher des actions',
      createAction: 'Créer une nouvelle action',
      filters: {
        title: 'Filtres',
        number: 'Numéro',
        titleDescription: 'Titre/Description',
        creationDate: 'Date création',
        status: 'Statut',
        dateStatus: 'Date statut',
        approveDate: 'Date approbation/rejet',
        startDate: 'Date début implémentation',
        endDate: 'Date fin d’implémentation',
        effectiveDate: 'Date recette implémentation',
        name: 'Nom du porteur',
      },
      form: {
        name: 'Nom du porteur',
        status: 'Statut',
        approve: 'Approbation/rejet',
        startDate: 'Début de l’implémentation',
        endDate: 'Fin de l’implémentation',
        effectiveDate: 'Recette de l’implémentation',
      },
      actions: 'Actions',
      actionCounter: (count: string) => `${count} actions`,
      grid: {
        number: 'Numéro',
        description: 'Description',
        creationDate: 'Date création',
        status: 'Statut',
        dateStatus: 'Date statut',
        carrier: 'Porteur',
        rexCount: 'Nbr de REX associés',
      },
      chooseCauseType: 'Choisir un type de cause pour ce REX',
      soRelevant: 'si pertinent',
      addAction: "Ajouter l'action au REX",
      editAction: "Editer l'action",
    },
    exportResults: {
      title: 'Exporter les resultats',
      subtitle: 'Choisissez le format d’export.',
      spreadsheet: 'Tableur',
      csv: 'CSV',
    },
    saveSearch: {
      title: 'Sauvegarder ma recherche',
      inputLabel: 'Nommer votre recherche',
    },
    editColumns: {
      title: 'Éditer les colonnes',
      subtitle: 'Activer ou désactiver les colonnes dont vous avez besoin.',
    },
    // createCresag: {
    //   title: "Créer un CRESAG",
    //   subtitle:
    //     "Vous allez créer un CRESAG et envoyer les informations suivantes au contributeur sécurité :",
    //   createCresag: "Créer un CRESAG",
    // },
    cresagIdentification: {
      title: 'Identification d’un CRESAG',
      subtitle:
        'Veuillez vérifier si un ou plusieurs cas correspondent à votre évènement.',
      noOne: 'Non, aucune',
      voluntaryCresag: 'CRESAG volontaire',
      flightOperations: {
        title: 'Opérations aériennes',
        values: [
          'Perte de contrôle involontaire.',
          "Atterrissage en dehors de l'aire d'atterrissage prévue.",
          "Impossibilité d'atteindre les performances de l'aéronef, escomptées en conditions normales, lors du décollage, de la montée ou de l'atterrissage.",
          'Incursion sur piste.',
          'Sortie de piste.',
          "Tout vol effectué au moyen d'un aéronef inapte au vol ou pour lequel la préparation de vol était incomplète, qui a mis ou aurait pu mettre en danger l'aéronef, ses occupants ou toute autre personne.",
          "Vol involontaire en conditions IMC (conditions météorologiques de vol aux instruments) d'un aéronef non certifié IFR (règles de vol aux instruments), ou d'un pilote non qualifié IFR, qui a mis ou aurait pu mettre en danger l'aéronef, ses occupants ou toute autre personne.",
        ],
      },
      technicalEvents: {
        title: 'Événements techniques',
        values: [
          "Vibration anormalement forte (par exemple entrée en résonance d'aileron ou de gouverne de profondeur, ou d'hélice).",
          'Toute commande de vol ne fonctionnant pas correctement ou déconnectée.',
          "Défaillance ou détérioration importante de la structure de l'aéronef.",
          "Perte d'un élément de la structure ou d'une installation de l'aéronef en vol.",
          "Défaillance d'un moteur, d'un rotor, d'une hélice, d'un système d'alimentation en carburant ou de tout autre système essentiel.",
          "Fuite d'un fluide ayant entraîné un risque d'incendie ou de contamination dangereuse de la structure, des systèmes ou de l'équipement de l'aéronef, ou un danger pour les occupants.",
        ],
      },
      interaction: {
        title:
          'Interaction avec les services de navigation aérienne et la gestion du trafic aérien',
        values: [
          "Interaction avec les services de navigation aérienne (par exemple fourniture de services incorrects, communications contradictoires ou écart par rapport à l'autorisation) qui a mis ou aurait pu mettre en danger l'aéronef, ses occupants ou toute autre personne.",
          "Non-respect de l'espace aérien.",
        ],
      },
      urgency: {
        title: 'Urgences et autres situations critiques',
        values: [
          "Tout événement entraînant un appel d'urgence, y compris pour un pan-pan.",
          "Incendie, explosion, fumée, gaz ou émanations toxiques à l'intérieur de l'aéronef.",
          "Incapacité du pilote entraînant l'impossibilité d'effectuer toute tâche.",
        ],
      },
      environment: {
        title: 'Environnement extérieur et météorologie',
        values: [
          "Collision, au sol ou en l'air, avec un autre aéronef, le sol ou un obstacle.",
          "Quasi-collision, au sol ou en l'air, avec un autre aéronef, le sol ou un obstacle, exigeant une manœuvre d'évitement d'urgence.",
          "Impact d'animaux y compris collision aviaire ayant provoqué des dégâts à l'aéronef ou la perte ou le dysfonctionnement d'un service essentiel.",
          "Interférence avec l'aéronef causée par des armes à feu, feux d'artifice, cerfs-volants, illuminations laser, lumières puissantes, lasers, aéronefs télépilotés, modèles réduits ou par des moyens similaires.",
          "Impact de foudre provoquant des dégâts à l'aéronef ou la perte de fonctions de l'aéronef.",
          "Fortes turbulences ayant entraîné des blessures pour les occupants ou justifié de soumettrel'aéronef à une inspection après vol en turbulence.",
          "Givrage, y compris du carburateur, qui a mis ou aurait pu mettre en danger l'aéronef, ses occupants ou toute autre personne.",
        ],
      },
      validate: 'Valider',
      createCresag: 'Valider et créer un CRESAG',
    },
  },
  pages: {
    dashboard: 'Tableau de bord',
    rex: 'Gestion des REX',
    search: 'Rechercher',
    favorites: 'Mes favoris',
    settings: 'Paramètres',
  },
  // PRIVACY POLICY
  privacyPolicy: {
    title: 'Politique de confidentialité',
  },
  // REX PAGE
  rex: {
    home: {
      rexManagement: 'Gestion des REX',
      subtitle: 'Créez, modifiez des REX et traitez les signalements.',
      createRex: 'Créer un REX vierge',
      reportToProcess: 'Signalements à traiter',
      rexToComplete: 'REX à compléter',
      rexToPublish: 'REX à publier',
      search: 'Search',
      myLastsUpdatedRex: 'Mes derniers REX modifiés ',
    },
    newRex: {
      createRexFromReport: {
        title: 'Saisie du REX à partir du signalement',
        subtitle: (reportId: string) => `Signalement n°${reportId}`,
        archiveReport: 'Archiver le signalement',
        deleteReport: 'Supprimer le signalement',
        deleteReportConfirmTitle: 'Suppresssion du signalement',
        deleteReportConfirmDescription:
          'Etes-vous sûr de vouloir supprimer ce signalement ? Cette action est irréversible.',
        archiveReportConfirmTitle: 'Archivage du signalement',
        archiveReportConfirmDescription:
          'Etes-vous sûr de vouloir archiver ce signalement ?',
      },
      completeRex: {
        title: 'Saisie du REX',
        subtitle: 'Complétez et modifiez le REX.',
      },
      confirmVisibility: {
        title: 'Confirmer le niveau de visibilité du REX',
        needValue: 'Vous devez choisir une valeur',
      },
      showRexHistory: 'Voir l’historique du REX',
      restoreVersion: 'Restaurer cette version',
      leave: 'Quitter',
      createEmptyRex: {
        title: 'Saisie du REX',
        subtitle: 'Créer un REX vierge',
      },
      form: {
        title: 'Titre du REX',
        titlePlaceholder: 'Titre',
        description: 'Description',
      },
      generalInformation: {
        title: 'Informations générales',
        rexType: 'Type de REX',
        audioRecord: 'Enregistrement audio',
        rexSubject: 'Objet du REX',
        eventDate: 'Date évènement',
        flightPhase: 'Phase de vol',
        place: 'Lieu Géographique',
        placeDescription: 'Précision sur le lieu de l’évènement',
        flightKind: 'Nature de vol',
        flightType: 'Type de vol',
        aircraftType: 'Type d’aéronef',
        aircraftRegistration: 'Immatriculation d’aéronef',
        eventType: 'Type d’évènement',
        eventKind: 'Classe de l’évènement',
        flightRegime: 'Régime de vol',
        tooltip: {
          placeDescription:
            'QFU, numéro de taxiway, point de la procédure, radial distance, coordonnées,...',
        },
      },
      cpsAnalysis: {
        title: 'Analyse CPS',
        eventSummary: 'Résumé de l’évènement',
        mainCause: 'Causes principales',
        addCause: 'Ajouter une cause',
        correcticeActions: 'Actions correctrices',
        addAction: 'Ajouter une action',
        safetyEducation: 'Enseignement de sécurité',
        initialRisk: 'Risque initial',
        residualRisk: 'Risque atténué',
      },
      metaData: {
        title: 'Méta-données',
        id: 'ID',
        rexStatus: 'Statut du REX',
        beaInvestigation: 'Enquête au BEA',
        creationDate: 'Date de création ',
        createdBy: 'Créé par',
        updateDate: 'Dernière modification',
        updatedBy: 'Modifié par',
        createCRESAG: 'Créer un CRESAG',
        clubConcerned: 'Club concerné',
        cpsConcerned: 'CPS concerné',
        declarer: 'Déclarant',
        rexVisibility: 'Niveau de visibilité du REX',
        cresagReference: 'N° référence CRESAG',
        cresagStatus: 'Statut CRESAG',
        cresagVersion: 'N° version CRESAG',
        cresagCreationDate: 'Date création CRESAG',
        cresagForm: 'Formulaire CRESAG',
        seeCresagForm: 'Voir le formulaire CRESAG',
      },
      placeholders: {
        title: 'Titre',
        description: 'Description',
        oaciCode: 'Code OACI',
        selectValue: 'Sélectionner une valeur',
        summary: 'Résumé',
        type: 'Type',
        registration: 'Immatriculation',
        club: 'Club',
        cps: 'CPS',
      },
    },
    newCresag: {
      title: 'Saisie du CRESAG',
      subtitle: (rexId: string) => `Rex n°${rexId}`,
      returnToAssociatedRex: 'Retour à la fiche REX associée',
      descriptionSection: {
        title: 'Description',
        general: 'Général',
        activityType: 'Type d’activité',
        rexTitle: 'Titre du Rex',
        eventClass: 'Classe de l’évènement',
        eventStatus: 'Statut de l’évènement',
        informedEntities: 'Entités informées',
        eventReference: 'Référence de l’évènement',
        versionNumber: 'Numéro de version',
        dateAndPlace: 'Lieu & date',
        date: 'Date UTC',
        hour: 'Heure UTC',
        eventZone: 'Zone où a eu lieu l’évènement',
        oaciCode: 'Code OACI de l’aérodrome',
        details: 'Précisions sur le lieu de l’évènement',
        descriptionAndContext: 'Description de l’évènement et de son contexte',
        description: 'Description',
      },
      aircraftSection: {
        title: 'Aéronef',
        basicInformation: 'Informations de base',
        aircraftModel: 'Modèle aeronef',
        registration: 'Immatriculation',
        radioCallSign: 'Indicatif radio',
        exploiting: 'Exploitant',
        flightPhase: 'Phase de vol',
        flightKind: 'Nature de vol',
        additionalAircraftInformation:
          'Informations additionnelles sur l’aéronef',
        departureAerodrome: 'Dernier aérodrome de départ',
        destination: 'Destination prévue',
      },
      analysisSection: {
        title: 'Analyse',
        classificationAndRisk: 'Classification risque & évènement',
        event: 'Évènement',
        riskClassification: 'Classification du risque',
        classificationMethod: 'Méthode de classification',
        eventType: 'Type d’évènement avéré',
        analysisResult: 'Résultats de l’analyse',
        conclusions: 'Conclusions',
        correctiveActions: 'Actions correctives',
      },
    },
    reportToProcess: {
      grid: {
        id: 'ID',
        author: 'Auteur',
        subject: 'Objet',
        urgency: 'Urgence',
        date: 'Date',
        creationDate: 'Date de création',
        flightPhase: 'Phase vol',
        reportType: 'Type Signalement',
      },
    },
  },
  rexHistory: {
    title: 'Historique des versions',
    currentVersion: 'Version actuelle',
  },
  rexPublicationDialog: {
    publishedTitle: 'REX publié !',
    waitingValidationTitle: 'REX en attente de validation',
    publishedContent: 'Votre REX est maintenant publié. Merci',
    waitingValidationContent: (level: string) =>
      `Votre REX sera publié au niveau '${level}' après validation du modérateur de ce niveau. Merci`,
  },
  // SEARCH PAGE
  search: {
    search: 'Search',
    types: {
      rex: 'Rex',
      cause: 'Cause',
      action: 'Action',
    },
    searchRex: 'Rechercher des REX',
    searchActions: 'Rechercher des Actions',
    searchCauses: 'Rechercher des Causes',
    advancedSearch: 'Recherche avancée',
    resultsFound: 'Résultats trouvés',
    resultCounterString: (count: string) => `${count} REX`,
    editColumns: 'Editer les colonnes',
    exportResults: 'Exporter les resultats',
    advancedSearchHeader: {
      rexTitle: 'Recherche avancée de REX',
      causeTitle: 'Recherche avancée de causes',
      actionTitle: "Recherche avancée d'actions",
      searchArea: 'Périmètre de la recherche',
      rexCharacteristic: 'Caractéristiques du REX',
      cresagInfo: 'Infos CRESAG',
      rexInfo: 'Informations du REX',
      rexAnalysis: 'Analyse du REX',
      myRexSearch: 'Mes recherches de REX',
      saveSearch: 'Sauvegarder ma recherche',
      noSavedSearch: 'Aucune recherche sauvegardée',
      associatedRexInfo: 'Informations REX associé(s)',
      causeInfo: 'Informations causes',
      actionInfo: 'Informations actions',
    },
    advancedSearchParams: {
      searchArea: {
        all: 'Tout FFA',
        cra: 'CRA(s)',
        cda: 'CDA(s)',
        platform: 'Plateforme(s)',
        interclub: 'Interclub(s)',
        clubs: 'Club(s)',
      },
      associatedRexInfo: {
        rexNumber: 'Numéro du REX',
        rexCreationDate: 'Date création REX',
      },
      causeInfo: {
        number: 'Numéro',
        type: 'Type',
        title: 'Titre',
        description: 'Description',
      },
      actionInfo: {
        number: 'Numéro',
        description: 'Description',
        creationDate: 'Date de création',
        status: 'Statut',
        statusDate: 'Date statut',
        approvalDate: 'Date approbation/rejet',
        startDate: 'Date début implémentation',
        endDate: 'Date fin d’implémentation',
        commissioningDate: 'Date recette implémentation',
        carrierName: 'Nom du porteur',
      },
      rexCharacteristic: {
        number: 'Numéro',
        creationDate: 'Date de création',
        updateDate: 'Date de modification',
        createdBy: 'Créé par',
        updatedBy: 'Modifier par',
        aeroclub: 'Aéroclub',
        cps: 'CPS',
        declarer: 'Déclarant',
        anonymous: 'Anonyme',
        published: 'Publié',
        archived: 'Archivé',
      },
      cresagInfo: {
        id: 'Identifiant',
        sendDate: 'Date d’envoi',
        investigation: 'Enquête',
        createdBy: 'Créé par',
      },
      rexInfo: {
        rexType: 'Type de REX',
        rexSubject: 'Objet du REX',
        eventDate: 'Date évènement',
        eventPlace: 'Lieu évènement',
        flightPhase: 'Phase de vol',
        rexTitle: 'Titre du REX',
        rexDescription: 'Description du REX',
        flightKind: 'Nature du vol',
        flightType: 'Type de vol',
        eventType: 'Type évènement',
        aircraftType: 'Type aéronef',
        aircraftRegistration: 'Immatriculation aéronef',
        eventKind: 'Classe évènement',
      },
      rexAnalysis: {
        eventSummary: 'Résumé de l’évènement',
        instructorTips: 'Conseils instructeurs',
        riskProbability: 'Risque - Probabilité',
        riskImpact: 'Risque - Impact',
      },
    },
  },
  // SEARCH RESULT PAGE
  searchResult: {
    backToResults: 'Retour',
    generalInfo: 'Informations générales',
    cpsAnalysis: 'Analyse CPS',
    eventSummary: 'Résumé de l’évènement',
    safetyEducation: 'Enseignements de sécurité',
    initialRisk: 'Risque initial de l’évènement',
    residualRisk: 'Risque atténué de l’évènement',
    associatedRexs: 'REX associé(s)',
    actionTitle: (id: number) => `Action ${id}`,
    creationDate: 'Date de création',
    status: 'Statut',
    statusDate: 'Date du statut',
    carrierName: 'Nom du porteur',
    approvalDate: 'Date d’approbation/rejet',
    startDate: 'Date de début de l’implémentation',
    endDate: 'Date de fin réelle de l’implémentation',
    commissioningDate: 'Date de la recette de l’implémentation',
    archiveRex: 'Archiver le REX',
    removeRex: 'Supprimer le REX',
    deleteRexConfirmTitle: 'Suppresssion du REX',
    deleteRexConfirmDescription:
      'Etes-vous sûr de vouloir supprimer ce REX ? Cette action est irréversible.',
    archiveRexConfirmTitle: 'Archivage du REX',
    archiveRexConfirmDescription: 'Etes-vous sûr de vouloir archiver ce REX ?',
  },
  // SETTINGS PAGE
  settings: {
    title: 'Paramètres',
    activateTestEnv: 'Environnement de formation',
    activateTestEnvDescription: 'Activer l’environnement de formation',
    changeEnv: 'Changement d’environnement',
    changeEnvDescription:
      "Êtes-vous sûr de vouloir changer d'environnement ? Cela va vous déconnecter de l'application.",
    notifications: {
      title: 'Notifications',
      immediate: {
        title: 'Notification immédiate de club',
        description:
          'Être notifié par mail quand un nouveau REX concernant mon (mes) club(s) est publié.',
      },
      weekly: {
        title: 'Notification hebdomadaire des nouveaux REX',
        description:
          'Être notifié par mail chaque semaines sur les nouveaux REX publiés',
      },
      newsletter: {
        title: 'Newsletter REXFFA',
        description:
          'Recevoir par mail des informations générales concernant REXFFA.',
      },
    },
  },
};
