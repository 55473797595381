import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { s } from '../../strings/strings';

interface Props {
  children: React.ReactNode;
  title: string;
  description?: string;
  onConfirm: () => void;
}

export const ConfirmDialog = ({
  children,
  title,
  description,
  onConfirm,
}: Props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <div
        id="delete-dialog"
        onClick={(e) => {
          handleClickOpen();
          e.stopPropagation();
        }}
        style={{ display: 'flex' }}
      >
        {children}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        {description && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {description}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose}>{s.common.button.cancel}</Button>
          <Button
            onClick={() => {
              onConfirm();
              handleClose();
            }}
            autoFocus
          >
            {s.common.button.validate}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
