import React, { useEffect, useState } from 'react';
import { palette } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import { CustomModal } from '../common/CustomModal';
import refreshIcon from '../../assets/icons/refresh.svg';
import { CustomButton } from '../common/CustomButton';
import { s } from '../../strings/strings';
import { largerMargin } from '../../styles/styles';
import { SearchFilterType } from '../../types/common/main';
import { CustomSearchFilterInput } from './CustomSearchFilterInput';

interface CustomSearchFilterModalProps {
  title: string;
  open: boolean;
  defaultValue?: any;
  onClose: (value: any) => void;
  type?: SearchFilterType;
  placeholder?: string;
}

export const CustomSearchFilterModal: React.FC<
  CustomSearchFilterModalProps
> = ({ title, open, defaultValue, onClose, type, placeholder }) => {
  const [value, setValue] = useState<any>(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  let customPlaceholder = placeholder;

  if (!customPlaceholder) {
    if (type === 'text') customPlaceholder = s.common.placeholders.name;
    else if (type === 'number')
      customPlaceholder = s.common.placeholders.number0000;
  }

  return (
    <>
      <CustomModal
        open={open}
        onClose={() => {
          onClose(value);
        }}
        style={{ minWidth: 600 }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p
            style={{
              ...fonts.semiBold18,
              color: palette.gray800,
              textAlign: 'center',
              marginTop: 0,
            }}
          >
            {title}
          </p>
          <div
            style={{
              paddingLeft: largerMargin,
              paddingRight: largerMargin,
              width: `calc(100% - ${largerMargin + largerMargin}px)`,
            }}
          >
            <CustomSearchFilterInput
              valueState={[value, setValue]}
              type={type}
              placeholder={customPlaceholder}
            />
          </div>
          <CustomButton
            title={s.common.button.reset}
            onClick={() => {
              setValue(null);
              onClose(null);
            }}
            iconSrc={refreshIcon}
            style={{
              color: palette.gray700,
              backgroundColor: 'transparent',
              alignSelf: 'flex-end',
            }}
          />
        </div>
      </CustomModal>
    </>
  );
};
