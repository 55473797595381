import React from 'react';
import { DashboardCharts } from './common/DashboardCharts';
import { Grid } from '@mui/material';

interface DashboardCSSectionProps {
  selectedEntityIdx?: string;
  entityChildren?: string[];
}

export const DashboardCSSection: React.FC<DashboardCSSectionProps> = ({
  selectedEntityIdx,
  entityChildren,
}) => {
  return (
    <>
      <Grid container rowSpacing={1.5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <DashboardCharts
          selectedEntityIdx={selectedEntityIdx}
          entityChildren={entityChildren}
        />
      </Grid>
    </>
  );
};
