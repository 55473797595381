import { BasicPage } from '../../components/templates/BasicPage';
import { s } from '../../strings/strings';
import { palette } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import { defaultMargin, smallMargin } from '../../styles/styles';

const TitleH1 = ({ children }: { children: string }) => (
  <h1 style={{ ...fonts.bold30, margin: 0, marginBottom: defaultMargin }}>
    {children}
  </h1>
);

const TitleH2 = ({ children }: { children: string }) => (
  <h2 style={{ ...fonts.bold22, margin: 0, marginBottom: smallMargin }}>
    {children}
  </h2>
);

const Paragraph = ({ children }: { children: string }) => (
  <p style={{ ...fonts.regular16 }}>{children}</p>
);

export const GCUPage = () => {
  return (
    <BasicPage style={{ backgroundColor: palette.gray50 }} hideNav>
      <div>
        <TitleH1>{s.gcu.title}</TitleH1>
        <TitleH2>Règles de confidentialité</TitleH2>
        <Paragraph>
          REXFFA - mobile est éditée par la FFA (Fédération Française
          Aéronautique), association loi 1901 créée en 1929 et reconnue
          d’utilité publique depuis 1933, située 155 avenue de Wagram, 75017
          Paris, et dont le but est de promouvoir l’aviation légère. Le terme
          Application s’applique à l’application pour mobile ou tablette Android
          et IOS nommée « REXFFA ». Nos règles de confidentialité expliquent
          quelles données nous collectons depuis l’Application et les raisons de
          cette collecte, et comment nous utilisons / traitons ces informations.
        </Paragraph>
        <TitleH2>Données personnelles</TitleH2>
        <Paragraph>
          Depuis notre Application, nous accédons à certaines données
          personnelles, comme les noms, prénoms, identifiants personnels de
          connexion (login et mot de passe), ainsi que les adresses de courrier
          électronique qui sont déposés dans la base fédérale (SMILE) par tout
          licencié de la FFA lors de son adhésion. Cependant, nous ne les
          collectons pas depuis, ni dans, cette Application. Les adresses de
          courrier électronique et les identifiants personnels de connexion sont
          requis pour l’utilisation de l’Application, et ne sont pas traités de
          manière éphémère. Nous partageons ces données pour le fonctionnement
          de l’application, la prévention des fraudes, la sécurité et la
          conformité, ainsi que pour la gestion des comptes. Toutes ces données
          sont chiffrées lors de l’échange entre l’Application et SMILE.
        </Paragraph>
        <TitleH2>Autres types de données</TitleH2>
        <Paragraph>
          L’Application demande l’accès à : • l’appareil photo, afin de prendre
          des clichés lors de la génération d’un signalement (optionnel) ; • au
          microphone, afin de pouvoir dicter un commentaire dans un signalement
          (optionnel) ; • aux fichiers et contenus multimédia : requis par le
          système si l’on a validé au moins un des accès précédents, inutile
          sinon. Notre application ne collecte AUCUN autre type de données, et
          ne demande AUCUN autre accès particulier au smartphone.
        </Paragraph>
        <TitleH2>Règles de confidentialité</TitleH2>
        <Paragraph>
          REXFFA - mobile est éditée par la FFA (Fédération Française
          Aéronautique), association loi 1901 créée en 1929 et reconnue
          d’utilité publique depuis 1933, située 155 avenue de Wagram, 75017
          Paris, et dont le but est de promouvoir l’aviation légère. Le terme
          Application s’applique à l’application pour mobile ou tablette Android
          et IOS nommée « REXFFA ». Nos règles de confidentialité expliquent
          quelles données nous collectons depuis l’Application et les raisons de
          cette collecte, et comment nous utilisons / traitons ces informations.
        </Paragraph>
        <TitleH2>Données personnelles</TitleH2>
        <Paragraph>
          Depuis notre Application, nous accédons à certaines données
          personnelles, comme les noms, prénoms, identifiants personnels de
          connexion (login et mot de passe), ainsi que les adresses de courrier
          électronique qui sont déposés dans la base fédérale (SMILE) par tout
          licencié de la FFA lors de son adhésion. Cependant, nous ne les
          collectons pas depuis, ni dans, cette Application. Les adresses de
          courrier électronique et les identifiants personnels de connexion sont
          requis pour l’utilisation de l’Application, et ne sont pas traités de
          manière éphémère. Nous partageons ces données pour le fonctionnement
          de l’application, la prévention des fraudes, la sécurité et la
          conformité, ainsi que pour la gestion des comptes. Toutes ces données
          sont chiffrées lors de l’échange entre l’Application et SMILE.
        </Paragraph>
        <TitleH2>Autres types de données</TitleH2>
        <Paragraph>
          L’Application demande l’accès à : • l’appareil photo, afin de prendre
          des clichés lors de la génération d’un signalement (optionnel) ; • au
          microphone, afin de pouvoir dicter un commentaire dans un signalement
          (optionnel) ; • aux fichiers et contenus multimédia : requis par le
          système si l’on a validé au moins un des accès précédents, inutile
          sinon. Notre application ne collecte AUCUN autre type de données, et
          ne demande AUCUN autre accès particulier au smartphone.
        </Paragraph>
      </div>
    </BasicPage>
  );
};
