import logo from '../../assets/images/logo.svg';
import loginImage from '../../assets/images/login-image.svg';
import { fonts } from '../../styles/fonts';
import { palette } from '../../styles/colors';
import { s } from '../../strings/strings';
import { CustomButton } from '../../components/common/CustomButton';
import { CustomTextInput } from '../../components/common/CustomTextInput';
import { useEffect, useState } from 'react';
import {
  defaultMargin,
  largeMargin,
  largerMargin,
  smallMargin,
} from '../../styles/styles';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Snackbar, Stack, Switch } from '@mui/material';
import { useMainData } from '../../providers/MainDataProvider';
import { useAuth } from '../../providers/AuthProvider';

export const LoginPage = () => {
  const { todoAlert } = useMainData();
  const { smileLogin, login: loginToRexFFA, user, smileUser } = useAuth();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState('');
  const [isMFAVisible, setIsMFAVisible] = useState(false);

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');

  useEffect(() => {
    if (smileUser && user) navigate('/dashboard');
  }, [smileUser, user, navigate]);

  const onSubmitLogin = () => {
    if (!login) {
      setIsError(s.login.enterLogin);
      return;
    }
    if (!password) {
      setIsError(s.login.enterPassword);
      return;
    }

    setIsLoading(true);
    smileLogin(login, password)
      .then((res) => {
        if (res.disable_mfa) {
          navigate('/dashboard');
        } else {
          setIsMFAVisible(true);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 403)
          setIsError(s.login.errorIncorrectCredentials);
        else setIsError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmitMFA = () => {
    setIsLoading(true);

    if (!code) {
      setIsError(s.mfa.errorEnterCode);
      return;
    }

    loginToRexFFA(login, Number(code))
      .then(() => {
        navigate('/dashboard');
      })
      .catch((error) => {
        if (error?.response?.status === 403)
          setIsError(s.mfa.errorIncorrectCode);
        else setIsError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div style={{ height: '100vh' }}>
        <div style={{ display: 'flex', padding: 32 }}>
          <img
            src={loginImage}
            alt=""
            style={{ height: 'calc(100vh - 64px)' }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '50%',
                alignItems: 'center',
              }}
            >
              {isMFAVisible ? (
                <MFAContent
                  mfaState={[code, setCode]}
                  onSubmit={onSubmitMFA}
                  isLoading={isLoading}
                />
              ) : (
                <LoginContent
                  loginState={[login, setLogin]}
                  passwordState={[password, setPassword]}
                  onSubmit={onSubmitLogin}
                  isLoading={isLoading}
                />
              )}
            </div>
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                display: 'flex',
                justifyContent: 'space-between',
                left: 0,
                right: 0,
                marginLeft: largerMargin,
                marginRight: defaultMargin,
                borderTop: `1px solid ${palette.gray200}`,
              }}
            >
              {/* <Link to="" style={styles.link} onClick={todoAlert}>
                {s.login.needHelp}
              </Link> */}
              <Stack
                spacing={`${largerMargin}px`}
                direction="row"
                style={{ marginLeft: 'auto' }}
              >
                <Link to="/privacy-policy" style={styles.link}>
                  {s.login.privacyPolicy}
                </Link>
                <Link to="/gcu" style={styles.link}>
                  {s.login.TOS}
                </Link>
              </Stack>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={!!isError}
        autoHideDuration={6000}
        onClose={() => setIsError('')}
      >
        <Alert
          onClose={() => setIsError('')}
          severity="error"
          sx={{ width: '100%' }}
        >
          {isError}
        </Alert>
      </Snackbar>
    </>
  );
};

interface LoginContentProps {
  loginState: [string, React.Dispatch<React.SetStateAction<string>>];
  passwordState: [string, React.Dispatch<React.SetStateAction<string>>];
  onSubmit: () => void;
  isLoading: boolean;
}

const LoginContent: React.FC<LoginContentProps> = ({
  loginState,
  passwordState,
  onSubmit,
  isLoading,
}) => {
  const { primaryColors, isTestEnvironment, setIsTestEnvironment } =
    useMainData();

  return (
    <>
      <img src={logo} alt="" width={175} />
      <p
        style={{
          ...fonts.semiBold24,
          color: primaryColors.primary,
          marginBottom: 0,
        }}
      >
        {s.login.welcome}
      </p>
      <p
        style={{
          ...fonts.medium16,
          color: palette.gray700,
          marginTop: smallMargin,
          marginBottom: defaultMargin,
          textAlign: 'center',
        }}
      >
        {s.login.enterCredentials}
      </p>
      <CustomTextInput
        label={s.login.smileLogin}
        tooltip={s.login.loginTooltip}
        placeholder={s.login.login}
        state={loginState}
        style={{ marginBottom: defaultMargin }}
        columnGap={0}
        name="username"
        autoComplete="username"
      />
      <CustomTextInput
        label={s.login.password}
        placeholder={s.login.password}
        state={passwordState}
        type="password"
        name="password"
        autoComplete="current-password"
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: defaultMargin,
          marginBottom: defaultMargin,
        }}
      >
        <Switch
          checked={isTestEnvironment}
          onChange={(_, checked) => {
            setIsTestEnvironment(checked);
          }}
        />
        <p
          style={{
            ...fonts.medium14,
            color: palette.gray700,
            margin: 0,
          }}
        >
          {s.settings.activateTestEnv}
        </p>
      </div>
      <CustomButton
        title={s.login.submitButton}
        onClick={onSubmit}
        loading={isLoading}
        style={{ width: '100%' }}
      />
    </>
  );
};

interface MFAContentProps {
  mfaState: [string, React.Dispatch<React.SetStateAction<string>>];
  onSubmit: () => void;
  isLoading: boolean;
}

const MFAContent: React.FC<MFAContentProps> = ({
  mfaState,
  onSubmit,
  isLoading,
}) => {
  const { primaryColors } = useMainData();

  return (
    <>
      <img src={logo} alt="" width={175} />
      <p
        style={{
          ...fonts.semiBold24,
          color: primaryColors.primary,
          marginBottom: 0,
        }}
      >
        {s.mfa.verifyMail}
      </p>
      <p
        style={{
          ...fonts.medium16,
          color: palette.gray700,
          marginTop: smallMargin,
          marginBottom: defaultMargin,
          textAlign: 'center',
        }}
      >
        {s.mfa.enterCode}
      </p>
      <CustomTextInput
        label={s.mfa.code}
        placeholder={s.mfa.code}
        state={mfaState}
        style={{ marginBottom: largeMargin }}
        type="number"
      />
      <CustomButton
        title={s.mfa.submitButton}
        onClick={onSubmit}
        loading={isLoading}
        style={{ width: '100%' }}
      />
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  link: {
    ...fonts.medium12,
    color: palette.gray800,
    textDecoration: 'none',
    padding: '20px 0px',
  },
};
