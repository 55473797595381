import { useState, ChangeEvent } from 'react';
import { BasicFile } from '../types/common/main';

export const useImagePicker = () => {
  const [selectedFiles, setSelectedFiles] = useState<BasicFile[]>([]);

  // Gère la sélection de fichiers
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;

    const updatedFiles: BasicFile[] = Array.from(files).map((file) => ({
      uri: URL.createObjectURL(file),
      type: file.type,
      filename: file.name,
      file,
    }));

    setSelectedFiles((prevSelectedFiles) => [
      ...prevSelectedFiles,
      ...updatedFiles,
    ]);
  };

  // Supprime un fichier sélectionné
  const handleRemoveFile = (index: number) => {
    setSelectedFiles((prevSelectedFiles) => {
      const newFiles = [...prevSelectedFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  return {
    selectedFiles,
    setSelectedFiles,
    handleFileChange,
    handleRemoveFile,
  };
};
