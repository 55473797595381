import React, { ChangeEvent, useEffect, useState } from 'react';
import { CustomModal } from '../common/CustomModal';
import { fonts } from '../../styles/fonts';
import { palette } from '../../styles/colors';
import { s } from '../../strings/strings';
import { CustomButton } from '../common/CustomButton';
import {
  defaultMargin,
  largeMargin,
  smallMargin,
  tinyMargin,
} from '../../styles/styles';
import { Stack, Switch } from '@mui/material';
import { AdvancedSearchItem } from '../search/AdvancedSearchItem';
import { CustomGrid } from '../common/CustomGrid';
import { GridColDef } from '@mui/x-data-grid';
import { CustomTextInput } from '../common/CustomTextInput';
import arrowLeftIcon from '../../assets/icons/arrow-narrow-left.svg';
import { CauseTypeSelector } from './CauseTypeSelector';
import { useCauseMutations } from '../../hooks/useCauseMutations';
import { Cause, CauseData, UpdateCause } from '../../types/common/api';
import { CauseType } from '../../types/common/main';
import { useMainData } from '../../providers/MainDataProvider';
import { getSearchColor } from '../../utils/main';

interface AddCauseModalProps {
  open: boolean;
  onClose: () => void;
  rowSelectionState?: [
    CauseData[],
    React.Dispatch<React.SetStateAction<CauseData[]>>
  ];
  causeToEdit?: Cause;
  onBack?: () => void;
}

export const AddCauseModal: React.FC<AddCauseModalProps> = ({
  open,
  onClose,
  rowSelectionState,
  causeToEdit,
  onBack,
}) => {
  const [isAddingCause, setIsAddingCause] = useState(false);

  return (
    <>
      <CustomModal
        open={open}
        onClose={onClose}
        closeCross={false}
        style={{ minWidth: 950 }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {isAddingCause || causeToEdit ? (
            <AddCauseContent
              onBack={onBack ? onBack : () => setIsAddingCause(false)}
              causeToEdit={causeToEdit}
              setRowSelection={rowSelectionState?.[1]}
            />
          ) : (
            <MainContent
              onAddCause={() => setIsAddingCause(true)}
              rowSelectionState={rowSelectionState}
              onClose={onClose}
            />
          )}
        </div>
      </CustomModal>
    </>
  );
};

interface MainContentProps {
  onAddCause: () => void;
  onClose: () => void;
  rowSelectionState?: [
    CauseData[],
    React.Dispatch<React.SetStateAction<CauseData[]>>
  ];
}

const MainContent: React.FC<MainContentProps> = ({
  onAddCause,
  onClose,
  rowSelectionState,
  // causeDataState,
}) => {
  const { isTestEnvironment } = useMainData();
  const { causes } = useCauseMutations();

  const rowSelection = rowSelectionState?.[0];
  const setRowSelection = rowSelectionState?.[1];

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: s.modals.addCause.grid.number,
      flex: 1,
    },
    {
      field: 'title',
      headerName: s.modals.addCause.grid.title,
      flex: 1,
    },
    {
      field: 'description',
      headerName: s.modals.addCause.grid.description,
      flex: 1,
    },
    {
      field: 'cause_type',
      headerName: s.modals.addCause.mainCause,
      flex: 1,
      renderCell: (params) => {
        const rowId = params.row?.id;
        const currentValue = rowSelection?.find((c) => c.id === rowId);

        if (!currentValue) return 'N/A';

        const defaultChecked = currentValue?.type === CauseType.MainCause;

        const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
          const checked = event.target.checked;
          const type = checked
            ? CauseType.MainCause
            : CauseType.ContributingFactor;

          setRowSelection?.((oldData) => {
            return oldData?.map((e) =>
              e.id === rowId ? { type, id: rowId } : e
            );
          });
        };

        return (
          <Switch onChange={handleChange} defaultChecked={defaultChecked} />
        );
      },
    },
    {
      field: 'rex_count',
      headerName: s.modals.addCause.grid.rexCount,
      flex: 1,
      valueGetter: (params) => params?.row?._count?.rexs,
    },
  ];

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: defaultMargin,
          marginLeft: 0,
          marginRight: 0,
          flexGrow: 1,
          maxHeight: '75vh',
        }}
      >
        <p
          style={{
            ...fonts.bold20,
            textAlign: 'center',
            marginTop: 0,
          }}
        >
          {s.modals.addCause.title}
        </p>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: defaultMargin,
            marginBottom: smallMargin,
          }}
        >
          <p
            style={{
              ...fonts.bold18,
              margin: 0,
            }}
          >
            {s.modals.addCause.searchCauses}
          </p>
          <CustomButton
            title={s.modals.addCause.createCause}
            onClick={onAddCause}
            style={{
              ...fonts.medium14,
              color: getSearchColor('rex', isTestEnvironment)?.border,
              backgroundColor: getSearchColor('rex', isTestEnvironment)
                ?.background,
              border: `1px solid ${
                getSearchColor('rex', isTestEnvironment)?.border
              }`,
              flexGrow: 0,
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <p style={{ ...fonts.medium14, marginRight: defaultMargin }}>
            {s.modals.addCause.filters.title}
          </p>
          <Stack
            spacing={`${smallMargin}px`}
            direction="row"
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: smallMargin,
            }}
          >
            <AdvancedSearchItem
              title={s.modals.addCause.filters.number}
              type="number"
              color={getSearchColor('rex', isTestEnvironment)?.main}
            />
            <AdvancedSearchItem
              title={s.modals.addCause.filters.type}
              type="text"
              color={getSearchColor('rex', isTestEnvironment)?.main}
            />
            <AdvancedSearchItem
              title={s.modals.addCause.filters.description}
              type="text"
              inputModalPlaceholder={s.common.placeholders.description}
              value="Description"
              color={getSearchColor('rex', isTestEnvironment)?.main}
            />
          </Stack>
        </div>
        <div
          style={{
            marginTop: smallMargin,
            width: '100%',
            flexShrink: 1,
            overflowY: 'auto',
          }}
        >
          <CustomGrid
            rows={causes || []}
            columns={columns}
            rowSelectionState={[
              rowSelection?.map((e) => e.id) || [],
              (model: any) => {
                const idsArray: number[] = model;
                const newValues = idsArray?.map(
                  (id) =>
                    rowSelection?.find((c) => c.id === id) || {
                      id,
                      type: CauseType.MainCause,
                    }
                );
                setRowSelection?.(newValues);
              },
            ]}
          />
        </div>
      </div>
      <FooterButtons onBack={onClose} onAdd={onClose} />
    </>
  );
};

interface AddCauseContentProps {
  onBack: () => void;
  setRowSelection?: React.Dispatch<React.SetStateAction<CauseData[]>>;
  causeToEdit?: Cause;
}

const AddCauseContent: React.FC<AddCauseContentProps> = ({
  onBack,
  setRowSelection,
  causeToEdit,
}) => {
  const { createCause, updateCause } = useCauseMutations();

  const [isLoading, setIsLoading] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [causeType, setCauseType] = useState(CauseType.MainCause);

  useEffect(() => {
    if (causeToEdit) {
      setTitle(causeToEdit.title);
      setDescription(causeToEdit.description);
    }
  }, [causeToEdit]);

  const onSubmit = () => {
    setIsLoading(true);

    if (causeToEdit) {
      const updatedFields: UpdateCause = {};

      if (title !== causeToEdit.title) updatedFields.title = title;
      if (description !== causeToEdit.description)
        updatedFields.description = description;

      updateCause
        .mutateAsync({
          id: causeToEdit.id,
          cause: updatedFields,
        })
        .finally(() => {
          setIsLoading(false);
          onBack();
        });
    } else {
      createCause
        .mutateAsync({
          title,
          description,
        })
        .then((data) => {
          const cause = data.data;
          setRowSelection?.((oldValues) => {
            const newValues = [...oldValues];
            newValues.push({ id: cause.id, type: causeType });
            return newValues;
          });
        })
        .finally(() => {
          setIsLoading(false);
          onBack();
        });
    }
  };

  const isEdit = !!causeToEdit;

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: defaultMargin,
          marginLeft: 150,
          marginRight: 150,
          flexGrow: 1,
        }}
      >
        <p
          style={{
            ...fonts.bold20,
            textAlign: 'center',
            marginTop: 0,
          }}
        >
          {isEdit ? s.modals.addCause.titleEdit : s.modals.addCause.title}
        </p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              marginTop: defaultMargin,
              marginBottom: largeMargin,
            }}
            onClick={onBack}
          >
            <img
              src={arrowLeftIcon}
              alt=""
              style={{ marginRight: tinyMargin }}
            />
            <p
              style={{
                ...fonts.medium14,
                color: palette.gray600,
                margin: 0,
              }}
            >
              {s.common.button.back}
            </p>
          </div>
          <p
            style={{
              ...fonts.bold18,
              margin: 0,
              marginBottom: smallMargin,
            }}
          >
            {isEdit
              ? s.modals.addCause.editCause
              : s.modals.addCause.createCause}
          </p>
          <CustomTextInput
            label={s.modals.addCause.causeTitle}
            placeholder={s.rex.newRex.form.titlePlaceholder}
            state={[title, setTitle]}
            style={{ marginBottom: defaultMargin }}
          />
          <CustomTextInput
            label={s.rex.newRex.form.description}
            placeholder={s.rex.newRex.form.description}
            multiline
            optional
            numberOfLines={2}
            maxLength={255}
            state={[description, setDescription]}
          />
        </div>
        {!isEdit && <CauseTypeSelector state={[causeType, setCauseType]} />}
      </div>
      <FooterButtons
        onBack={onBack}
        onAdd={onSubmit}
        confirmLoading={isLoading}
        confirmDisabled={!title}
        isEdit={isEdit}
      />
    </>
  );
};

interface FooterButtonsProps {
  onBack: () => void;
  onAdd: () => void;
  confirmLoading?: boolean;
  confirmDisabled?: boolean;
  isEdit?: boolean;
}

const FooterButtons: React.FC<FooterButtonsProps> = ({
  onBack,
  onAdd,
  confirmLoading,
  confirmDisabled,
  isEdit,
}) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          marginTop: defaultMargin + smallMargin,
          alignSelf: 'flex-end',
        }}
      >
        <CustomButton
          title={s.common.button.cancel}
          onClick={onBack}
          style={{
            marginRight: defaultMargin,
            paddingLeft: defaultMargin + smallMargin,
            paddingRight: defaultMargin + smallMargin,
            backgroundColor: 'transparent',
            color: palette.gray600,
          }}
        />
        <CustomButton
          title={
            isEdit ? s.modals.addCause.editCause : s.modals.addCause.addCause
          }
          onClick={onAdd}
          style={{
            marginRight: defaultMargin,
            paddingLeft: defaultMargin + smallMargin,
            paddingRight: defaultMargin + smallMargin,
          }}
          loading={confirmLoading}
          disabled={confirmDisabled}
        />
      </div>
    </>
  );
};
