import React, { useEffect, useState } from 'react';
import { CustomModal } from '../common/CustomModal';
import { fonts } from '../../styles/fonts';
import { colors, palette } from '../../styles/colors';
import { s } from '../../strings/strings';
import { CustomButton } from '../common/CustomButton';
import {
  defaultMargin,
  largeMargin,
  mediumRadius,
  smallMargin,
  tinyMargin,
} from '../../styles/styles';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  SxProps,
  Theme,
} from '@mui/material';
import { useMainData } from '../../providers/MainDataProvider';

interface CresagIdentificationModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (needCresag: boolean) => Promise<void>;
}

export const CresagIdentificationModal: React.FC<
  CresagIdentificationModalProps
> = ({ open, onClose, onSubmit }) => {
  const { primaryColors } = useMainData();
  const [isLoading, setIsLoading] = useState(false);

  const [checkedItemCounter, setCheckedItemCounter] = useState(0);
  const [noOneChecked, setNoOneChecked] = useState(false);
  const [voluntaryChecked, setVoluntaryChecked] = useState(false);

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setCheckedItemCounter((oldValue) =>
      checked ? oldValue + 1 : oldValue - 1
    );
  };

  useEffect(() => {
    if (noOneChecked && checkedItemCounter > 0) setNoOneChecked(false);
  }, [noOneChecked, checkedItemCounter]);

  return (
    <>
      <CustomModal
        open={open}
        onClose={onClose}
        closeCross={false}
        style={{ minWidth: 900, maxHeight: '90vh', overflowY: 'scroll' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: defaultMargin,
          }}
        >
          <p
            style={{
              ...fonts.bold20,
              textAlign: 'center',
              marginTop: 0,
            }}
          >
            {s.modals.cresagIdentification.title}
          </p>
          <p
            style={{
              ...fonts.semiBold16,
              color: palette.gray900,
              textAlign: 'center',
              marginTop: defaultMargin,
              marginBottom: defaultMargin + smallMargin,
            }}
          >
            {s.modals.cresagIdentification.subtitle}
          </p>
          <div
            style={{
              marginLeft: largeMargin,
              marginRight: largeMargin,
              width: `calc(100% - ${largeMargin + largeMargin}px)`,
              borderRadius: mediumRadius,
            }}
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6} style={styles.gridItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      style={styles.checkbox}
                      checked={noOneChecked}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setNoOneChecked(checked);
                        if (checked) setVoluntaryChecked(false);
                      }}
                    />
                  }
                  label={s.modals.cresagIdentification.noOne}
                  sx={sx.formControlLabel}
                  style={{ paddingLeft: smallMargin }}
                />
                <CresagIdentificationCard
                  key="flightOperations"
                  title={s.modals.cresagIdentification.flightOperations.title}
                  values={s.modals.cresagIdentification.flightOperations.values}
                  handleChangeCheckbox={handleChangeCheckbox}
                />
                <CresagIdentificationCard
                  key="interaction"
                  title={s.modals.cresagIdentification.interaction.title}
                  values={s.modals.cresagIdentification.interaction.values}
                  handleChangeCheckbox={handleChangeCheckbox}
                />
                <CresagIdentificationCard
                  key="urgency"
                  title={s.modals.cresagIdentification.urgency.title}
                  values={s.modals.cresagIdentification.urgency.values}
                  handleChangeCheckbox={handleChangeCheckbox}
                />
              </Grid>
              <Grid item xs={6} style={styles.gridItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      style={styles.checkbox}
                      checked={voluntaryChecked}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setVoluntaryChecked(checked);
                        if (checked) setNoOneChecked(false);
                      }}
                    />
                  }
                  label={s.modals.cresagIdentification.voluntaryCresag}
                  sx={sx.formControlLabel}
                  style={{ paddingLeft: smallMargin }}
                />
                <CresagIdentificationCard
                  key="technicalEvents"
                  title={s.modals.cresagIdentification.technicalEvents.title}
                  values={s.modals.cresagIdentification.technicalEvents.values}
                  handleChangeCheckbox={handleChangeCheckbox}
                />
                <CresagIdentificationCard
                  key="environment"
                  title={s.modals.cresagIdentification.environment.title}
                  values={s.modals.cresagIdentification.environment.values}
                  handleChangeCheckbox={handleChangeCheckbox}
                />
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: defaultMargin + smallMargin,
              alignSelf: 'flex-end',
            }}
          >
            <CustomButton
              title={s.common.button.cancel}
              onClick={onClose}
              style={{
                marginRight: defaultMargin,
                paddingLeft: defaultMargin + smallMargin,
                paddingRight: defaultMargin + smallMargin,
                backgroundColor: 'transparent',
                color: palette.gray600,
              }}
            />
            <CustomButton
              title={
                checkedItemCounter > 0 || voluntaryChecked
                  ? s.modals.cresagIdentification.createCresag
                  : s.modals.cresagIdentification.validate
              }
              onClick={() => {
                setIsLoading(true);
                onSubmit(checkedItemCounter > 0 || voluntaryChecked).finally(
                  () => setIsLoading(false)
                );
              }}
              loading={isLoading}
              style={{
                marginRight: defaultMargin,
                paddingLeft: defaultMargin + smallMargin,
                paddingRight: defaultMargin + smallMargin,
                backgroundColor: primaryColors.primary,
                color: palette.white,
              }}
              disabled={
                !noOneChecked && !voluntaryChecked && checkedItemCounter === 0
              }
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
};

interface CresagIdentificationCardProps {
  key: string;
  title: string;
  values: string[];
  handleChangeCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CresagIdentificationCard: React.FC<CresagIdentificationCardProps> = ({
  key,
  title,
  values,
  handleChangeCheckbox,
}) => {
  return (
    <>
      <div
        style={{
          padding: defaultMargin,
          backgroundColor: palette.gray50,
          borderRadius: mediumRadius,
          marginTop: defaultMargin + smallMargin,
        }}
      >
        <p
          style={{
            margin: 0,
            marginBottom: tinyMargin,
            ...fonts.semiBold14,
            textAlign: 'center',
          }}
        >
          {title}
        </p>
        <FormGroup>
          {values?.map((value, index) => (
            <FormControlLabel
              key={`${key}_${index?.toString()}`}
              control={
                <Checkbox
                  size="small"
                  style={styles.checkbox}
                  onChange={handleChangeCheckbox}
                />
              }
              label={value}
              sx={sx.formControlLabel}
            />
          ))}
        </FormGroup>
      </div>
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  gridItem: {},
  checkbox: { padding: 0, marginRight: tinyMargin },
};

const sx: { [key: string]: SxProps<Theme> } = {
  formControlLabel: {
    display: 'flex',
    alignItems: 'flex-start',
    '& .MuiTypography-root': {
      ...fonts.regular12,
      color: palette.gray700,
    },
    padding: `${tinyMargin}px`,
  },
};
