import imagePlaceholder from '../../assets/images/image-placeholder.svg';
import closeIcon from '../../assets/icons/close-gray.svg';
import { palette } from '../../styles/colors';
import { largeRadius, tinyMargin } from '../../styles/styles';

interface ImageSlotItem {
  uri?: string;
  onDelete: () => void;
  handleFileChange: React.ChangeEventHandler<HTMLInputElement>;
}

export const ImageSlot: React.FC<ImageSlotItem> = ({
  uri,
  onDelete,
  handleFileChange,
}) => {
  return (
    <>
      <div style={{ position: 'relative' }}>
        {uri ? (
          <>
            <div
              style={{
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                padding: tinyMargin,
                borderRadius: largeRadius,
                backgroundColor: palette.white,
                border: `1px solid ${palette.gray200}`,
                zIndex: 10,
                top: -14,
                right: -14,
              }}
              onClick={onDelete}
            >
              <img
                src={closeIcon}
                alt="close"
                style={{
                  cursor: 'pointer',
                }}
              />
            </div>
            <a href={uri} target="_blank" rel="noreferrer">
              <img
                src={uri}
                alt=""
                onClick={() => {}}
                style={{
                  cursor: 'pointer',
                  objectFit: 'cover',
                  height: 111,
                  width: 174,
                }}
              />
            </a>
          </>
        ) : (
          <>
            <label htmlFor="image-picker">
              <img
                src={imagePlaceholder}
                alt="placeholder"
                // onClick={onClick}
                style={{ cursor: 'pointer' }}
              />
            </label>
            <input
              id="image-picker"
              type="file"
              accept="image/*"
              multiple
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </>
        )}
      </div>
    </>
  );
};
