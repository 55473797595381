import { s } from '../../strings/strings';
import { palette } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import {
  buttonPadding,
  defaultMargin,
  mediumRadius,
} from '../../styles/styles';
import { CustomButton } from '../common/CustomButton';
import { CustomSearchTypeSelector } from './CustomSearchTypeSelector';
import { getSearchColor } from '../../utils/main';
import { SearchType } from '../../types/common/main';
import { useMainData } from '../../providers/MainDataProvider';

interface CustomSearchBarProps {
  onSearch: () => void;
  placeholder?: string;
  disabled?: boolean;
  isSearching: boolean;
  onClickAdvancedSearch?: () => void;
  state: [any, React.Dispatch<React.SetStateAction<any>>];
  style?: React.CSSProperties;
  searchTypeState: [
    SearchType,
    React.Dispatch<React.SetStateAction<SearchType>>
  ];
}

export const CustomSearchBar: React.FC<CustomSearchBarProps> = ({
  onSearch,
  placeholder = '',
  disabled,
  isSearching,
  onClickAdvancedSearch,
  state,
  style,
  searchTypeState,
}) => {
  const { isTestEnvironment } = useMainData();

  const [value, setValue] = state;
  const [searchType] = searchTypeState;

  const paddingRight = buttonPadding;
  const paddingLeft = buttonPadding;

  const getSearchTitle = () => {
    if (searchType === 'rex') return s.search.searchRex;
    if (searchType === 'cause') return s.search.searchCauses;
    if (searchType === 'action') return s.search.searchActions;
    return '';
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          ...style,
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexBasis: '60%',
          }}
        >
          <input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            disabled={disabled}
            style={{
              ...styles.input,
              borderColor: palette.gray200,
              paddingLeft: paddingLeft,
              paddingRight: paddingRight,
              width: `calc(100% - ${paddingLeft + paddingRight}px)`,
              flexBasis: '75%',
            }}
            placeholder={placeholder}
          />
          <CustomButton
            onClick={onSearch}
            title={getSearchTitle()}
            style={{
              borderRadius: 0,
              flexBasis: '25%',
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              backgroundColor: getSearchColor(searchType, isTestEnvironment)
                ?.main,
            }}
            loading={isSearching}
          />
          <CustomSearchTypeSelector searchTypeState={searchTypeState} />
        </div>
        {onClickAdvancedSearch && (
          <CustomButton
            onClick={onClickAdvancedSearch}
            title={s.search.advancedSearch}
            style={{
              backgroundColor: 'transparent',
              color: palette.gray600,
              marginLeft: defaultMargin,
            }}
          />
        )}
      </div>
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  input: {
    ...fonts.regular16,
    padding: buttonPadding,
    borderRadius: mediumRadius,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: palette.white,
    borderWidth: 1,
    borderStyle: 'solid',
  },
};
