import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { palette } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import { mediumRadius, smallMargin, tinyMargin } from '../../styles/styles';
import chevronDownIcon from '../../assets/icons/chevron-down.svg';
import React, { useEffect, useState } from 'react';
import { BasicInformationDialog } from '../dialog/BasicInformationDialog';

interface CustomDropdownProps {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  multiple?: boolean;
  state: [any, React.Dispatch<React.SetStateAction<any>>];
  onValueChange?: (event: SelectChangeEvent) => void;
  style?: React.CSSProperties;
  values: { value: string | number; label: string }[];
  alwaysCheckedValues?: string[];
  informationpDialogContent?: React.ReactNode;
}

export const CustomDropdown: React.FC<CustomDropdownProps> = ({
  label,
  placeholder = '',
  disabled,
  state,
  style,
  values,
  multiple = false,
  informationpDialogContent,
  alwaysCheckedValues,
}) => {
  const [value, setValue] = state;
  const [open, setOpen] = useState(false);

  const handleChange = (event: SelectChangeEvent<string | string[]>) => {
    if (multiple) {
      const values = event.target.value as string[];
      alwaysCheckedValues?.forEach((value) => {
        if (!values.includes(value)) {
          values.push(value);
        }
      });
      setValue(values);
    } else {
      setValue(event.target.value);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <div style={{ width: '100%', position: 'relative', ...style }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: smallMargin,
            marginBottom: tinyMargin,
          }}
        >
          {label && (
            <p
              style={{
                ...fonts.medium14,
                color: palette.gray700,
                margin: 0,
                marginBottom: 0,
              }}
            >
              {label}
            </p>
          )}
          {informationpDialogContent && (
            <BasicInformationDialog content={informationpDialogContent} />
          )}
        </div>
        <FormControl fullWidth>
          <Select
            displayEmpty={!!placeholder}
            disabled={disabled}
            value={value || ''}
            multiple={multiple}
            onClick={(e) => {
              if (
                multiple &&
                alwaysCheckedValues &&
                alwaysCheckedValues?.length > 0
              ) {
                const clickedValue = (e.target as HTMLDivElement).innerText;
                if (alwaysCheckedValues?.includes(clickedValue)) {
                  e.stopPropagation();
                }
              }
            }}
            onChange={handleChange}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            renderValue={
              multiple
                ? (selected) => {
                    if (!Array.isArray(selected)) return <></>;
                    const values: string[] = selected;
                    if (values.length === 0) {
                      return (
                        <p
                          style={{
                            ...fonts.regular16,
                            color: palette.gray500,
                            margin: 0,
                          }}
                        >
                          {placeholder}
                        </p>
                      );
                    }

                    return values.join(', ');
                  }
                : undefined
            }
            IconComponent={() => (
              <img
                src={chevronDownIcon}
                alt="chevron down"
                style={{ marginRight: smallMargin, cursor: 'pointer' }}
                onClick={handleOpen}
              />
            )}
            sx={{
              ...fonts.regular16,
              borderColor: palette.gray200,
              height: '47px',

              '.MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px !important',
                borderColor: `${palette.gray200} !important`,
                borderRadius: `${mediumRadius}px`,
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            {placeholder && (
              <MenuItem value="">
                <p
                  style={{
                    ...fonts.regular16,
                    color: palette.gray500,
                    margin: 0,
                  }}
                >
                  {placeholder}
                </p>
              </MenuItem>
            )}
            {values.map((element, index) => (
              <MenuItem key={index} value={element.value}>
                {multiple ? (
                  <>
                    <Checkbox
                      checked={value?.indexOf(element.value) > -1}
                      style={{ padding: 0, paddingRight: smallMargin }}
                    />
                    <ListItemText primary={element.value} />
                  </>
                ) : (
                  element.label
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
};
