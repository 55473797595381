import { Grid } from '@mui/material';
import { defaultMargin, tinyMargin } from '../../styles/styles';
import { fonts } from '../../styles/fonts';
import { palette } from '../../styles/colors';
import { Rex } from '../../types/common/api';
import { useAuth } from '../../providers/AuthProvider';
import { useEffect, useState } from 'react';
import { useRexVisibilities } from '../../hooks/useRexVisibilities';

interface RexGridSummaryProps {
  rex?: Rex;
}

export const RexGridSummary: React.FC<RexGridSummaryProps> = ({ rex }) => {
  const { isMemberOfClub, myEntities } = useAuth();
  const { rexVisibilities } = useRexVisibilities();

  const [entityName, setEntityName] = useState<string>('');
  const isMember = isMemberOfClub(rex?.club_id || '');

  useEffect(() => {
    const entity = myEntities?.find(
      (entity) => entity.ree_idx?.toString() === rex?.club_id
    );
    setEntityName(entity?.ree_designation || '');
  }, [myEntities, rex?.club_id]);

  const visibility = rexVisibilities?.find(
    (visibility) => visibility.id === rex?.rex_visibility_id
  );

  return (
    <>
      <Grid
        container
        rowSpacing={1.5}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={{ padding: defaultMargin, paddingLeft: 0 }}
      >
        {entityName && (
          <>
            <Grid item xs={4} style={styles.metaDataGridTitle}>
              Club
            </Grid>
            <Grid item xs={8} style={styles.metaDataGridValue}>
              {entityName}
            </Grid>
          </>
        )}
        {visibility && (
          <>
            <Grid item xs={4} style={styles.metaDataGridTitle}>
              Niveau de visibilité
            </Grid>
            <Grid item xs={8} style={styles.metaDataGridValue}>
              {visibility?.name}
            </Grid>
          </>
        )}
        <Grid item xs={4} style={styles.metaDataGridTitle}>
          Type de REX
        </Grid>
        <Grid item xs={8} style={styles.metaDataGridValue}>
          {rex?.rex_type}
        </Grid>
        <Grid item xs={4} style={styles.metaDataGridTitle}>
          Objet du rex
        </Grid>
        <Grid item xs={8} style={styles.metaDataGridValue}>
          {rex?.rex_subject?.name}
        </Grid>
        <Grid item xs={4} style={styles.metaDataGridTitle}>
          Date de l’évènement
        </Grid>
        <Grid item xs={8} style={styles.metaDataGridValue}>
          {rex?.event_date}
        </Grid>
        <Grid item xs={4} style={styles.metaDataGridTitle}>
          Phase de vol
        </Grid>
        <Grid item xs={8} style={styles.metaDataGridValue}>
          {rex?.flight_phase?.name}
        </Grid>
        {isMember && (
          <>
            <Grid item xs={4} style={styles.metaDataGridTitle}>
              Lieu Géographique
            </Grid>
            <Grid item xs={8} style={styles.metaDataGridValue}>
              <p
                style={{
                  ...styles.metaDataGridValue,
                  margin: 0,
                  marginBottom: tinyMargin,
                }}
              >
                {rex?.place}
              </p>
              <p style={{ ...styles.metaDataGridValue, margin: 0 }}>
                {rex?.place_description}
              </p>
            </Grid>
          </>
        )}
        <Grid item xs={4} style={styles.metaDataGridTitle}>
          Nature du vol
        </Grid>
        <Grid item xs={8} style={styles.metaDataGridValue}>
          {rex?.flight_kind}
        </Grid>
        <Grid item xs={4} style={styles.metaDataGridTitle}>
          Type de vol
        </Grid>
        <Grid item xs={8} style={styles.metaDataGridValue}>
          {rex?.flight_type}
        </Grid>
        <Grid item xs={4} style={styles.metaDataGridTitle}>
          Type d’aéronef
        </Grid>
        <Grid item xs={8} style={styles.metaDataGridValue}>
          {rex?.aircraft_type}
        </Grid>
        {isMember && (
          <>
            <Grid item xs={4} style={styles.metaDataGridTitle}>
              Immatriculation aéronef
            </Grid>
            <Grid item xs={8} style={styles.metaDataGridValue}>
              {rex?.aircraft_registration}
            </Grid>
          </>
        )}
        <Grid item xs={4} style={styles.metaDataGridTitle}>
          Type d’évènement
        </Grid>
        <Grid item xs={8} style={styles.metaDataGridValue}>
          {rex?.event_type}
        </Grid>
        <Grid item xs={4} style={styles.metaDataGridTitle}>
          Classe de l’évènement
        </Grid>
        <Grid item xs={8} style={styles.metaDataGridValue}>
          {rex?.event_kind}
        </Grid>
      </Grid>
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  metaDataGridTitle: {
    ...fonts.regular14,
    color: palette.gray700,
  },
  metaDataGridValue: {
    ...fonts.semiBold14,
  },
};
