import { s } from '../../strings/strings';
import { palette } from '../../styles/colors';
import whiteChevronDownIcon from '../../assets/icons/white-chevron-down.svg';
import { Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { getSearchColor } from '../../utils/main';
import { SearchType } from '../../types/common/main';
import { CustomButton } from '../common/CustomButton';
import { useMainData } from '../../providers/MainDataProvider';

interface CustomSearchTypeSelectorProps {
  searchTypeState: [
    SearchType,
    React.Dispatch<React.SetStateAction<SearchType>>
  ];
}

export const CustomSearchTypeSelector: React.FC<
  CustomSearchTypeSelectorProps
> = ({ searchTypeState }) => {
  const { isTestEnvironment } = useMainData();
  const setSearchType = searchTypeState[1];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomButton
        title={''}
        onClick={handleClick}
        iconSrc={whiteChevronDownIcon}
        style={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderLeftWidth: 2,
          borderLeftColor: palette.gray400,
          borderLeftStyle: 'solid',
          backgroundColor: getSearchColor(searchTypeState[0], isTestEnvironment)
            ?.main,
        }}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            setSearchType('rex');
            handleClose();
          }}
        >
          {s.search.types.rex}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSearchType('cause');
            handleClose();
          }}
        >
          {s.search.types.cause}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSearchType('action');
            handleClose();
          }}
        >
          {s.search.types.action}
        </MenuItem>
      </Menu>
    </>
  );
};
