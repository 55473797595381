import * as React from 'react';
import { s } from '../../strings/strings';
import { ConfirmDialog } from './ConfirmDialog';

interface Props {
  children: React.ReactNode;
  onDelete: () => void;
  title?: string;
  description?: string;
}

export const DeleteDialog = ({
  title,
  description,
  children,
  onDelete,
}: Props) => {
  return (
    <ConfirmDialog
      title={title || s.common.deleteDialog.title}
      description={description || s.common.deleteDialog.description}
      onConfirm={onDelete}
    >
      {children}
    </ConfirmDialog>
  );
};
