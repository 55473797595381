import { BasicPage } from "../../components/templates/BasicPage";
import { s } from "../../strings/strings";
import { palette } from "../../styles/colors";
import { fonts } from "../../styles/fonts";
import {
  defaultMargin,
  largeMargin,
  largerMargin,
  smallMargin,
} from "../../styles/styles";
import { EditOrCreateRex } from "../../components/rex/EditOrCreateRex";

export const NewEmptyRexPage = () => {
  return (
    <>
      <BasicPage
        style={{
          paddingRight: 0,
          paddingLeft: 0,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            backgroundColor: palette.gray50,
            paddingRight: largerMargin,
            paddingLeft: largerMargin,
            paddingTop: defaultMargin,
            paddingBottom: defaultMargin,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p style={{ ...fonts.bold24, margin: 0 }}>
              {s.rex.newRex.createEmptyRex.title}
            </p>
            <p style={{ ...fonts.medium16, margin: 0, marginTop: smallMargin }}>
              {s.rex.newRex.createEmptyRex.subtitle}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: largeMargin,
            marginLeft: largerMargin * 3,
            marginRight: largerMargin * 3,
          }}
        >
          <EditOrCreateRex />
        </div>
      </BasicPage>
    </>
  );
};
