import { SearchType } from "../../types/common/main";
import { ActionSearchResultGrid } from "./action/ActionSearchResultGrid";
import { CauseSearchResultGrid } from "./cause/CauseSearchResultGrid";
import { SearchFilters } from "../../types/common/api";
import { RexAdvancedSearchFilters } from "./rex/RexAdvancedSearchFilters";
import { CauseAdvancedSearchFilters } from "./cause/CauseAdvancedSearchFilters";
import { ActionAdvancedSearchFilters } from "./action/ActionAdvancedSearchFilters";

interface AdvancedSearchFiltersProps {
  searchParamsState: [
    SearchFilters,
    React.Dispatch<React.SetStateAction<SearchFilters>>
  ];
  searchType: SearchType;
}

export const AdvancedSearchFilters: React.FC<AdvancedSearchFiltersProps> = ({
  searchParamsState,
  searchType,
}) => {
  return (
    <>
      {searchType === "rex" && (
        <RexAdvancedSearchFilters
          searchParamsState={searchParamsState}
          searchType={searchType}
        />
      )}
      {searchType === "cause" && (
        <CauseAdvancedSearchFilters
          searchParamsState={searchParamsState}
          searchType={searchType}
        />
      )}
      {searchType === "action" && (
        <ActionAdvancedSearchFilters
          searchParamsState={searchParamsState}
          searchType={searchType}
        />
      )}
    </>
  );
};
