import { FFA_INDEX } from '../constants';

export const getEntitiesStatsParam = (
  entityIdx?: string,
  entitiesIdx?: string[]
) => {
  let entities: string[] = [];
  if (entityIdx === FFA_INDEX) return entities;
  if (entityIdx) entities.push(entityIdx);
  if (entitiesIdx?.length) entities = entities.concat(entitiesIdx);
  return entities;
};
