import { useQuery } from 'react-query';
import { useAuth } from '../providers/AuthProvider';
import { api } from '../utils/api';
import { Rex } from '../types/common/api';
import { AxiosResponse } from 'axios';
import { useMainData } from '../providers/MainDataProvider';

export const useModifiedRexsMutations = () => {
  const { isTestEnvironment } = useMainData();

  const { user } = useAuth();

  const modifiedRexsKey = [
    'modifiedRexsKey',
    user?.id,
    ...(isTestEnvironment ? ['test'] : []),
  ];

  const { data: modifiedRexs, refetch: refetchModifiedRexs } = useQuery(
    modifiedRexsKey,
    async () =>
      await api
        .get('rex/modified')
        .then(({ data }: AxiosResponse<Rex[], any>) => data)
  );

  return {
    modifiedRexs,
    refetchModifiedRexs,
  };
};
