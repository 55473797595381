import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { fonts } from "../../styles/fonts";
import {
  defaultMargin,
  largeMargin,
  mediumRadius,
  smallMargin,
  tinyMargin,
} from "../../styles/styles";
import { s } from "../../strings/strings";
import { palette } from "../../styles/colors";
import { CauseType } from "../../types/common/main";

interface CauseTypeSelectorProps {
  state: [string, React.Dispatch<React.SetStateAction<string>>];
}

export const CauseTypeSelector: React.FC<CauseTypeSelectorProps> = ({
  state,
}) => {
  const [value, setValue] = state;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <>
      <div
        style={{
          marginTop: largeMargin,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <p
          style={{
            ...fonts.bold18,
            margin: 0,
            marginBottom: smallMargin + tinyMargin,
          }}
        >
          {s.modals.addCause.chooseCauseType}
        </p>
        <FormControl>
          <RadioGroup
            row
            name="row-radio-cause-type"
            value={value}
            onChange={handleChange}
          >
            <div style={{ ...styles.causeItem, marginRight: defaultMargin }}>
              <FormControlLabel
                value={CauseType.MainCause}
                control={<Radio />}
                label={s.modals.addCause.mainCause}
              />
            </div>
            <div style={styles.causeItem}>
              <FormControlLabel
                value={CauseType.ContributingFactor}
                control={<Radio />}
                label={s.modals.addCause.contributingFactor}
              />
            </div>
          </RadioGroup>
        </FormControl>
      </div>
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  causeItem: {
    border: `1px solid ${palette.gray200}`,
    paddingRight: smallMargin,
    paddingLeft: smallMargin,
    borderRadius: mediumRadius,
  },
};
