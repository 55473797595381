import { s } from '../../strings/strings';
import { colors, palette } from '../../styles/colors';
import dotsVerticalWhite from '../../assets/icons/dots-vertical-white.svg';
import { Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { CustomButton } from '../common/CustomButton';
import { fonts } from '../../styles/fonts';
import { useRexsToPublishMutations } from '../../hooks/useRexsToPublishMutations';
import { useNavigate } from 'react-router-dom';
import { DeleteDialog } from '../common/DeleteDialog';
import { ConfirmDialog } from '../common/ConfirmDialog';

export const RexActionSelector = ({ rexId }: { rexId: string }) => {
  const navigate = useNavigate();
  const { removeRexToPublish, archiveRex } = useRexsToPublishMutations();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingArchive, setIsLoadingArchive] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomButton
        title={''}
        onClick={handleClick}
        style={{
          ...fonts.medium14,
          border: `1px solid ${palette.darkBlue600}`,
          backgroundColor: palette.darkBlue600,
          color: palette.darkBlue600,
          flexGrow: 0,
          width: '50px',
          aspectRatio: 1,
        }}
        iconSrc={dotsVerticalWhite}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <ConfirmDialog
          title={s.searchResult.archiveRexConfirmTitle}
          description={s.searchResult.archiveRexConfirmDescription}
          onConfirm={() => {
            setIsLoadingArchive(true);
            archiveRex
              .mutateAsync(Number(rexId))
              .then(() => {
                navigate(-1);
              })
              .finally(() => {
                setIsLoadingArchive(false);
                handleClose();
              });
          }}
        >
          <MenuItem>{s.searchResult.archiveRex}</MenuItem>
        </ConfirmDialog>
        <DeleteDialog
          onDelete={() => {
            setIsLoadingDelete(true);
            removeRexToPublish
              .mutateAsync(Number(rexId))
              .then(() => {
                navigate(-1);
              })
              .finally(() => {
                setIsLoadingDelete(false);
                handleClose();
              });
          }}
          title={s.searchResult.deleteRexConfirmTitle}
          description={s.searchResult.deleteRexConfirmDescription}
        >
          <MenuItem style={{ color: colors.error }}>
            {s.searchResult.removeRex}
          </MenuItem>
        </DeleteDialog>
      </Menu>
    </>
  );
};
