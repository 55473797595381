import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api } from '../utils/api';
import { AxiosResponse } from 'axios';
import { Rex } from '../types/common/api';
import { useMainData } from '../providers/MainDataProvider';

export const useFavoriteRexMutations = () => {
  const { isTestEnvironment } = useMainData();

  const favoriteRexsKey = [
    'favoriteRexs',
    ...(isTestEnvironment ? ['test'] : []),
  ];

  const queryClient = useQueryClient();

  const { data: favoriteRexs, refetch: refetchFavoriteRexs } = useQuery(
    favoriteRexsKey,
    async () =>
      await api
        .get('user/favorite/rex')
        .then(({ data }: AxiosResponse<Rex[], any>) => data)
  );

  // Mutations
  const addFavoriteRex = useMutation(
    (id: number) => api.post(`user/favorite/rex/${id}`),
    {
      onSuccess: ({ data }: AxiosResponse<Rex, any>) => {
        queryClient.setQueryData(
          favoriteRexsKey,
          (oldData: Rex[] | undefined) =>
            oldData ? [...oldData, data] : [data]
        );
      },
    }
  );

  const deleteFavoriteRexs = useMutation(
    (ids: number[]) =>
      api.put(`user/favorite/rex/delete-many`, {
        rex_ids: ids,
      }),
    {
      onSuccess: ({ data }: AxiosResponse<Rex[], any>, ids) => {
        queryClient.setQueryData(
          favoriteRexsKey,
          (oldData: Rex[] | undefined) =>
            oldData?.filter((rex) => !ids.includes(rex?.id)) || []
        );
      },
    }
  );

  const deleteFavoriteRex = useMutation(
    (id: number) => api.delete(`user/favorite/rex/${id}`),
    {
      onSuccess: ({ data }: AxiosResponse<Rex, any>, id) => {
        queryClient.setQueryData(
          favoriteRexsKey,
          (oldData: Rex[] | undefined) =>
            oldData ? oldData?.filter((element) => element.id !== id) : []
        );
      },
    }
  );

  return {
    favoriteRexs,
    refetchFavoriteRexs,
    addFavoriteRex,
    deleteFavoriteRexs,
    deleteFavoriteRex,
  };
};
