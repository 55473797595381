import { Link, useNavigate, useParams } from 'react-router-dom';
import { BasicPage } from '../../components/templates/BasicPage';
import { palette } from '../../styles/colors';
import {
  defaultMargin,
  largeMargin,
  largerMargin,
  mediumRadius,
  smallMargin,
  tinyMargin,
} from '../../styles/styles';
import { fonts } from '../../styles/fonts';
import { s } from '../../strings/strings';
import arrowLeftIcon from '../../assets/icons/arrow-narrow-left.svg';
import { useEffect, useState } from 'react';
import { Action, FlightPhase, Rex } from '../../types/common/api';
import { api } from '../../utils/api';
import { AxiosResponse } from 'axios';
import bookmarkIcon from '../../assets/icons/blue-bookmark.svg';
import bookmarkFilledIcon from '../../assets/icons/blue-bookmark-filled.svg';
import purpleDownloadIcon from '../../assets/icons/purple-download.svg';
import { CustomButton } from '../../components/common/CustomButton';
import { useFavoriteActionMutations } from '../../hooks/useFavoriteActionMutations';
import { GridColDef, useGridApiRef } from '@mui/x-data-grid';
import { ReportTypeChip } from '../../components/common/chip/ReportTypeChip';
import { FlightPhaseChip } from '../../components/common/chip/FlightPhaseChip';
import { getRiskColor, getSearchColor } from '../../utils/main';
import { CustomGrid } from '../../components/common/CustomGrid';
import { ExportResultsModal } from '../../components/search/ExportResultsModal';
import { getDisplayableStringFromIsoString } from '../../utils/date';
import { useMainData } from '../../providers/MainDataProvider';
import { useAuth } from '../../providers/AuthProvider';
import { AddActionModal } from '../../components/rex/AddActionModal';

export const ActionResultPage = () => {
  const { isMemberOfClub, hasCPSPermission, hasCSPermission } = useAuth();
  const { primaryColors } = useMainData();
  const navigate = useNavigate();
  const { actionId } = useParams();
  const apiRef = useGridApiRef();
  const { addFavoriteAction, deleteFavoriteAction, favoriteActions } =
    useFavoriteActionMutations();

  const [action, setAction] = useState<Action>();
  const [associatedRexs, setAssociatedRexs] = useState<Rex[]>();
  const [isLoadingBookmark, setIsLoadingBookmark] = useState<boolean>(false);
  const [isBookmarked, setIsBookmarked] = useState<boolean>(false);
  const [isExportResultsModalOpen, setIsExportResultsModalOpen] =
    useState(false);

  const [isAddActionModalOpen, setIsAddActionModalOpen] = useState(false);
  const [needToRefresh, setNeedToRefresh] = useState(false);

  useEffect(() => {
    api
      .get(`action/${actionId}`)
      .then(({ data }: AxiosResponse<Action, any>) => {
        setAction(data);
        setNeedToRefresh(false);
      });
    api
      .get(`action/${actionId}/rexs`)
      .then(({ data }: AxiosResponse<Rex[], any>) => setAssociatedRexs(data));
  }, [actionId, needToRefresh]);

  useEffect(() => {
    setIsBookmarked(
      favoriteActions?.find((e) => e.id.toString() === actionId) ? true : false
    );
  }, [favoriteActions, actionId]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: s.common.grid.id,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.id;
      },
    },
    {
      field: 'title',
      headerName: s.common.grid.title,
      flex: 2,
    },
    {
      field: 'rex_type',
      headerName: s.common.grid.rexType,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.rex_type ? (
          <ReportTypeChip reportType={row.rex_type} />
        ) : (
          <p>N/A</p>
        );
      },
    },
    {
      field: 'event_date',
      headerName: s.common.grid.date,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        const eventDateString = row?.event_date;
        const date = eventDateString?.split('-')?.reverse()?.join('/');
        return date;
      },
    },
    {
      field: 'flight_phase',
      headerName: s.rex.reportToProcess.grid.flightPhase,
      renderCell: (params) => {
        const row = params.row;
        const flightPhase: FlightPhase = row?.flight_phase;
        return flightPhase ? (
          <FlightPhaseChip flightPhase={flightPhase} />
        ) : (
          <p>N/A</p>
        );
      },
      flex: 1,
    },
    {
      field: 'flight_kind',
      headerName: s.common.grid.flightKind,
      flex: 1,
    },
    {
      field: 'flight_type',
      headerName: s.common.grid.flightType,
      flex: 1,
    },
    {
      field: 'aircraft_registration',
      headerName: s.common.grid.registration,
      renderCell: (params) => {
        const row = params.row;
        const clubId = row?.club_id || '';

        return isMemberOfClub(clubId) ? row?.aircraft_registration : '';
      },
      flex: 1,
    },
    {
      field: 'event_kind',
      headerName: s.common.grid.classification,
      flex: 1,
    },
    {
      field: 'initial_risk_probability',
      headerName: s.common.grid.risk,
      renderCell: (params) => {
        const row = params.row;
        const severity: number = row?.initial_risk_severity;
        const probability: number = row?.initial_risk_probability;
        return (
          <>
            <div
              style={{
                display: 'flex',
                backgroundColor: getRiskColor(severity, probability),
                borderRadius: mediumRadius,
                height: 18,
                width: 18,
              }}
            ></div>
          </>
        );
      },
      flex: 0.5,
    },
  ];

  return (
    <>
      <BasicPage
        style={{
          paddingRight: 0,
          paddingLeft: 0,
          paddingTop: 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            backgroundColor: palette.gray50,
            paddingRight: largerMargin,
            paddingLeft: largerMargin,
            paddingTop: defaultMargin,
            paddingBottom: defaultMargin,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Link
              to={'..'}
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              <img
                src={arrowLeftIcon}
                alt=""
                style={{ marginRight: tinyMargin }}
              />
              <p
                style={{
                  ...fonts.medium14,
                  color: palette.gray600,
                  margin: 0,
                }}
              >
                {s.searchResult.backToResults}
              </p>
            </Link>
            <p style={{ ...fonts.bold24, margin: 0, marginTop: defaultMargin }}>
              {action?.id ? s.searchResult.actionTitle(action.id) : ''}
            </p>
            <p style={{ ...fonts.medium16, margin: 0, marginTop: tinyMargin }}>
              {action?.description}
            </p>
          </div>
          <div style={{ display: 'flex', gap: defaultMargin }}>
            {(hasCPSPermission || hasCSPermission) && (
              <CustomButton
                title={s.common.button.editAction}
                onClick={() => {
                  setIsAddActionModalOpen(true);
                }}
                style={{
                  marginRight: 'auto',
                  color: palette.white,
                }}
                loading={isLoadingBookmark}
                loaderColor={primaryColors.primary}
              />
            )}
            <CustomButton
              title={''}
              onClick={() => {
                setIsLoadingBookmark(true);
                (isBookmarked ? deleteFavoriteAction : addFavoriteAction)
                  .mutateAsync(Number(actionId))
                  .finally(() => {
                    setIsLoadingBookmark(false);
                  });
              }}
              style={{
                ...fonts.medium14,
                border: `1px solid ${palette.darkBlue600}`,
                backgroundColor: palette.darkBlue50,
                color: palette.darkBlue600,
                flexGrow: 0,
              }}
              loading={isLoadingBookmark}
              loaderColor={primaryColors.primary}
              iconSrc={isBookmarked ? bookmarkFilledIcon : bookmarkIcon}
            />
          </div>
        </div>
        <div
          style={{
            paddingTop: largeMargin,
            paddingRight: largerMargin,
            paddingBottom: defaultMargin,
            paddingLeft: largerMargin,
          }}
        >
          <div
            style={{
              marginTop: 0,
              marginBottom: largeMargin,
            }}
          >
            <p
              style={{
                ...fonts.bold18,
                margin: 0,
              }}
            >
              {s.searchResult.generalInfo}
            </p>
            <div
              style={{
                display: 'flex',
                flexGrow: 1,
                gap: `${smallMargin}px`,
                marginTop: defaultMargin,
                paddingLeft: smallMargin,
                paddingRight: smallMargin,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  gap: `${smallMargin + tinyMargin}px`,
                }}
              >
                <InfoItem
                  title={s.searchResult.creationDate}
                  value={getDisplayableStringFromIsoString(
                    action?.created_at || ''
                  )}
                />
                <InfoItem
                  title={s.searchResult.status}
                  value={action?.status || ''}
                />
                <InfoItem
                  title={s.searchResult.statusDate}
                  value={getDisplayableStringFromIsoString(
                    action?.status_date || ''
                  )}
                />
                <InfoItem
                  title={s.searchResult.carrierName}
                  value={action?.carrier_name || ''}
                />
                {action?.approval_date && (
                  <InfoItem
                    title={s.searchResult.approvalDate}
                    value={getDisplayableStringFromIsoString(
                      action?.approval_date
                    )}
                  />
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  gap: `${smallMargin + tinyMargin}px`,
                }}
              >
                {action?.start_date && (
                  <InfoItem
                    title={s.searchResult.startDate}
                    value={getDisplayableStringFromIsoString(
                      action?.start_date
                    )}
                  />
                )}
                {action?.end_date && (
                  <InfoItem
                    title={s.searchResult.endDate}
                    value={getDisplayableStringFromIsoString(action.end_date)}
                  />
                )}
                {action?.commissioning_date && (
                  <InfoItem
                    title={s.searchResult.commissioningDate}
                    value={getDisplayableStringFromIsoString(
                      action.commissioning_date
                    )}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 0,
              marginBottom: defaultMargin,
            }}
          >
            <p
              style={{
                ...fonts.bold18,
                margin: 0,
              }}
            >
              {s.searchResult.associatedRexs}
            </p>
            <CustomButton
              title={s.search.exportResults}
              onClick={() => setIsExportResultsModalOpen(true)}
              style={{
                marginLeft: defaultMargin,
                border: `1px solid ${getSearchColor('action')?.border}`,
                backgroundColor: getSearchColor('action')?.background,
                color: getSearchColor('action')?.border,
              }}
              iconSrc={purpleDownloadIcon}
              iconPosition="right"
            />
          </div>
          <CustomGrid
            apiRef={apiRef}
            rows={associatedRexs || []}
            columns={columns}
            onRowClick={(row) => navigate('/rex/' + row.id)}
          />
        </div>
      </BasicPage>
      <ExportResultsModal
        open={isExportResultsModalOpen}
        onClose={() => setIsExportResultsModalOpen(false)}
        apiRef={apiRef}
      />
      <AddActionModal
        open={isAddActionModalOpen}
        onClose={() => setIsAddActionModalOpen(false)}
        actionToEdit={action}
        onBack={() => {
          setIsAddActionModalOpen(false);
          setNeedToRefresh(true);
        }}
      />
    </>
  );
};

interface InfoItemProps {
  title: string;
  value: string;
}

const InfoItem: React.FC<InfoItemProps> = ({ title, value }) => {
  return (
    <>
      <div style={{ display: 'flex', flexGrow: 1, flexShrink: 1 }}>
        <p
          style={{
            ...fonts.regular14,
            color: palette.gray700,
            margin: 0,
            flexBasis: '25%',
          }}
        >
          {title}
        </p>
        <p style={{ ...fonts.regular14, margin: 0, flexBasis: '75%' }}>
          {value}
        </p>
      </div>
    </>
  );
};
