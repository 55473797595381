import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ErrorPage } from './pages/error/ErrorPage';
import { LoginPage } from './pages/unauthenticated/LoginPage';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { palette } from './styles/colors';
import { QueryClient, QueryClientProvider } from 'react-query';

// CSS
import './App.css';

// Fonts
import './fonts/Archivo/Archivo-Medium.ttf';
import './fonts/Archivo/Archivo-Regular.ttf';
import './fonts/Archivo/Archivo-SemiBold.ttf';
import './fonts/Archivo/Archivo-Bold.ttf';
import './fonts/Archivo/Archivo-Light.ttf';
import './fonts/Archivo/Archivo-Italic.ttf';
import { SettingsPages } from './pages/settings/SettingsPage';
import { MyFavoritesHomePage } from './pages/favorites/MyFavoritesHomePage';
import { SearchPage } from './pages/search/SearchPage';
import { RexHomePage } from './pages/rex/RexHomePage';
import { DashboardHomePage } from './pages/dashboard/DashboardHomePage';
import { AuthProvider } from './providers/AuthProvider';
import { MainDataProvider } from './providers/MainDataProvider';
import { ReportToProcessPage } from './pages/rex/ReportToProcessPage';
import { NewEmptyRexPage } from './pages/rex/NewEmptyRexPage';
import { RexToCompletePage } from './pages/rex/RexToCompletePage';
import { RexToPublish } from './pages/rex/RexToPublish';
import { NewRexFromReportPage } from './pages/rex/NewRexFromReportPage';
import { CompleteRexPage } from './pages/rex/CompleteRexPage';
import { RexResultPage } from './pages/search/RexResultPage';
import { CauseResultPage } from './pages/search/CauseResultPage';
import { ActionResultPage } from './pages/search/ActionResultPage';
import { EditOrCreateCresagPage } from './pages/rex/cresag/EditOrCreateCresagPage';
import { PrivacyPolicyPage } from './pages/privacyPolicy/PrivacyPolicyPage';
import { GCUPage } from './pages/gcu/GCUPage';
import { useEffect, useState } from 'react';

const queryClient = new QueryClient();

const App = () => {
  const [isTestEnvironment, setIsTestEnvironment] = useState<
    boolean | undefined
  >(undefined);

  useEffect(() => {
    const isTestEnvironment =
      localStorage.getItem('isTestEnvironment') ?? false;
    setIsTestEnvironment(isTestEnvironment === 'true');
  }, []);

  useEffect(() => {
    if (isTestEnvironment !== undefined) {
      localStorage.setItem('isTestEnvironment', isTestEnvironment.toString());
    }
  }, [isTestEnvironment]);

  const theme = createTheme({
    palette: {
      primary: {
        light: palette.darkBlue500,
        main: palette.darkBlue900,
        dark: palette.darkBlue900,
        contrastText: palette.white,
      },
    },
  });
  const testTheme = createTheme({
    palette: {
      primary: {
        light: palette.success500,
        main: palette.success700,
        dark: palette.success700,
        contrastText: palette.white,
      },
    },
  });

  return (
    <ThemeProvider theme={isTestEnvironment ? testTheme : theme}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <MainDataProvider
            isTestEnvironment={isTestEnvironment}
            setIsTestEnvironment={setIsTestEnvironment}
          >
            <AuthProvider>
              <Routes>
                <Route path="/" element={<div />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/dashboard" element={<DashboardHomePage />} />
                <Route path="/rex" element={<RexHomePage />} />
                <Route path="/rex/:rexId" element={<RexResultPage />} />
                <Route path="/rex/process" element={<ReportToProcessPage />} />
                <Route
                  path="/rex/process/:reportId"
                  element={<NewRexFromReportPage />}
                />
                <Route path="/rex/complete" element={<RexToCompletePage />} />
                <Route
                  path="/rex/complete/:rexId"
                  element={<CompleteRexPage />}
                />
                <Route path="/cause/:causeId" element={<CauseResultPage />} />
                <Route
                  path="/action/:actionId"
                  element={<ActionResultPage />}
                />
                <Route
                  path="/cresag/:cresagId"
                  element={<EditOrCreateCresagPage />}
                />
                <Route path="/rex/publish" element={<RexToPublish />} />
                <Route path="/rex/new" element={<NewEmptyRexPage />} />
                <Route path="/search" element={<SearchPage />} />
                <Route path="/search/:searchType" element={<SearchPage />} />
                <Route path="/search/rex/:rexId" element={<RexResultPage />} />
                <Route
                  path="/search/cause/:causeId"
                  element={<CauseResultPage />}
                />
                <Route
                  path="/search/action/:actionId"
                  element={<ActionResultPage />}
                />
                <Route path="/favorites" element={<MyFavoritesHomePage />} />
                <Route path="/settings" element={<SettingsPages />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/gcu" element={<GCUPage />} />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </AuthProvider>
          </MainDataProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
