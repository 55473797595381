const palette = {
  black: 'rgba(0, 0, 0, 1)',
  blue700: 'rgba(23, 92, 211, 1)',
  blue50: 'rgba(239, 248, 255, 1)',
  darkBlue900: 'rgba(9, 36, 169, 1)',
  darkBlue800: 'rgba(0, 64, 193, 1)',
  darkBlue700: 'rgba(0, 78, 235, 1)',
  darkBlue600: 'rgba(21, 94, 239, 1)',
  darkBlue500: 'rgba(41, 112, 255, 1)',
  darkBlue400: 'rgba(82, 139, 255, 1)',
  darkBlue300: 'rgba(132, 173, 255, 1)',
  darkBlue200: 'rgba(178, 204, 255, 1)',
  darkBlue100: 'rgba(209, 224, 255, 1)',
  darkBlue50: 'rgba(239, 244, 255, 1)',
  darkBlue25: 'rgba(245, 248, 255, 1)',
  error700: 'rgba(180, 35, 24, 1)',
  error600: 'rgba(217, 45, 32, 1)',
  error500: 'rgba(240, 68, 56, 1)',
  error400: 'rgba(249, 112, 102, 1)',
  error300: 'rgba(253, 162, 155, 1)',
  error50: 'rgba(254, 243, 242, 1)',
  gray900: 'rgba(16, 24, 40, 1)',
  gray800: 'rgba(29, 41, 57, 1)',
  gray700: 'rgba(52, 64, 84, 1)',
  gray600: 'rgba(71, 84, 103, 1)',
  gray500: 'rgba(102, 112, 133, 1)',
  gray400: 'rgba(152, 162, 179, 1)',
  gray300: 'rgba(208, 213, 221, 1)',
  gray200: 'rgba(234, 236, 240, 1)',
  gray100: 'rgba(242, 244, 247, 1)',
  gray50: 'rgba(249, 250, 251, 1)',
  grayBlue700: 'rgba(54, 63, 114, 1)',
  grayBlue50: 'rgba(248, 249, 252, 1)',
  orange300: 'rgba(247, 178, 122, 1)',
  pink200: 'rgba(252, 206, 238, 1)',
  purple800: 'rgba(74, 31, 184, 1)',
  purple700: 'rgba(89, 37, 220, 1)',
  purple50: 'rgba(244, 243, 255, 1)',
  success700: 'rgba(2, 122, 72, 1)',
  success500: 'rgba(18, 183, 106, 1)',
  success200: 'rgba(166, 244, 197, 1)',
  success50: 'rgba(236, 253, 243, 1)',
  teal800: 'rgba(18, 93, 86, 1)',
  violet600: 'rgba(120, 57, 238, 1)',
  violet400: 'rgba(164, 138, 251, 1)',
  violet50: 'rgba(245, 243, 255, 1)',
  warning700: 'rgba(181, 71, 8, 1)',
  warning600: 'rgba(220, 104, 3, 1)',
  warning500: 'rgba(247, 144, 9, 1)',
  warning300: 'rgba(254, 200, 75, 1)',
  warning50: 'rgba(255, 250, 235, 1)',
  white: 'rgba(255, 255, 255, 1)',
  // 2, 122, 72 to hex #027a48
};

const PRIMARY_COLOR = palette.darkBlue900;
const PRIMARY_TEST_COLOR = palette.success700;

const colors = {
  primary: PRIMARY_COLOR,
  primaryTest: PRIMARY_TEST_COLOR,

  textBlack: palette.black,
  textDarker: palette.gray800,
  textDark: palette.gray700,
  textMedium: palette.gray600,
  textLight: palette.gray500,
  textLighter: palette.gray400,
  textWhite: palette.white,

  links: palette.darkBlue600,

  button: PRIMARY_COLOR,
  buttonDisabled: palette.gray200,
  buttonText: palette.white,
  buttonTextDisabled: palette.gray400,

  background: palette.gray100,

  error: palette.error600,
};

export { colors, palette };
