import React, { useState } from 'react';
import { s } from '../../strings/strings';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { CustomGrid } from '../common/CustomGrid';
import { useFavoriteActionMutations } from '../../hooks/useFavoriteActionMutations';
import bookmarkSlashIcon from '../../assets/icons/bookmark-slash.svg';
import { CustomButton } from '../common/CustomButton';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { AssociatedRexList } from '../common/AssociatedRexList';
import { useNavigate } from 'react-router-dom';
import { useMainData } from '../../providers/MainDataProvider';
import { CustomTooltip } from '../common/CustomTooltip';

interface FavoritesActionsSectionProps {
  style?: React.CSSProperties;
  apiRef: React.MutableRefObject<GridApiCommunity>;
  rowSelectionState: [
    GridRowSelectionModel,
    React.Dispatch<React.SetStateAction<GridRowSelectionModel>>
  ];
}

export const FavoritesActionsSection: React.FC<
  FavoritesActionsSectionProps
> = ({ style, apiRef, rowSelectionState }) => {
  const { primaryColors } = useMainData();
  const navigate = useNavigate();
  const { favoriteActions, deleteFavoriteAction } =
    useFavoriteActionMutations();
  const [isLoadingBookmarkId, setIsLoadingBookmarkId] = useState<number>();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: s.common.grid.id,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.id;
      },
    },
    {
      field: 'description',
      headerName: s.common.grid.description,
      flex: 4,
    },
    {
      field: 'created_at',
      headerName: s.common.grid.date,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.created_at
          ? new Date(row?.created_at)
              ?.toISOString()
              ?.split('T')
              ?.shift()
              ?.split('-')
              ?.reverse()
              ?.join('/')
          : 'N/A';
      },
    },
    {
      field: 'status',
      headerName: s.modals.addAction.grid.status,
      flex: 1,
    },
    {
      field: 'status_date',
      headerName: s.common.grid.statusDate,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.status_date
          ? new Date(row?.status_date)
              ?.toISOString()
              ?.split('T')
              ?.shift()
              ?.split('-')
              ?.reverse()
              ?.join('/')
          : 'N/A';
      },
    },
    {
      field: 'carrier_name',
      headerName: s.common.grid.carrier,
      flex: 1,
    },
    {
      field: 'associated_rexs',
      headerName: s.common.grid.associatedRexs,
      flex: 3,
      renderCell: (params) => {
        const rexIds =
          params?.row?.rexs?.map((rex: { id: string }) => rex?.id) || [];
        return <AssociatedRexList ids={rexIds} />;
      },
    },
    {
      field: 'actions',
      headerName: '',
      flex: 0.5,
      renderCell: (params) => {
        const row = params.row;
        const actionId = row?.id;

        return (
          <CustomTooltip text={s.common.tooltip.removeFromFavorites}>
            <CustomButton
              title={''}
              style={{
                backgroundColor: 'transparent',
              }}
              loading={isLoadingBookmarkId === actionId}
              loaderColor={primaryColors.primary}
              iconSrc={bookmarkSlashIcon}
              iconPosition="right"
              onClick={() => {
                setIsLoadingBookmarkId(actionId);
                deleteFavoriteAction.mutateAsync(actionId).finally(() => {
                  setIsLoadingBookmarkId(undefined);
                });
              }}
            />
          </CustomTooltip>
        );
      },
    },
  ];

  return (
    <>
      <div style={{ ...style }}>
        <CustomGrid
          apiRef={apiRef}
          rows={favoriteActions || []}
          columns={columns}
          rowSelectionState={rowSelectionState}
          onRowClick={(row) => navigate('/action/' + row.id)}
        />
      </div>
    </>
  );
};
