import React from 'react';
import { palette } from '../../styles/colors';
import {
  largeMargin,
  mediumRadius,
  smallMargin,
  tinyMargin,
} from '../../styles/styles';
import { fonts } from '../../styles/fonts';
import blueAddButton from '../../assets/icons/blue-add-button.svg';
import greenAddButton from '../../assets/icons/green-add-button.svg';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { s } from '../../strings/strings';
import { CustomGrid } from './CustomGrid';
import { CustomButton } from './CustomButton';
import { useActionMutations } from '../../hooks/useActionMutations';
import { useCauseMutations } from '../../hooks/useCauseMutations';
import { CauseData } from '../../types/common/api';
import { getSearchColor } from '../../utils/main';
import { useMainData } from '../../providers/MainDataProvider';

interface CustomCauseOrActionSelectorProps {
  type: 'cause' | 'action';
  label?: string;
  title: string;
  style?: React.CSSProperties;
  onClick: () => void;
  data: GridRowSelectionModel;
  causeData?: CauseData[];
}

export const CustomCauseOrActionSelector: React.FC<
  CustomCauseOrActionSelectorProps
> = ({ type, label, title, style, onClick, data, causeData }) => {
  const { isTestEnvironment } = useMainData();

  return (
    <>
      {!data || data.length === 0 ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            ...style,
          }}
        >
          {label && (
            <p
              style={{
                ...fonts.medium14,
                color: palette.gray700,
                margin: 0,
                marginBottom: tinyMargin,
              }}
            >
              {label}
            </p>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              border: `1px solid ${palette.gray200}`,
              borderRadius: mediumRadius,
              paddingTop: largeMargin,
              paddingBottom: largeMargin,
              flexGrow: 1,
              cursor: 'pointer',
            }}
            onClick={onClick}
          >
            <img
              src={isTestEnvironment ? greenAddButton : blueAddButton}
              alt="Add button"
            />
            <p
              style={{
                ...fonts.semiBold14,
                margin: 0,
                marginTop: smallMargin,
              }}
            >
              {title}
            </p>
          </div>
        </div>
      ) : (
        <>
          {type === 'cause' && (
            <CausesGrid
              data={data?.map((e) => Number(e))}
              causeData={causeData || []}
              onAdd={onClick}
            />
          )}
          {type === 'action' && (
            <ActionsGrid data={data?.map((e) => Number(e))} onAdd={onClick} />
          )}
        </>
      )}
    </>
  );
};

interface CausesGridProps {
  data: GridRowSelectionModel;
  causeData: CauseData[];
  onAdd: () => void;
}

export const CausesGrid: React.FC<CausesGridProps> = ({
  data,
  causeData,
  onAdd,
}) => {
  const { causes } = useCauseMutations();

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: s.modals.addCause.grid.title,
      flex: 2,
    },
    {
      field: 'cause_type',
      headerName: s.modals.addCause.grid.causeType,
      flex: 1,
      renderCell: (params) => {
        const rowId = params?.row?.id;
        return causeData?.find((c) => c.id === rowId)?.type;
      },
    },
  ];

  return (
    <>
      <div
        style={{
          marginTop: smallMargin,
          width: '100%',
        }}
      >
        <CustomGrid
          toolbar={() => <GridHeader type="cause" onAdd={onAdd} />}
          rows={causes?.filter((c) => data?.includes(c.id)) || []}
          columns={columns}
        />
      </div>
    </>
  );
};

interface ActionsGridProps {
  data: number[];
  onAdd: () => void;
}

export const ActionsGrid: React.FC<ActionsGridProps> = ({ data, onAdd }) => {
  const { actions } = useActionMutations();

  const columns: GridColDef[] = [
    {
      field: 'description',
      headerName: s.modals.addAction.grid.description,
      flex: 2,
    },
    {
      field: 'status',
      headerName: s.modals.addAction.grid.status,
      flex: 1,
    },
    {
      field: 'carrier_name',
      headerName: s.modals.addAction.grid.carrier,
      flex: 1,
    },
  ];

  return (
    <>
      <div
        style={{
          marginTop: smallMargin,
          width: '100%',
        }}
      >
        <CustomGrid
          toolbar={() => <GridHeader type="action" onAdd={onAdd} />}
          rows={actions?.filter((a) => data?.includes(a.id)) || []}
          columns={columns}
        />
      </div>
    </>
  );
};

interface GridHeaderProps {
  type: 'cause' | 'action';
  onAdd: () => void;
}

const GridHeader: React.FC<GridHeaderProps> = ({ type, onAdd }) => {
  const { isTestEnvironment } = useMainData();

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: tinyMargin,
          marginRight: smallMargin,
          marginBottom: tinyMargin,
          marginLeft: smallMargin,
        }}
      >
        <p style={{ ...fonts.semiBold18 }}>
          {type === 'cause'
            ? s.modals.addCause.causes
            : s.modals.addAction.actions}
        </p>
        <CustomButton
          title={s.common.button.edit}
          onClick={onAdd}
          style={{
            color: getSearchColor('rex', isTestEnvironment)?.border,
            backgroundColor: getSearchColor('rex', isTestEnvironment)
              ?.background,
            border: `1px solid ${
              getSearchColor('rex', isTestEnvironment)?.border
            }`,
          }}
        />
      </div>
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {};
