import React, { ReactNode, useEffect, useState } from 'react';
import {
  defaultMargin,
  mediumRadius,
  smallMargin,
} from '../../../styles/styles';
import { palette } from '../../../styles/colors';
import { fonts } from '../../../styles/fonts';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { ReportType, StatsRange } from '../../../types/common/main';

interface DashboardStatsCardProps {
  title: string;
  children: ReactNode;
  rangeState: [StatsRange, React.Dispatch<React.SetStateAction<StatsRange>>];
  disableRexType?: boolean;
  onChangeRexType: (values: { [x: string]: boolean }) => void;
}

export const DashboardStatsCard: React.FC<DashboardStatsCardProps> = ({
  title,
  children,
  rangeState,
  disableRexType,
  onChangeRexType,
}) => {
  const [dateRange, setDateRange] = rangeState;
  const [options, setOptions] = useState({
    [ReportType.Anomaly]: true,
    [ReportType.GoodPractice]: true,
  });

  const handleChangeOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newOptions = {
      ...options,
      [event.target.name]: event.target.checked,
    };
    setOptions(newOptions);
    onChangeRexType(newOptions);
  };

  const handleDateRange = (
    event: React.MouseEvent<HTMLElement>,
    newRange: StatsRange
  ) => {
    setDateRange(newRange);
  };

  const {
    [ReportType.Anomaly]: anomaly,
    [ReportType.GoodPractice]: goodPractice,
  } = options;

  return (
    <>
      <div
        style={{
          border: `1px solid ${palette.gray200}`,
          borderRadius: mediumRadius,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            padding: defaultMargin,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <p style={{ ...fonts.semiBold20, color: palette.gray900, margin: 0 }}>
            {title}
          </p>
          <ToggleButtonGroup
            value={dateRange}
            exclusive
            onChange={handleDateRange}
          >
            <ToggleButton
              value="twelveMonths"
              style={{ textTransform: 'initial' }}
              size="small"
            >
              {'< 12 mois'}
            </ToggleButton>
            <ToggleButton
              value="total"
              style={{ textTransform: 'initial' }}
              size="small"
            >
              {'Depuis le début'}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <Divider />
        <div
          style={{
            padding: smallMargin,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {children}
        </div>
        {!disableRexType && (
          <>
            <Divider />
            <div
              style={{
                paddingLeft: defaultMargin,
                paddingRight: defaultMargin,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={anomaly}
                      onChange={handleChangeOptions}
                      name={ReportType.Anomaly}
                      size="small"
                    />
                  }
                  sx={{
                    '& .MuiTypography-root': {
                      ...fonts.regular14,
                      color: palette.gray700,
                    },
                  }}
                  label={ReportType.Anomaly}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={goodPractice}
                      onChange={handleChangeOptions}
                      name={ReportType.GoodPractice}
                      size="small"
                    />
                  }
                  sx={{
                    '& .MuiTypography-root': {
                      ...fonts.regular14,
                      color: palette.gray700,
                    },
                  }}
                  label={ReportType.GoodPractice}
                />
              </FormGroup>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const Divider = () => {
  return (
    <>
      <div
        style={{
          height: 1,
          borderTop: `1px solid ${palette.gray200}`,
        }}
      />
    </>
  );
};
