import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api } from '../utils/api';
import { AxiosResponse } from 'axios';
import { CreateCause, Cause, UpdateCause } from '../types/common/api';
import { useMainData } from '../providers/MainDataProvider';

export const useCauseMutations = () => {
  const { isTestEnvironment } = useMainData();
  const causesKey = ['rexCauses', ...(isTestEnvironment ? ['test'] : [])];

  const queryClient = useQueryClient();

  const { data: causes, refetch: refetchCauses } = useQuery(
    causesKey,
    async () =>
      await api
        .get('cause')
        .then(({ data }: AxiosResponse<Cause[], any>) => data)
  );

  // Mutations
  const createCause = useMutation(
    (cause: CreateCause) => {
      return api.post(`cause`, cause, {});
    },
    {
      onSuccess: ({ data: cause }: AxiosResponse<Cause, any>) => {
        queryClient.setQueryData(causesKey, (causes: Cause[] | undefined) =>
          causes ? [...causes, cause] : [cause]
        );
      },
    }
  );

  const updateCause = useMutation(
    ({ id, cause }: { id: number; cause: UpdateCause }) => {
      return api.put(`cause/${id}`, cause, {});
    },
    {
      onSuccess: ({ data: cause }: AxiosResponse<Cause, any>) => {
        queryClient.setQueryData(
          causesKey,
          (causes: Cause[] | undefined) =>
            causes?.map((c) => (c.id === cause.id ? cause : c)) || [cause]
        );
      },
    }
  );

  return { causes, refetchCauses, createCause, updateCause };
};
