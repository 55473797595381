import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth } from '../providers/AuthProvider';
import { api } from '../utils/api';
import { Report, UpdateReport } from '../types/common/api';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useMainData } from '../providers/MainDataProvider';

export const useReportsMutations = () => {
  const { isTestEnvironment } = useMainData();
  const { user } = useAuth();

  const myReportsKey = [
    'myReports',
    user?.id,
    ...(isTestEnvironment ? ['test'] : []),
  ];
  const reportsAssignedToMeKey = [
    'reportsAssignedToMe',
    user?.id,
    ...(isTestEnvironment ? ['test'] : []),
  ];

  const [hasNewReports, setHasNewReports] = useState(false);

  const queryClient = useQueryClient();

  const { data: myReports, refetch: refetchMyReports } = useQuery(
    myReportsKey,
    async () =>
      await api
        .get('report/mine')
        .then(({ data }: AxiosResponse<Report[], any>) => data)
  );

  const { data: reportsAssignedToMe, refetch: refetchReportsAssignedToMe } =
    useQuery(
      reportsAssignedToMeKey,
      async () =>
        await api
          .get('report/assigned-to-me')
          .then(({ data }: AxiosResponse<Report[], any>) => data)
    );

  useEffect(() => {
    let hasNewReports = false;
    reportsAssignedToMe?.forEach((report) => {
      if (report.report_status.id === 1) hasNewReports = true;
    });
    setHasNewReports(hasNewReports);
  }, [reportsAssignedToMe]);

  // Mutations
  const answerToReport = useMutation(
    (updateData: UpdateReport) =>
      api.put(`report/${updateData.id}`, {
        answer: updateData.answer,
      }),
    {
      onSuccess: ({ data: updatedReport }: AxiosResponse<Report, any>) => {
        // Update local data
        queryClient.setQueryData(
          myReportsKey,
          (reports: Report[] | undefined) =>
            reports?.map((report) =>
              report.id === updatedReport.id
                ? Object.assign(report, updatedReport)
                : report
            ) || []
        );
        queryClient.setQueryData(
          reportsAssignedToMeKey,
          (reports: Report[] | undefined) =>
            reports?.map((report) =>
              report.id === updatedReport.id
                ? Object.assign(report, updatedReport)
                : report
            ) || []
        );
      },
    }
  );

  const deleteReport = useMutation((id: number) => api.delete(`report/${id}`), {
    onSuccess: ({ data: deletedReport }: AxiosResponse<Report, any>) => {
      // Update local data
      queryClient.setQueryData(
        myReportsKey,
        (reports: Report[] | undefined) =>
          reports?.filter((report) => report.id !== deletedReport.id) || []
      );
      queryClient.setQueryData(
        reportsAssignedToMeKey,
        (reports: Report[] | undefined) =>
          reports?.filter((report) => report.id !== deletedReport.id) || []
      );
    },
  });

  const archiveReport = useMutation(
    (id: number) => api.get(`report/${id}/archive`),
    {
      onSuccess: ({ data: updatedReport }: AxiosResponse<Report, any>) => {
        // Update local data
        queryClient.setQueryData(
          myReportsKey,
          (reports: Report[] | undefined) =>
            reports?.filter((report) => report.id !== updatedReport.id) || []
        );
        queryClient.setQueryData(
          reportsAssignedToMeKey,
          (reports: Report[] | undefined) =>
            reports?.filter((report) => report.id !== updatedReport.id) || []
        );
      },
    }
  );

  return {
    myReports,
    refetchMyReports,
    reportsAssignedToMe,
    refetchReportsAssignedToMe,
    answerToReport,
    hasNewReports,
    deleteReport,
    archiveReport,
  };
};
