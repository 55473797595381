import { palette } from "../../../styles/colors";
import React from "react";
import { CustomChip } from "./CustomChip";

interface ReportUrgencyChipProps {
  urgency: string;
}

export const ReportUrgencyChip: React.FC<ReportUrgencyChipProps> = ({
  urgency,
}) => {
  let colors: { backgroundColor: string; textColor: string };
  switch (urgency) {
    case "Urgent":
      colors = {
        textColor: palette.warning700,
        backgroundColor: palette.warning50,
      };
      break;
    case "Normal":
    default:
      colors = {
        textColor: palette.blue700,
        backgroundColor: palette.blue50,
      };
      break;
  }

  return <CustomChip title={urgency} colors={colors} />;
};
