import { useEffect, useState } from 'react';
import { CustomSearchBar } from '../../components/search/CustomSearchBar';
import { BasicPage } from '../../components/templates/BasicPage';
import { palette } from '../../styles/colors';
import {
  defaultMargin,
  largeMargin,
  largerMargin,
  tinyMargin,
} from '../../styles/styles';
import { s } from '../../strings/strings';
import { fonts } from '../../styles/fonts';
import { CustomButton } from '../../components/common/CustomButton';
import blueDownloadIcon from '../../assets/icons/blue-download.svg';
import greenDownloadIcon from '../../assets/icons/green-download.svg';
import purpleDownloadIcon from '../../assets/icons/purple-download.svg';
import yellowDownloadIcon from '../../assets/icons/yellow-download.svg';
import { GridEventListener, useGridApiRef } from '@mui/x-data-grid';
import { SearchFilters, AdvancedSearchParamsDto } from '../../types/common/api';
import { ExportResultsModal } from '../../components/search/ExportResultsModal';
import { getSearchColor } from '../../utils/main';
import { api } from '../../utils/api';
import { AxiosResponse } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { AdvancedSearchHeader } from '../../components/search/AdvancedSearchHeader';
import { SearchType } from '../../types/common/main';
import { CustomSearchGridResult } from '../../components/search/CustomSearchGridResult';
import { useMainData } from '../../providers/MainDataProvider';

export const SearchPage = () => {
  const { isTestEnvironment } = useMainData();
  const navigate = useNavigate();
  const apiRef = useGridApiRef();
  const { searchType: searchTypeParams } = useParams();
  const defaultSearchType =
    searchTypeParams && ['rex', 'cause', 'action'].includes(searchTypeParams)
      ? (searchTypeParams as SearchType)
      : 'rex';

  const [searchType, setSearchType] = useState<SearchType>(defaultSearchType);
  const [searchValue, setSearchValue] = useState('');
  const [searchParams, setSearchParams] = useState<SearchFilters>({});
  const [searchResults, setSearchResults] = useState<any[] | undefined>([]);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  const [isExportResultsModalOpen, setIsExportResultsModalOpen] =
    useState(false);

  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    setSearchResults([]);
  }, [searchType]);

  useEffect(() => {
    navigate(`/search/${searchType}`, { replace: true });
  }, [searchType, navigate]);

  const getSearchParamsQuery = () => {
    const dto: any = { ...searchParams };
    // Gestion des paramètres particuliers
    if (dto?.rex_creation_date?.min)
      dto.rex_creation_date_min = dto.rex_creation_date.min;
    if (dto?.rex_creation_date?.max)
      dto.rex_creation_date_max = dto.rex_creation_date.max;
    if (dto?.rex_update_date?.min)
      dto.rex_update_date_min = dto.rex_update_date.min;
    if (dto?.rex_update_date?.max)
      dto.rex_update_date_max = dto.rex_update_date.max;
    if (dto?.cresag_date?.min) dto.cresag_date_min = dto.cresag_date.min;
    if (dto?.cresag_date?.max) dto.cresag_date_max = dto.cresag_date.max;
    if (dto?.rex_subject?.id) dto.rex_subject_id = dto.rex_subject.id;
    if (dto?.event_date?.min) dto.event_date_min = dto.event_date.min;
    if (dto?.event_date?.max) dto.event_date_max = dto.event_date?.max;
    if (dto?.flight_phase?.id) dto.flight_phase_id = dto.flight_phase.id;
    if (dto?.risk_probability?.min)
      dto.min_risk_probability = dto.risk_probability.min;
    if (dto?.risk_probability?.max)
      dto.max_risk_probability = dto.risk_probability.max;
    if (dto?.risk_impact?.min) dto.min_risk_impact = dto.risk_impact.min;
    if (dto?.risk_impact?.max) dto.max_risk_impact = dto.risk_impact.max;

    // Actions
    if (dto?.action_creation_date?.min)
      dto.action_creation_date_min = dto.action_creation_date.min;
    if (dto?.action_creation_date?.max)
      dto.action_creation_date_max = dto.action_creation_date.max;
    if (dto?.action_status_date?.min)
      dto.action_status_date_min = dto.action_status_date.min;
    if (dto?.action_status_date?.max)
      dto.action_status_date_max = dto.action_status_date.max;
    if (dto?.action_approval_date?.min)
      dto.action_approval_date_min = dto.action_approval_date.min;
    if (dto?.action_approval_date?.max)
      dto.action_approval_date_max = dto.action_approval_date.max;
    if (dto?.action_start_date?.min)
      dto.action_start_date_min = dto.action_start_date.min;
    if (dto?.action_start_date?.max)
      dto.action_start_date_max = dto.action_start_date.max;
    if (dto?.action_end_date?.min)
      dto.action_end_date_min = dto.action_end_date.min;
    if (dto?.action_end_date?.max)
      dto.action_end_date_max = dto.action_end_date.max;
    if (dto?.action_commissioning_date?.min)
      dto.action_commissioning_date_min = dto.action_commissioning_date.min;
    if (dto?.action_commissioning_date?.max)
      dto.action_commissioning_date_max = dto.action_commissioning_date.max;

    // Suppression des paramètres inutiles
    delete dto.rex_creation_date;
    delete dto.rex_update_date;
    delete dto.cresag_date;
    delete dto.rex_subject;
    delete dto.event_date;
    delete dto.flight_phase;
    delete dto.risk_probability;
    delete dto.risk_impact;
    delete dto.action_creation_date;
    delete dto.action_status_date;
    delete dto.action_approval_date;
    delete dto.action_start_date;
    delete dto.action_end_date;
    delete dto.action_commissioning_date;

    const params: AdvancedSearchParamsDto = { ...dto };

    return params
      ? `?${Object.entries(params)
          ?.map(([key, value]) => {
            return value ? `${key}=${value}` : null;
          })
          ?.filter((elem) => !!elem)
          ?.join('&')}`
      : ``;
  };

  const onSearch = () => {
    setIsSearching(true);
    if (isAdvancedSearch) {
      api
        .get(`search/advanced/${searchType}${getSearchParamsQuery()}`)
        .then(({ data }: AxiosResponse<any[], any>) => setSearchResults(data))
        .finally(() => {
          setIsSearching(false);
        });
    } else {
      api
        .get(`search/${searchType}?search_term=${searchValue}`)
        .then(({ data }: AxiosResponse<any[], any>) => setSearchResults(data))
        .finally(() => {
          setIsSearching(false);
        });
    }
  };

  const onResultClick: GridEventListener<'rowClick'> = (params) => {
    const rexId = params.row?.id;
    navigate(`${rexId}`);
  };

  return (
    <>
      <BasicPage
        style={{
          paddingRight: 0,
          paddingLeft: 0,
          paddingTop: 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: palette.gray50,
            paddingRight: largerMargin,
            paddingLeft: largerMargin,
            paddingTop: defaultMargin,
            paddingBottom: defaultMargin,
            textDecoration: 'none',
          }}
        >
          {isAdvancedSearch ? (
            <AdvancedSearchHeader
              onClose={() => setIsAdvancedSearch(false)}
              onSearch={onSearch}
              searchParamsState={[searchParams, setSearchParams]}
              isSearching={isSearching}
              searchTypeState={[searchType, setSearchType]}
            />
          ) : (
            <CustomSearchBar
              state={[searchValue, setSearchValue]}
              onSearch={onSearch}
              placeholder={s.search.search}
              onClickAdvancedSearch={() => setIsAdvancedSearch(true)}
              isSearching={isSearching}
              searchTypeState={[searchType, setSearchType]}
            />
          )}
        </div>
        <div
          style={{
            marginTop: largeMargin,
            marginLeft: largeMargin * 2,
            marginRight: largeMargin * 2,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              marginBottom: largeMargin,
            }}
          >
            <div>
              <p style={{ ...fonts.bold30, margin: 0 }}>
                {s.search.resultsFound}
              </p>
              <p
                style={{
                  ...fonts.medium18,
                  margin: 0,
                  marginTop: tinyMargin,
                  color: getSearchColor(searchType, isTestEnvironment)?.main,
                }}
              >
                {s.search.resultCounterString(
                  searchResults?.length?.toString() || '0'
                )}
              </p>
            </div>
            <div style={{ display: 'flex' }}>
              <CustomButton
                title={s.search.exportResults}
                onClick={() => setIsExportResultsModalOpen(true)}
                style={{
                  marginLeft: defaultMargin,
                  border: `1px solid ${
                    getSearchColor(searchType, isTestEnvironment)?.border
                  }`,
                  backgroundColor: getSearchColor(searchType, isTestEnvironment)
                    ?.background,
                  color: getSearchColor(searchType, isTestEnvironment)?.border,
                }}
                iconSrc={
                  searchType === 'cause'
                    ? yellowDownloadIcon
                    : searchType === 'action'
                    ? purpleDownloadIcon
                    : isTestEnvironment
                    ? greenDownloadIcon
                    : blueDownloadIcon
                }
                iconPosition="right"
              />
            </div>
          </div>
          <CustomSearchGridResult
            apiRef={apiRef}
            results={searchResults || []}
            searchType={searchType}
            onRowClick={onResultClick}
          />
        </div>
      </BasicPage>
      <ExportResultsModal
        open={isExportResultsModalOpen}
        onClose={() => setIsExportResultsModalOpen(false)}
        apiRef={apiRef}
      />
    </>
  );
};
