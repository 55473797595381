import React, { useState } from 'react';
import { CustomModal } from '../common/CustomModal';
import { fonts } from '../../styles/fonts';
import { colors, palette } from '../../styles/colors';
import { s } from '../../strings/strings';
import { CustomButton } from '../common/CustomButton';
import { defaultMargin, smallMargin } from '../../styles/styles';
import { CustomTextInput } from '../common/CustomTextInput';
import { useMainData } from '../../providers/MainDataProvider';

interface SaveSearchModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (name: string) => void;
}

export const SaveSearchModal: React.FC<SaveSearchModalProps> = ({
  open,
  onClose,
  onSave,
}) => {
  const { primaryColors } = useMainData();

  const [name, setName] = useState('');

  return (
    <>
      <CustomModal
        open={open}
        onClose={onClose}
        closeCross={false}
        style={{ minWidth: 400 }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: defaultMargin,
          }}
        >
          <p
            style={{
              ...fonts.bold20,
              textAlign: 'center',
              marginTop: 0,
              marginBottom: defaultMargin + smallMargin,
            }}
          >
            {s.modals.saveSearch.title}
          </p>
          <div
            style={{
              paddingLeft: defaultMargin,
              paddingRight: defaultMargin,
              width: `calc(100% - ${defaultMargin + defaultMargin}px)`,
            }}
          >
            <CustomTextInput
              state={[name, setName]}
              label={s.modals.saveSearch.inputLabel}
              placeholder={s.common.placeholders.name}
            />
          </div>

          <div
            style={{
              display: 'flex',
              marginTop: defaultMargin + smallMargin,
              alignSelf: 'flex-end',
            }}
          >
            <CustomButton
              title={s.common.button.cancel}
              onClick={onClose}
              style={{
                marginRight: defaultMargin,
                paddingLeft: defaultMargin + smallMargin,
                paddingRight: defaultMargin + smallMargin,
                backgroundColor: 'transparent',
                color: palette.gray600,
              }}
            />
            <CustomButton
              title={s.common.button.save}
              onClick={() => {
                onSave(name);
                setName('');
                onClose();
              }}
              style={{
                marginRight: defaultMargin,
                paddingLeft: defaultMargin + smallMargin,
                paddingRight: defaultMargin + smallMargin,
                backgroundColor: primaryColors.primary,
                color: palette.white,
              }}
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
};
