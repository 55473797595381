import React from 'react';
import { defaultMargin, smallMargin, tinyMargin } from '../../../styles/styles';
import { s } from '../../../strings/strings';
import { fonts } from '../../../styles/fonts';
import { SearchFilters } from '../../../types/common/api';
import { Grid } from '@mui/material';
import { getSearchColor } from '../../../utils/main';
import { SearchType } from '../../../types/common/main';
import { AssociatedRexFilters } from '../common/AssociatedRexFilters';
import { ActionInfoFilters } from './ActionInfoFilters';
import { useMainData } from '../../../providers/MainDataProvider';

interface ActionAdvancedSearchFiltersProps {
  searchParamsState: [
    SearchFilters,
    React.Dispatch<React.SetStateAction<SearchFilters>>
  ];
  searchType: SearchType;
}

export const ActionAdvancedSearchFilters: React.FC<
  ActionAdvancedSearchFiltersProps
> = ({ searchParamsState, searchType }) => {
  const { isTestEnvironment } = useMainData();
  const colors = {
    main: getSearchColor(searchType, isTestEnvironment)?.main,
    background: getSearchColor(searchType, isTestEnvironment)?.background,
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Grid
          container
          rowSpacing={`${smallMargin + tinyMargin}px`}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          style={{ marginTop: defaultMargin }}
        >
          <Grid item xs={2.5}>
            <p style={styles.gridTitleItem}>
              {s.search.advancedSearchHeader.associatedRexInfo}
            </p>
          </Grid>
          <Grid item xs={9.5}>
            <AssociatedRexFilters
              searchParamsState={searchParamsState}
              colors={colors}
            />
          </Grid>
          <Grid item xs={2.5}>
            <p style={styles.gridTitleItem}>
              {s.search.advancedSearchHeader.actionInfo}
            </p>
          </Grid>
          <Grid item xs={9.5}>
            <ActionInfoFilters
              searchParamsState={searchParamsState}
              colors={colors}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  gridTitleItem: {
    margin: 0,
    marginTop: smallMargin + tinyMargin,
    ...fonts.semiBold16,
  },
};
