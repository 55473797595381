import { createContext, useContext, useEffect, useState } from 'react';
import { colors } from '../styles/colors';
import environment from '../environment';
import { updateBaseUrl } from '../utils/api';

interface MainDataProps {
  isTestEnvironment: boolean | undefined;
  setIsTestEnvironment: (isTestEnvironment: boolean | undefined) => void;
  children: React.ReactNode;
}

interface PrimaryColors {
  primary: string;
}

interface MainDataContextType {
  todoAlert: () => void;
  isTestEnvironment: boolean;
  setIsTestEnvironment: (isTestEnvironment: boolean) => void;
  primaryColors: PrimaryColors;
}

const MainDataContext = createContext<MainDataContextType>(undefined!!);

export const MainDataProvider = ({
  isTestEnvironment,
  setIsTestEnvironment,
  children,
}: MainDataProps) => {
  const [primaryColors, setPrimaryColors] = useState<PrimaryColors>({
    primary: colors.primary,
  });

  const todoAlert = () => alert('En cours de développement');

  useEffect(() => {
    if (isTestEnvironment === undefined) return;

    setPrimaryColors(
      isTestEnvironment
        ? {
            primary: colors.primaryTest,
          }
        : {
            primary: colors.primary,
          }
    );

    updateBaseUrl(
      isTestEnvironment ? environment.testApiUrl : environment.apiUrl
    );
  }, [isTestEnvironment]);

  if (isTestEnvironment === undefined) return null;

  return (
    <MainDataContext.Provider
      value={{
        todoAlert,
        isTestEnvironment: isTestEnvironment,
        setIsTestEnvironment,
        primaryColors,
      }}
    >
      {children}
    </MainDataContext.Provider>
  );
};

export const useMainData = () => useContext(MainDataContext);
