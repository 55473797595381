import { GridColDef } from '@mui/x-data-grid';
import { BasicPage } from '../../components/templates/BasicPage';
import { useReportsMutations } from '../../hooks/useReportsMutations';
import { s } from '../../strings/strings';
import { palette } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import {
  defaultMargin,
  largeMargin,
  largerMargin,
  smallMargin,
  tinyMargin,
} from '../../styles/styles';
import arrowLeftIcon from '../../assets/icons/arrow-narrow-left.svg';
import classicPenIcon from '../../assets/icons/classic-pen.svg';
import { Link, useNavigate } from 'react-router-dom';
import { CustomGrid } from '../../components/common/CustomGrid';
import { ReportUrgencyChip } from '../../components/common/chip/ReportUrgencyChip';
import { ReportTypeChip } from '../../components/common/chip/ReportTypeChip';
import { FlightPhaseChip } from '../../components/common/chip/FlightPhaseChip';
import { FlightPhase } from '../../types/common/api';
import { useAuth } from '../../providers/AuthProvider';
import { CustomTooltip } from '../../components/common/CustomTooltip';

export const ReportToProcessPage = () => {
  const { hasCPSPermission } = useAuth();
  const { reportsAssignedToMe } = useReportsMutations();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: s.rex.reportToProcess.grid.id,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.id;
      },
    },
    {
      field: 'sender_name',
      headerName: s.rex.reportToProcess.grid.author,
      flex: 1.5,
    },
    {
      field: 'report_subject',
      headerName: s.rex.reportToProcess.grid.subject,
      flex: 2,
      renderCell: (params) => {
        const row = params.row;
        return row?.report_subject?.name;
      },
    },
    {
      field: 'urgency',
      headerName: s.rex.reportToProcess.grid.urgency,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.urgency ? (
          <ReportUrgencyChip urgency={row.urgency} />
        ) : (
          <p>N/A</p>
        );
      },
    },
    {
      field: 'created_at',
      headerName: s.rex.reportToProcess.grid.creationDate,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        const eventDateString = row?.created_at;
        if (!eventDateString) return '';
        const date = new Date(eventDateString);
        const formattedDate = date.toLocaleDateString('fr-FR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
        const formattedTime = date.toLocaleTimeString('fr-FR', {
          hour: '2-digit',
          minute: '2-digit',
        });
        return `${formattedDate} ${formattedTime}`;
      },
    },
    {
      field: 'flight_phase',
      headerName: s.rex.reportToProcess.grid.flightPhase,
      renderCell: (params) => {
        const row = params.row;
        const flightPhase: FlightPhase = row?.flight_phase;
        return flightPhase ? (
          <FlightPhaseChip flightPhase={flightPhase} />
        ) : (
          <p>N/A</p>
        );
      },
      flex: 1,
    },
    {
      field: 'report_type',
      headerName: s.rex.reportToProcess.grid.reportType,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.report_type ? (
          <ReportTypeChip reportType={row.report_type} />
        ) : (
          <p>N/A</p>
        );
      },
    },
    ...(hasCPSPermission
      ? [
          {
            field: 'edit',
            headerName: '',
            flex: 1,
            renderCell: (params: any) => {
              const row = params.row;
              const id = row.id;

              return (
                <>
                  <CustomTooltip text={s.common.tooltip.edit}>
                    <img
                      src={classicPenIcon}
                      alt="Edit icon"
                      onClick={(e) => {
                        navigate(`${id}`);
                        e.stopPropagation();
                      }}
                      style={{ cursor: 'pointer', padding: smallMargin }}
                    />
                  </CustomTooltip>
                </>
              );
            },
          },
        ]
      : []),
  ];

  return (
    <>
      <BasicPage
        style={{
          paddingRight: 0,
          paddingLeft: 0,
          paddingTop: 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: palette.gray50,
            paddingRight: largerMargin,
            paddingLeft: largerMargin,
            paddingTop: defaultMargin,
            paddingBottom: defaultMargin,
            textDecoration: 'none',
            cursor: 'pointer',
          }}
        >
          <Link
            to={'./..'}
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
          >
            <img
              src={arrowLeftIcon}
              alt=""
              style={{ marginRight: tinyMargin }}
            />
            <p
              style={{
                ...fonts.medium14,
                color: palette.gray600,
                margin: 0,
              }}
            >
              {s.common.button.back}
            </p>
          </Link>
          <p style={{ ...fonts.bold30, margin: 0, marginTop: defaultMargin }}>
            {s.rex.home.reportToProcess}
          </p>
        </div>
        <div
          style={{
            marginTop: largeMargin,
            marginLeft: largeMargin * 2,
            marginRight: largeMargin * 2,
          }}
        >
          <CustomGrid
            rows={reportsAssignedToMe || []}
            columns={columns}
            onRowClick={
              hasCPSPermission ? (row) => navigate(`${row.id}`) : undefined
            }
          />
        </div>
      </BasicPage>
    </>
  );
};
