import { largeRadius, smallMargin, tinyMargin } from "../../../styles/styles";
import React from "react";
import { fonts } from "../../../styles/fonts";

interface CustomChipProps {
  title: string;
  viewStyle?: React.CSSProperties | undefined;
  textStyle?: React.CSSProperties | undefined;
  colors: { backgroundColor: string; textColor: string };
}

export const CustomChip: React.FC<CustomChipProps> = ({
  title,
  viewStyle,
  textStyle,
  colors,
}) => {
  return (
    <div
      style={{
        ...viewStyle,
        paddingLeft: smallMargin,
        paddingRight: smallMargin,
        paddingTop: tinyMargin,
        paddingBottom: tinyMargin,
        backgroundColor: colors.backgroundColor,
        borderRadius: largeRadius,
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <p
        style={{
          ...fonts.medium12,
          color: colors.textColor,
          margin: 0,
          ...textStyle,
        }}
      >
        {title}
      </p>
    </div>
  );
};
