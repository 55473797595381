import { Stack } from "@mui/material";
import { AdvancedSearchItem } from "../AdvancedSearchItem";
import { s } from "../../../strings/strings";
import { smallMargin } from "../../../styles/styles";
import { SearchFilters } from "../../../types/common/api";
import React from "react";
import { getDisplayableStringFromDateRange } from "../../../utils/date";

interface ActionInfoFiltersProps {
  searchParamsState: [
    SearchFilters,
    React.Dispatch<React.SetStateAction<SearchFilters>>
  ];
  colors: { main?: string; background?: string };
}

export const ActionInfoFilters: React.FC<ActionInfoFiltersProps> = ({
  searchParamsState,
  colors,
}) => {
  const [searchParams, setSearchParams] = searchParamsState;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: `${smallMargin}px`,
        }}
      >
        <Stack
          direction="row"
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: `${smallMargin}px`,
          }}
        >
          <AdvancedSearchItem
            title={s.search.advancedSearchParams.actionInfo.number}
            type="number"
            value={searchParams.action_id}
            onClose={(value: any) => {
              setSearchParams((oldParams) => ({
                ...oldParams,
                action_id: value,
              }));
            }}
            backgroundColor={colors.background}
            color={colors.main}
          />
          <AdvancedSearchItem
            title={s.search.advancedSearchParams.actionInfo.description}
            type="text"
            inputModalPlaceholder={s.common.placeholders.description}
            value={searchParams.action_description}
            onClose={(value: any) => {
              setSearchParams((oldParams) => ({
                ...oldParams,
                action_description: value,
              }));
            }}
            backgroundColor={colors.background}
            color={colors.main}
          />
          <AdvancedSearchItem
            title={s.search.advancedSearchParams.actionInfo.creationDate}
            type="dateRange"
            value={searchParams.action_creation_date}
            displayString={
              searchParams?.action_creation_date
                ? getDisplayableStringFromDateRange(
                    searchParams.action_creation_date
                  )
                : undefined
            }
            onClose={(value: any) => {
              setSearchParams((oldParams) => ({
                ...oldParams,
                action_creation_date: value,
              }));
            }}
            backgroundColor={colors.background}
            color={colors.main}
          />
          <AdvancedSearchItem
            title={s.search.advancedSearchParams.actionInfo.status}
            type="actionStatus"
            value={searchParams.action_status}
            onClose={(value: any) => {
              setSearchParams((oldParams) => ({
                ...oldParams,
                action_status: value,
              }));
            }}
            backgroundColor={colors.background}
            color={colors.main}
          />
          <AdvancedSearchItem
            title={s.search.advancedSearchParams.actionInfo.statusDate}
            type="dateRange"
            value={searchParams.action_status_date}
            displayString={
              searchParams?.action_status_date
                ? getDisplayableStringFromDateRange(
                    searchParams.action_status_date
                  )
                : undefined
            }
            onClose={(value: any) => {
              setSearchParams((oldParams) => ({
                ...oldParams,
                action_status_date: value,
              }));
            }}
            backgroundColor={colors.background}
            color={colors.main}
          />
          <AdvancedSearchItem
            title={s.search.advancedSearchParams.actionInfo.approvalDate}
            type="dateRange"
            value={searchParams.action_approval_date}
            displayString={
              searchParams?.action_approval_date
                ? getDisplayableStringFromDateRange(
                    searchParams.action_approval_date
                  )
                : undefined
            }
            onClose={(value: any) => {
              setSearchParams((oldParams) => ({
                ...oldParams,
                action_approval_date: value,
              }));
            }}
            backgroundColor={colors.background}
            color={colors.main}
          />
          <AdvancedSearchItem
            title={s.search.advancedSearchParams.actionInfo.startDate}
            type="dateRange"
            value={searchParams.action_start_date}
            displayString={
              searchParams?.action_start_date
                ? getDisplayableStringFromDateRange(
                    searchParams.action_start_date
                  )
                : undefined
            }
            onClose={(value: any) => {
              setSearchParams((oldParams) => ({
                ...oldParams,
                action_start_date: value,
              }));
            }}
            backgroundColor={colors.background}
            color={colors.main}
          />
          <AdvancedSearchItem
            title={s.search.advancedSearchParams.actionInfo.endDate}
            type="dateRange"
            value={searchParams.action_end_date}
            displayString={
              searchParams?.action_end_date
                ? getDisplayableStringFromDateRange(
                    searchParams.action_end_date
                  )
                : undefined
            }
            onClose={(value: any) => {
              setSearchParams((oldParams) => ({
                ...oldParams,
                action_end_date: value,
              }));
            }}
            backgroundColor={colors.background}
            color={colors.main}
          />
          <AdvancedSearchItem
            title={s.search.advancedSearchParams.actionInfo.commissioningDate}
            type="dateRange"
            value={searchParams.action_commissioning_date}
            displayString={
              searchParams?.action_commissioning_date
                ? getDisplayableStringFromDateRange(
                    searchParams.action_commissioning_date
                  )
                : undefined
            }
            onClose={(value: any) => {
              setSearchParams((oldParams) => ({
                ...oldParams,
                action_commissioning_date: value,
              }));
            }}
            backgroundColor={colors.background}
            color={colors.main}
          />
          <AdvancedSearchItem
            title={s.search.advancedSearchParams.actionInfo.carrierName}
            type="text"
            inputModalPlaceholder={s.common.placeholders.name}
            value={searchParams.action_carrier_name}
            onClose={(value: any) => {
              setSearchParams((oldParams) => ({
                ...oldParams,
                action_carrier_name: value,
              }));
            }}
            backgroundColor={colors.background}
            color={colors.main}
          />
        </Stack>
      </div>
    </>
  );
};
