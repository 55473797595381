import React, { useMemo } from 'react';
import { s } from '../../../strings/strings';
import { FlightPhase, Rex } from '../../../types/common/api';
import { GridColDef } from '@mui/x-data-grid';
import { CustomGrid } from '../../common/CustomGrid';
import { mediumRadius } from '../../../styles/styles';
import { ReportTypeChip } from '../../common/chip/ReportTypeChip';
import { FlightPhaseChip } from '../../common/chip/FlightPhaseChip';
import { convertObjectToQueryParams, getRiskColor } from '../../../utils/main';
import { useQuery } from 'react-query';
import { api } from '../../../utils/api';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { getEntitiesStatsParam } from '../../../utils/stats';
import { useMainData } from '../../../providers/MainDataProvider';

interface DashboardLastRexsProps {
  style?: React.CSSProperties;
  selectedEntityIdx?: string;
  entityChildren?: string[];
}

export const DashboardLastRexs: React.FC<DashboardLastRexsProps> = ({
  style,
  selectedEntityIdx,
  entityChildren,
}) => {
  const { isTestEnvironment } = useMainData();
  const navigate = useNavigate();

  const entities = useMemo(() => {
    return getEntitiesStatsParam(selectedEntityIdx, entityChildren);
  }, [selectedEntityIdx, entityChildren]);

  const lastRexsKey = [
    'lastRexs',
    ...(isTestEnvironment ? ['test'] : []),
    ...(entities?.length ? entities : []),
  ];

  const { data: lastRexs } = useQuery(lastRexsKey, async () =>
    api
      .get(
        `rex/recent${convertObjectToQueryParams({
          entities_idx: entities,
        })}`
      )
      .then(({ data }: AxiosResponse<Rex[], any>) => data)
  );

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: s.common.grid.id,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.id;
      },
    },
    {
      field: 'title',
      headerName: s.common.grid.title,
      flex: 2,
    },
    {
      field: 'rex_type',
      headerName: s.common.grid.rexType,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.rex_type ? (
          <ReportTypeChip reportType={row.rex_type} />
        ) : (
          <p>N/A</p>
        );
      },
    },
    {
      field: 'event_date',
      headerName: s.common.grid.date,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        const eventDateString = row?.event_date;
        const date = eventDateString?.split('-')?.reverse()?.join('/');
        return date;
      },
    },
    {
      field: 'flight_phase',
      headerName: s.rex.reportToProcess.grid.flightPhase,
      renderCell: (params) => {
        const row = params.row;
        const flightPhase: FlightPhase = row?.flight_phase;
        return flightPhase ? (
          <FlightPhaseChip flightPhase={flightPhase} />
        ) : (
          <p>N/A</p>
        );
      },
      flex: 1,
    },
    {
      field: 'flight_kind',
      headerName: s.common.grid.flightKind,
      flex: 1,
    },
    {
      field: 'flight_type',
      headerName: s.common.grid.flightType,
      flex: 1,
    },
    {
      field: 'aircraft_registration',
      headerName: s.common.grid.registration,
      flex: 1,
    },
    {
      field: 'event_kind',
      headerName: s.common.grid.classification,
      flex: 1,
    },
    {
      field: 'initial_risk_probability',
      headerName: s.common.grid.risk,
      renderCell: (params) => {
        const row = params.row;
        const severity: number = row?.initial_risk_severity;
        const probability: number = row?.initial_risk_probability;
        return (
          <>
            <div
              style={{
                display: 'flex',
                backgroundColor: getRiskColor(severity, probability),
                borderRadius: mediumRadius,
                height: 18,
                width: 18,
              }}
            ></div>
          </>
        );
      },
      flex: 0.5,
    },
  ];

  return (
    <>
      <div style={{ ...style }}>
        <CustomGrid
          headerTitle={s.dashboard.grids.lastRexs}
          rows={lastRexs || []}
          columns={columns}
          onRowClick={(row) => navigate('/rex/' + row.id)}
        />
      </div>
    </>
  );
};
