import { colors, palette } from '../styles/colors';
import { SearchType } from '../types/common/main';

export const getRiskColor = (
  severity: number,
  probability: number,
  opacity?: number
) => {
  const score = severity * probability;

  let color: string;

  if (score >= 15) color = palette.error500;
  else if (score >= 10) color = palette.warning500;
  else if (score >= 5) color = palette.warning300;
  else color = palette.success500;

  if (opacity) {
    const rgba = color?.substring(5, color.length - 1)?.split(',');
    rgba.pop();
    rgba.push(`${opacity}`);
    color = `rgba(${rgba.join(',')})`;
  }

  return color;
};

export const getSearchColor = (
  searchType: SearchType,
  isTest: boolean = false
) => {
  if (searchType === 'rex')
    return {
      main: isTest ? colors.primaryTest : colors.primary,
      border: isTest ? palette.success700 : palette.darkBlue600,
      background: isTest ? palette.success50 : palette.darkBlue50,
    };
  if (searchType === 'action')
    return {
      main: palette.violet600,
      border: palette.violet600,
      background: palette.violet50,
    };
  if (searchType === 'cause')
    return {
      main: palette.warning600,
      border: palette.warning600,
      background: palette.warning50,
    };

  return;
};

export const convertObjectToQueryParams = (obj: any) => {
  return obj
    ? `?${Object.entries(obj)
        ?.map(([key, value]) => {
          return value ? `${key}=${value}` : null;
        })
        ?.filter((elem) => !!elem)
        ?.join('&')}`
    : ``;
};
