import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth } from '../providers/AuthProvider';
import { api } from '../utils/api';
import { Rex } from '../types/common/api';
import { AxiosResponse } from 'axios';
import { convertObjectToQueryParams } from '../utils/main';
import { useMainData } from '../providers/MainDataProvider';

export const useRexsToPublishMutations = (entitiesIdx?: string[]) => {
  const { isTestEnvironment } = useMainData();
  const { user } = useAuth();

  const rexsToPublishKey = [
    'rexsToPublish',
    user?.id,
    ...(isTestEnvironment ? ['test'] : []),
    ...(entitiesIdx?.length ? entitiesIdx : []),
  ];

  const queryClient = useQueryClient();

  const { data: rexsToPublish, refetch: refetchRexsToPublish } = useQuery(
    rexsToPublishKey,
    async () =>
      await api
        .get(
          `rex/publishable${
            entitiesIdx?.length
              ? convertObjectToQueryParams({
                  entities_idx: entitiesIdx,
                })
              : ''
          }`
        )
        .then(({ data }: AxiosResponse<Rex[], any>) => data)
  );

  const publishRex = useMutation((id: number) => api.get(`rex/${id}/publish`), {
    onSuccess: ({ data: updatedRex }: AxiosResponse<Rex, any>, id) => {
      // Update local data
      queryClient.setQueryData(
        rexsToPublishKey,
        (rexs: Rex[] | undefined) => rexs?.filter((rex) => rex?.id !== id) || []
      );
    },
  });

  const removeRexToPublish = useMutation(
    (id: number) => api.delete(`rex/${id}`),
    {
      onSuccess: ({ data: deletedRex }: AxiosResponse<Rex, any>, id) => {
        // Update local data
        queryClient.setQueryData(rexsToPublishKey, (rexs: Rex[] | undefined) =>
          rexs ? rexs?.filter((rex) => rex.id !== id) : []
        );
      },
    }
  );

  const archiveRex = useMutation((id: number) => api.put(`rex/${id}/archive`), {
    onSuccess: ({ data: deletedRex }: AxiosResponse<Rex, any>, id) => {
      // Update local data
      queryClient.setQueryData(rexsToPublishKey, (rexs: Rex[] | undefined) =>
        rexs ? rexs?.filter((rex) => rex.id !== id) : []
      );
    },
  });

  return {
    rexsToPublish,
    refetchRexsToPublish,
    publishRex,
    removeRexToPublish,
    archiveRex,
  };
};
