import { useEffect, useState } from "react";
import { ReportSubject } from "../types/common/api";
import { api } from "../utils/api";
import { AxiosResponse } from "axios";

export const useReportSubjects = () => {
  const [reportSubjects, setReportSubjects] = useState<ReportSubject[]>();

  useEffect(() => {
    api
      .get(`/report-subject`)
      .then(({ data }: AxiosResponse<ReportSubject[], any>) => {
        setReportSubjects(data);
      });
  }, []);

  return { reportSubjects };
};
