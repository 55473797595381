import { Stack } from '@mui/material';
import { AdvancedSearchItem } from '../../../components/search/AdvancedSearchItem';
import { s } from '../../../strings/strings';
import { smallMargin } from '../../../styles/styles';
import { SearchFilters } from '../../../types/common/api';
import React from 'react';
import { getDisplayableStringFromDateRange } from '../../../utils/date';
import { useAuth } from '../../../providers/AuthProvider';

interface RexCharacteristicFiltersProps {
  searchParamsState: [
    SearchFilters,
    React.Dispatch<React.SetStateAction<SearchFilters>>
  ];
  colors: { main?: string; background?: string };
}

export const RexCharacteristicFilters: React.FC<
  RexCharacteristicFiltersProps
> = ({ searchParamsState, colors }) => {
  const { hasCPSPermission, hasCSPermission } = useAuth();
  const [searchParams, setSearchParams] = searchParamsState;

  return (
    <>
      <Stack
        direction="row"
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: `${smallMargin}px`,
        }}
      >
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexCharacteristic.number}
          type="number"
          value={searchParams.rex_id}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              rex_id: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexCharacteristic.creationDate}
          type="dateRange"
          value={searchParams.rex_creation_date}
          displayString={
            searchParams?.rex_creation_date
              ? getDisplayableStringFromDateRange(
                  searchParams.rex_creation_date
                )
              : undefined
          }
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              rex_creation_date: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexCharacteristic.updateDate}
          type="dateRange"
          value={searchParams.rex_update_date}
          displayString={
            searchParams?.rex_update_date
              ? getDisplayableStringFromDateRange(searchParams.rex_update_date)
              : undefined
          }
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              rex_update_date: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexCharacteristic.createdBy}
          type="text"
          value={searchParams.rex_created_by}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              rex_created_by: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexCharacteristic.updatedBy}
          type="text"
          value={searchParams.rex_updated_by}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              rex_updated_by: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexCharacteristic.aeroclub}
          type="text"
          value={searchParams.club_name}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              club_name: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexCharacteristic.cps}
          type="text"
          value={searchParams.cps_name}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              cps_name: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexCharacteristic.declarer}
          type="text"
          value={searchParams.declarer_name}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              declarer_name: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        <AdvancedSearchItem
          title={s.search.advancedSearchParams.rexCharacteristic.anonymous}
          type="yesNo"
          value={searchParams.anonymous}
          onClose={(value: any) => {
            setSearchParams((oldParams) => ({
              ...oldParams,
              anonymous: value,
            }));
          }}
          backgroundColor={colors.background}
          color={colors.main}
        />
        {(hasCPSPermission || hasCSPermission) && (
          <>
            <AdvancedSearchItem
              title={s.search.advancedSearchParams.rexCharacteristic.published}
              type="yesNo"
              value={searchParams.published}
              onClose={(value: any) => {
                setSearchParams((oldParams) => ({
                  ...oldParams,
                  published: value,
                }));
              }}
              backgroundColor={colors.background}
              color={colors.main}
            />
            <AdvancedSearchItem
              title={s.search.advancedSearchParams.rexCharacteristic.archived}
              type="yesNo"
              value={searchParams.archived}
              onClose={(value: any) => {
                setSearchParams((oldParams) => ({
                  ...oldParams,
                  archived: value,
                }));
              }}
              backgroundColor={colors.background}
              color={colors.main}
            />
          </>
        )}
      </Stack>
    </>
  );
};
