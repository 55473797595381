import axios from 'axios';
import environment from '../environment';

export const api = axios.create({
  baseURL: environment.apiUrl,
});

export const updateBaseUrl = (url: string) => {
  api.defaults.baseURL = url;
};

export const updateApiAuthorization = (token: string | null) =>
  (api.defaults.headers.common['Authorization'] = token
    ? `Bearer ${token}`
    : null);

export const updateSmileApiAuthorization = (
  token: string | null,
  licenseNumber: string | null
) => {
  api.defaults.headers.common['smile_token'] = token ? `${token}` : null;
  api.defaults.headers.common['smile_license_number'] = licenseNumber
    ? `${licenseNumber}`
    : null;
};
