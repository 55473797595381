import { useEffect, useState } from 'react';
import { EditOrCreateRex } from '../../components/rex/EditOrCreateRex';
import { BasicPage } from '../../components/templates/BasicPage';
import { s } from '../../strings/strings';
import { palette } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import {
  defaultMargin,
  largeMargin,
  largeRadius,
  largerMargin,
  mediumRadius,
  smallMargin,
  tinyMargin,
} from '../../styles/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from '../../utils/api';
import { AxiosResponse } from 'axios';
import { Rex, RexDraft, RexHistory } from '../../types/common/api';
import { CustomButton } from '../../components/common/CustomButton';
import historyIcon from '../../assets/icons/history.svg';
import arrowRightIcon from '../../assets/icons/arrow-right-dark-gray.svg';
import { Divider } from '@mui/material';

export const CompleteRexPage = () => {
  const { rexId } = useParams();
  const navigate = useNavigate();

  const [rex, setRex] = useState<Rex>();
  const [initialRexData, setInitialRexData] = useState<RexDraft>();
  const [rexHistoryData, setRexHistoryData] = useState<RexHistory>();
  const [isRexHistoryOpen, setIsRexHistoryOpen] = useState<boolean>(false);

  useEffect(() => {
    api
      .get(`rex/${rexId}`)
      .then(({ data }: AxiosResponse<Rex, any>) => setRex(data));
  }, [rexId]);

  useEffect(() => {
    const rexDraft: RexDraft = {
      ...rex,
      images: rex?.images?.map((image) => ({ uri: image.path })),
    };
    setInitialRexData(rexDraft);
  }, [rex]);

  useEffect(() => {
    if (isRexHistoryOpen) {
      const data = rexHistoryData === undefined ? rex : rexHistoryData;
      const rexDraft: RexDraft = {
        ...data,
        id: Number(rexId),
        images: data?.images?.map((image) => ({ uri: image.path })),
      };
      setInitialRexData(rexDraft);
    }
  }, [rexHistoryData, isRexHistoryOpen, rexId, rex]);

  if (!rexId) {
    navigate('..');
    return <></>;
  }

  return (
    <>
      <BasicPage
        style={{
          paddingRight: 0,
          paddingLeft: 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            backgroundColor: palette.gray50,
            paddingRight: largerMargin,
            paddingLeft: largerMargin,
            paddingTop: defaultMargin,
            paddingBottom: defaultMargin,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <p style={{ ...fonts.bold24, margin: 0 }}>
              {s.rex.newRex.completeRex.title}
            </p>
            <p style={{ ...fonts.medium16, margin: 0, marginTop: smallMargin }}>
              {s.rex.newRex.completeRex.subtitle}
            </p>
          </div>
          {isRexHistoryOpen ? (
            <div style={{ display: 'flex', gap: smallMargin }}>
              <CustomButton
                title={s.rex.newRex.leave}
                onClick={() => {
                  setIsRexHistoryOpen(false);
                }}
                style={{
                  ...fonts.medium14,
                  color: palette.darkBlue600,
                  backgroundColor: palette.white,
                  border: `1px solid ${palette.darkBlue600}`,
                  flexGrow: 0,
                }}
              />
              {rexHistoryData?.id && (
                <CustomButton
                  title={s.rex.newRex.restoreVersion}
                  onClick={() => {
                    const rexDraft: RexDraft = {
                      ...rexHistoryData,
                      id: Number(rexId),
                      images: rexHistoryData?.images?.map((image) => ({
                        uri: image.path,
                      })),
                    };
                    setRexHistoryData(undefined);
                    setIsRexHistoryOpen(false);
                    setInitialRexData(rexDraft);
                  }}
                  style={{
                    ...fonts.medium14,
                    color: palette.white,
                    border: `1px solid ${palette.darkBlue600}`,
                    flexGrow: 0,
                  }}
                />
              )}
            </div>
          ) : (
            <CustomButton
              title={s.rex.newRex.showRexHistory}
              onClick={() => {
                setIsRexHistoryOpen(true);
              }}
              style={{
                ...fonts.medium14,
                color: palette.darkBlue600,
                backgroundColor: palette.white,
                border: `1px solid ${palette.darkBlue600}`,
                flexGrow: 0,
              }}
              iconSrc={historyIcon}
              iconPosition="right"
            />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: largeMargin,
            marginLeft: largerMargin * 3,
            marginRight: largerMargin * 3,
          }}
        >
          <EditOrCreateRex initialRexData={initialRexData} />
          {isRexHistoryOpen && (
            <RexHistoryList
              versions={rex?.versions?.sort(
                (a, b) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
              )}
              onSelect={(rex?: RexHistory) => setRexHistoryData(rex)}
              selectedId={rexHistoryData?.id}
            />
          )}
        </div>
      </BasicPage>
    </>
  );
};

interface RexHistoryListProps {
  versions?: RexHistory[];
  onSelect: (rex?: RexHistory) => void;
  selectedId?: number;
}

const RexHistoryList: React.FC<RexHistoryListProps> = ({
  versions,
  onSelect,
  selectedId,
}) => {
  return (
    <>
      <div
        style={{
          border: `1px solid ${palette.gray200}`,
          borderRadius: mediumRadius,
          marginLeft: largeMargin,
          minWidth: 250,
        }}
      >
        <p
          style={{
            ...fonts.regular16,
            margin: defaultMargin,
          }}
        >
          {s.rexHistory.title}
        </p>
        <Divider
          style={{
            backgroundColor: palette.gray300,
          }}
        />
        <CurrentVersionItem
          onSelect={() => {
            onSelect(undefined);
          }}
          isSelected={selectedId === undefined}
        />
        {versions?.map((version, index) => (
          <VersionItem
            key={index}
            date={new Date(version.created_at)}
            name={`${version.updated_by?.name}`}
            onSelect={() => {
              onSelect(version);
            }}
            isSelected={version.id === selectedId}
          />
        ))}
      </div>
    </>
  );
};

const dateToHistoryVersionString = (date: Date) => {
  // Tableaux pour les noms des mois et des jours
  const nomsMois = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ];

  // Obtention des informations sur la date
  const jour = date.getDate();
  const mois = date.getMonth();
  const heure = date.getHours();
  const minute = date.getMinutes();

  // Construction de la chaîne de caractères formatée
  const chaineDate = `Version ${jour} ${nomsMois[mois]} à ${
    (heure < 10 ? '0' : '') + heure
  }:${(minute < 10 ? '0' : '') + minute}`;

  return chaineDate;
};

interface VersionItemProps {
  date: Date;
  onSelect: () => void;
  isSelected: boolean;
  name: string;
}

const VersionItem: React.FC<VersionItemProps> = ({
  date,
  onSelect,
  isSelected,
  name,
}) => {
  return (
    <>
      <div
        style={{
          padding: defaultMargin,
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'flex-start',
          cursor: 'pointer',
          backgroundColor: isSelected ? palette.gray100 : 'transparent',
        }}
        onClick={onSelect}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            src={arrowRightIcon}
            alt=""
            style={{ marginRight: tinyMargin }}
          />

          <p style={{ ...fonts.regular14, margin: 0 }}>
            {dateToHistoryVersionString(date)}
          </p>
        </div>
        <div
          style={{
            paddingLeft: smallMargin,
            paddingRight: smallMargin,
            paddingTop: tinyMargin,
            paddingBottom: tinyMargin,
            backgroundColor: palette.gray100,
            borderRadius: largeRadius,
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
            flexShrink: 1,
            marginTop: smallMargin,
          }}
        >
          <div
            style={{
              backgroundColor: palette.gray500,
              height: 8,
              aspectRatio: 1,
              borderRadius: mediumRadius,
              display: 'flex',
              marginRight: tinyMargin,
            }}
          />
          <p
            style={{
              ...fonts.medium12,
              color: palette.gray700,
              margin: 0,
            }}
          >
            {name}
          </p>
        </div>
      </div>
      <Divider
        style={{
          backgroundColor: palette.gray100,
        }}
      />
    </>
  );
};

interface CurrentVersionItemProps {
  onSelect: () => void;
  isSelected: boolean;
}

const CurrentVersionItem: React.FC<CurrentVersionItemProps> = ({
  onSelect,
  isSelected,
}) => {
  return (
    <>
      <div
        style={{
          padding: defaultMargin,
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'flex-start',
          cursor: 'pointer',
          backgroundColor: isSelected ? palette.gray100 : 'transparent',
        }}
        onClick={onSelect}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <p style={{ ...fonts.regular14, margin: 0 }}>
            {s.rexHistory.currentVersion}
          </p>
        </div>
      </div>
      <Divider
        style={{
          backgroundColor: palette.gray100,
        }}
      />
    </>
  );
};
