import React, { useEffect, useMemo, useState } from 'react';
import { palette } from '../../styles/colors';
import { defaultMargin, largeMargin, smallMargin } from '../../styles/styles';
import { s } from '../../strings/strings';
import { fonts } from '../../styles/fonts';
import { CustomButton } from '../../components/common/CustomButton';
import closeIcon from '../../assets/icons/close.svg';
import refreshIcon from '../../assets/icons/refresh.svg';
import { SearchFilters } from '../../types/common/api';
import { SaveSearchModal } from '../../components/search/SaveSearchModal';
import { getSearchColor } from '../../utils/main';
import { SearchType } from '../../types/common/main';
import { CustomSearchTypeSelector } from './CustomSearchTypeSelector';
import { AdvancedSearchFilters } from './AdvancedSearchFilters';
import { useSavedSearches } from '../../hooks/useSavedSearches';
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { DeleteDialog } from '../common/DeleteDialog';
import { useMainData } from '../../providers/MainDataProvider';
import { CustomTooltip } from '../common/CustomTooltip';
interface AdvancedSearchHeaderProps {
  onClose: () => void;
  onSearch: () => void;
  searchParamsState: [
    SearchFilters,
    React.Dispatch<React.SetStateAction<SearchFilters>>
  ];
  isSearching: boolean;
  searchTypeState: [
    SearchType,
    React.Dispatch<React.SetStateAction<SearchType>>
  ];
}

export const AdvancedSearchHeader: React.FC<AdvancedSearchHeaderProps> = ({
  onClose,
  onSearch,
  searchParamsState,
  isSearching,
  searchTypeState,
}) => {
  const { savedSearches, addSavedSearch, removeSavedSearch } =
    useSavedSearches();
  const { isTestEnvironment } = useMainData();

  const [isSaveSearchModalOpen, setIsSaveSearchModalOpen] = useState(false);
  const [searchType] = searchTypeState;
  const [searchParams, setSearchParams] = searchParamsState;
  const [selectedSavedSearch, setSelectedSavedSearch] = useState<string>();

  useEffect(() => {
    if (selectedSavedSearch) {
      const search = savedSearches.find(
        (search) => search.id === parseInt(selectedSavedSearch)
      );
      if (!search) return;
      if (search.params !== searchParams) setSelectedSavedSearch(undefined);
    }
  }, [searchParams, selectedSavedSearch, savedSearches]);

  useEffect(() => {
    setSelectedSavedSearch(undefined);
  }, [searchType]);

  const getSearchTitle = () => {
    if (searchType === 'rex') return s.search.searchRex;
    if (searchType === 'cause') return s.search.searchCauses;
    if (searchType === 'action') return s.search.searchActions;
    return '';
  };

  const getAdvancedSearchTitle = () => {
    if (searchType === 'rex') return s.search.advancedSearchHeader.rexTitle;
    if (searchType === 'cause') return s.search.advancedSearchHeader.causeTitle;
    if (searchType === 'action')
      return s.search.advancedSearchHeader.actionTitle;
    return '';
  };
  const filteredSavedSearches = useMemo(() => {
    return savedSearches.filter((search) => search.type === searchType);
  }, [savedSearches, searchType]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            ...fonts.bold30,
            color: getSearchColor(searchType, isTestEnvironment)?.main,
            marginBottom: 0,
          }}
        >
          {getAdvancedSearchTitle()}
        </p>
        <CustomTooltip text={s.common.tooltip.closeAdvancedSearch}>
          <CustomButton
            onClick={onClose}
            title={''}
            iconSrc={closeIcon}
            style={{
              backgroundColor: palette.white,
              border: `1px solid ${palette.gray200}`,
              color: palette.gray600,
              padding: smallMargin,
            }}
          />
        </CustomTooltip>
      </div>
      <div>
        <AdvancedSearchFilters
          searchParamsState={searchParamsState}
          searchType={searchType}
        />
        <div
          style={{
            marginTop: largeMargin,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <FormControl sx={{ width: 300 }}>
              <InputLabel id="saved-searches-label">
                {s.search.advancedSearchHeader.myRexSearch}
              </InputLabel>
              <Select
                labelId="saved-searches-label"
                id="saved-searches"
                value={selectedSavedSearch || ''}
                displayEmpty
                onChange={(event: SelectChangeEvent) => {
                  const selectedId = event.target.value;
                  const selectedSearch = filteredSavedSearches.find(
                    (search) => search.id === parseInt(selectedId)
                  );
                  if (!selectedSearch) return;
                  setSelectedSavedSearch(selectedId);
                  setSearchParams(selectedSearch.params);
                }}
                input={
                  <OutlinedInput
                    label={s.search.advancedSearchHeader.myRexSearch}
                  />
                }
                renderValue={(selected) => {
                  const selectedSearch = filteredSavedSearches.find(
                    (search) => search.id === parseInt(selected)
                  );
                  return selectedSearch?.title || '';
                }}
              >
                {filteredSavedSearches?.length > 0 ? (
                  filteredSavedSearches.map((savedSearch) => (
                    <MenuItem key={savedSearch.id} value={`${savedSearch.id}`}>
                      <ListItemText primary={savedSearch.title || ''} />
                      <DeleteDialog
                        onDelete={() => {
                          removeSavedSearch(savedSearch.id);
                        }}
                      >
                        <img
                          src={closeIcon}
                          alt="close"
                          style={{
                            zIndex: 100,
                            cursor: 'pointer',
                          }}
                        />
                      </DeleteDialog>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">
                    <em>{s.search.advancedSearchHeader.noSavedSearch}</em>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </div>
          <div style={{ display: 'flex' }}>
            <CustomButton
              title={s.common.button.reset}
              onClick={() => {
                setSearchParams({});
              }}
              iconSrc={refreshIcon}
              style={{
                color: palette.gray700,
                backgroundColor: 'transparent',
              }}
            />
            <CustomButton
              title={s.search.advancedSearchHeader.saveSearch}
              onClick={() => setIsSaveSearchModalOpen(true)}
              style={{
                marginLeft: defaultMargin,
                color: getSearchColor(searchTypeState[0], isTestEnvironment)
                  ?.border,
                backgroundColor: getSearchColor(
                  searchTypeState[0],
                  isTestEnvironment
                )?.background,
                border: `1px solid ${
                  getSearchColor(searchTypeState[0], isTestEnvironment)?.border
                }`,
              }}
            />
            <CustomButton
              title={getSearchTitle()}
              onClick={onSearch}
              style={{
                marginLeft: defaultMargin,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor: getSearchColor(
                  searchTypeState[0],
                  isTestEnvironment
                )?.main,
              }}
              loading={isSearching}
            />
            <CustomSearchTypeSelector searchTypeState={searchTypeState} />
          </div>
        </div>
      </div>
      <SaveSearchModal
        open={isSaveSearchModalOpen}
        onClose={() => setIsSaveSearchModalOpen(false)}
        onSave={(name) => {
          addSavedSearch({
            title: name,
            params: searchParams,
            type: searchType,
          });
        }}
      />
    </>
  );
};
