import { palette } from "../../../styles/colors";
import React from "react";
import { CustomChip } from "./CustomChip";
import { ReportType } from "../../../types/common/main";

interface ReportTypeChipProps {
  reportType: string;
}

export const ReportTypeChip: React.FC<ReportTypeChipProps> = ({
  reportType,
}) => {
  let colors: { backgroundColor: string; textColor: string };
  switch (reportType) {
    case ReportType.GoodPractice:
      colors = {
        textColor: palette.blue700,
        backgroundColor: palette.blue50,
      };
      break;
    case ReportType.Anomaly:
    default:
      colors = {
        textColor: palette.error700,
        backgroundColor: palette.error50,
      };
      break;
  }

  return <CustomChip title={reportType} colors={colors} />;
};
