import React from 'react';
import { Grid } from '@mui/material';
import { DashboardCharts } from './common/DashboardCharts';
import { DashboardOutstandingREXOfTheMonth } from './licensee/DashboardOutstandingREXOfTheMonth';
import { DashboardLastRexs } from './licensee/DashboardLastRexs';

interface DashboardLicenseeSectionProps {
  selectedEntityIdx?: string;
  entityChildren?: string[];
}

export const DashboardLicenseeSection: React.FC<
  DashboardLicenseeSectionProps
> = ({ selectedEntityIdx, entityChildren }) => {
  return (
    <>
      <Grid container rowSpacing={1.5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <DashboardOutstandingREXOfTheMonth />
        </Grid>
        <Grid item xs={12}>
          <DashboardLastRexs
            selectedEntityIdx={selectedEntityIdx}
            entityChildren={entityChildren}
          />
        </Grid>
        <DashboardCharts
          selectedEntityIdx={selectedEntityIdx}
          entityChildren={entityChildren}
        />
      </Grid>
    </>
  );
};
