import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { s } from '../../../strings/strings';
import { mediumRadius, smallMargin, tinyMargin } from '../../../styles/styles';
import { palette } from '../../../styles/colors';
import { fonts } from '../../../styles/fonts';
import { SearchFilters } from '../../../types/common/api';

interface SearchAreaFiltersProps {
  searchParamsState: [
    SearchFilters,
    React.Dispatch<React.SetStateAction<SearchFilters>>
  ];
}

export const SearchAreaFilters: React.FC<SearchAreaFiltersProps> = ({
  searchParamsState,
}) => {
  const [searchParams, setSearchParams] = searchParamsState;

  const values = ['all', 'cra', 'cda', 'platform', 'interclub', 'clubs'];

  return (
    <>
      <FormControl>
        <RadioGroup
          row
          name="row-radio-search-area"
          value={searchParams?.search_area || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const value = (event.target as HTMLInputElement).value;
            setSearchParams((oldParams) => ({
              ...oldParams,
              search_area: value,
            }));
          }}
        >
          {values.map((value) => (
            <div style={styles.areaItem} key={value}>
              <FormControlLabel
                value={value}
                control={<Radio size="small" style={styles.radioStyle} />}
                label={
                  <p style={styles.areaText}>
                    {
                      s.search.advancedSearchParams.searchArea[
                        value as keyof typeof s.search.advancedSearchParams.searchArea
                      ]
                    }
                  </p>
                }
                style={styles.formControlStyle}
              />
            </div>
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  areaItem: {
    border: `1px solid ${palette.gray200}`,
    paddingLeft: smallMargin,
    borderRadius: mediumRadius,
    marginRight: smallMargin,
    backgroundColor: palette.white,
  },
  areaText: {
    ...fonts.regular14,
    lineHeight: '16px',
    margin: 0,
    color: palette.gray800,
  },
  radioStyle: {
    padding: 0,
    marginRight: smallMargin,
    height: 15,
    aspectRatio: 1,
  },
  formControlStyle: {
    marginRight: 0,
    padding: `${smallMargin}px ${smallMargin + tinyMargin}px`,
  },
};
