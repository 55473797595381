import React, { useCallback, useEffect, useState } from 'react';
import {
  defaultMargin,
  mediumRadius,
  smallMargin,
  tinyMargin,
} from '../../../styles/styles';
import { palette } from '../../../styles/colors';
import { fonts } from '../../../styles/fonts';
import {
  CircularProgress,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { StatsRange } from '../../../types/common/main';
import { s } from '../../../strings/strings';
import checkCircleIcon from '../../../assets/icons/check-circle.svg';
import editIcon from '../../../assets/icons/edit.svg';
import inboxIcon from '../../../assets/icons/inbox.svg';
import hourglassIcon from '../../../assets/icons/hourglass.svg';
import { api } from '../../../utils/api';
import { CPSStatsDto, StatsOptionsDto } from '../../../types/common/api';
import { CustomTooltip } from '../../common/CustomTooltip';
import { useMainData } from '../../../providers/MainDataProvider';
import { getEntitiesStatsParam } from '../../../utils/stats';
import { convertObjectToQueryParams } from '../../../utils/main';

interface DashboardCPSStatsCardProps {
  selectedEntityIdx?: string;
  entityChildren?: string[];
}

export const DashboardCPSStatsCard: React.FC<DashboardCPSStatsCardProps> = ({
  selectedEntityIdx,
  entityChildren,
}) => {
  const [statsRange, setStatsRange] = useState<StatsRange>('total');
  const [loading, setLoading] = useState<boolean>(false);
  const [stats, setStats] = useState<CPSStatsDto>();

  const getOptionsParamsQuery = useCallback(
    (range: StatsRange) => {
      const dto: StatsOptionsDto = {
        entities_idx: getEntitiesStatsParam(selectedEntityIdx, entityChildren),
      };

      if (range !== 'total') {
        const date = new Date();
        if (range === 'twelveMonths') date.setMonth(date.getMonth() - 12);
        if (range === 'threeMonths') date.setMonth(date.getMonth() - 3);
        dto.rex_date_min = date.toISOString()?.split('T')?.shift();
      }

      return convertObjectToQueryParams(dto);
    },
    [entityChildren, selectedEntityIdx]
  );

  const fetchStats = useCallback(
    (range: StatsRange) => {
      setLoading(true);
      api
        .get(`stats/cps${getOptionsParamsQuery(range)}`)
        .then(({ data }) => {
          setStats(data);
        })
        .finally(() => setLoading(false));
    },
    [getOptionsParamsQuery]
  );

  useEffect(() => {
    fetchStats(statsRange);
  }, [statsRange, fetchStats]);

  const handleDateRange = (
    event: React.MouseEvent<HTMLElement>,
    newRange: StatsRange
  ) => {
    setStatsRange(newRange);
  };

  const msToDays = (ms: number) => {
    return Math.round((ms / (1000 * 60 * 60 * 24)) * 10) / 10 || 0.1;
  };

  return (
    <>
      <div
        style={{
          border: `1px solid ${palette.gray200}`,
          borderRadius: mediumRadius,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            padding: defaultMargin,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <p style={{ ...fonts.semiBold20, color: palette.gray900, margin: 0 }}>
            {s.dashboard.cpsStats.title}
          </p>
          <ToggleButtonGroup
            value={statsRange}
            exclusive
            onChange={handleDateRange}
          >
            <ToggleButton
              value="threeMonths"
              style={{ textTransform: 'initial' }}
              size="small"
            >
              {'< 3 mois'}
            </ToggleButton>
            <ToggleButton
              value="twelveMonths"
              style={{ textTransform: 'initial' }}
              size="small"
            >
              {'< 12 mois'}
            </ToggleButton>
            <ToggleButton
              value="total"
              style={{ textTransform: 'initial' }}
              size="small"
            >
              {'Depuis le début'}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <Divider />
        {loading ? (
          <div
            style={{
              display: ' flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: defaultMargin,
            }}
          >
            <CircularProgress
              style={{ color: palette.darkBlue600 }}
              size={40}
            />
          </div>
        ) : stats ? (
          <>
            <div
              style={{
                padding: smallMargin,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                style={{ padding: defaultMargin }}
              >
                <Grid item xs={4}>
                  <CardItem
                    title={s.dashboard.cpsStats.reportsReceived}
                    iconSrc={inboxIcon}
                    value={stats.reportsReceived}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CardItem
                    title={s.dashboard.cpsStats.rexCreated}
                    iconSrc={editIcon}
                    value={stats.rexCreated}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CardItem
                    title={s.dashboard.cpsStats.rexWaiting}
                    iconSrc={hourglassIcon}
                    value={stats.rexWaiting}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CardItem
                    title={s.dashboard.cpsStats.rexPublished}
                    iconSrc={checkCircleIcon}
                    value={stats.rexPublished}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CardItem
                    title={s.dashboard.cpsStats.cresagCreated}
                    iconSrc={editIcon}
                    value={stats.cresagCreated}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CardItem
                    title={s.dashboard.cpsStats.cresagFinished}
                    iconSrc={checkCircleIcon}
                    value={stats.cresagFinished}
                  />
                </Grid>
              </Grid>
            </div>
            <div>
              <Divider />
              <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                  <ListItem
                    title={s.dashboard.cpsStats.ffaPosts}
                    value={`${stats.ffaPosts}`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ListItem
                    title={s.dashboard.cpsStats.rexOpenPendingInvestigation}
                    value={`${stats.rexOpenPendingInvestigation}`}
                    tooltip={
                      s.dashboard.cpsStats.rexOpenPendingInvestigationTooltip
                    }
                  />
                </Grid>
              </Grid>
              <Divider />
              <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                  <ListItem
                    title={s.dashboard.cpsStats.craPosts}
                    value={`${stats.craPosts}`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ListItem
                    title={s.dashboard.cpsStats.rexWithOfficialInvestigation}
                    value={`${stats.rexWithOfficialInvestigation}`}
                    tooltip={
                      s.dashboard.cpsStats.rexWithOfficialInvestigationTooltip
                    }
                  />
                </Grid>
              </Grid>
              <Divider />
              <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                  <ListItem
                    title={s.dashboard.cpsStats.cdaPosts}
                    value={`${stats.cdaPosts}`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ListItem
                    title={s.dashboard.cpsStats.averageReportProcessingTime}
                    value={`${msToDays(stats.averageReportProcessingTime)}`}
                    unit={s.common.days}
                  />
                </Grid>
              </Grid>
              <Divider />
              <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                  <ListItem
                    title={s.dashboard.cpsStats.platformPosts}
                    value={`${stats.platformPosts}`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ListItem
                    title={s.dashboard.cpsStats.averageRexProcessingTime}
                    value={`${msToDays(stats.averageRexProcessingTime)}`}
                    unit={s.common.days}
                  />
                </Grid>
              </Grid>
              <Divider />
              <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                  <ListItem
                    title={s.dashboard.cpsStats.interClubPosts}
                    value={`${stats.interClubPosts}`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ListItem
                    title={s.dashboard.cpsStats.membersWhoMadeReport}
                    value={`${stats.membersWhoMadeReport}`}
                  />
                </Grid>
              </Grid>
              <Divider />
              <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                  <ListItem
                    title={s.dashboard.cpsStats.clubPosts}
                    value={`${stats.clubPosts}`}
                  />
                </Grid>
              </Grid>
            </div>
          </>
        ) : (
          <>
            <p style={{ ...fonts.semiBold18 }}>{s.dashboard.charts.noData}</p>
          </>
        )}
      </div>
    </>
  );
};

const Divider = () => {
  return (
    <>
      <div
        style={{
          height: 1,
          borderTop: `1px solid ${palette.gray200}`,
        }}
      />
    </>
  );
};

interface CardItemProps {
  title: string;
  iconSrc: string;
  value: number;
}

const CardItem: React.FC<CardItemProps> = ({ title, iconSrc, value }) => {
  const { primaryColors } = useMainData();

  return (
    <>
      <div>
        <p
          style={{
            ...fonts.regular14,
            color: palette.gray600,
            margin: 0,
            marginBottom: smallMargin,
          }}
        >
          {title}
        </p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={iconSrc} alt="" style={{ marginRight: tinyMargin }} />
          <p
            style={{
              ...fonts.semiBold24,
              color: primaryColors.primary,
              margin: 0,
            }}
          >
            {value}
          </p>
        </div>
      </div>
    </>
  );
};

interface ListItemProps {
  title: string;
  value: string;
  unit?: string;
  tooltip?: string;
}

const ListItem: React.FC<ListItemProps> = ({ title, value, unit, tooltip }) => {
  const { primaryColors } = useMainData();

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          paddingLeft: defaultMargin,
          paddingRight: defaultMargin,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexBasis: '80%',
            marginRight: smallMargin,
            alignItems: 'center',
            columnGap: smallMargin,
          }}
        >
          <p
            style={{
              ...fonts.regular14,
              color: palette.gray600,
              margin: 0,
            }}
          >
            {title}
          </p>
          {tooltip && <CustomTooltip text={tooltip} />}
        </div>
        <p
          style={{
            ...fonts.semiBold18,
            color: primaryColors.primary,
            margin: 0,
          }}
        >
          {value}{' '}
          {unit && (
            <span style={{ ...fonts.semiBold12, color: primaryColors.primary }}>
              {unit}
            </span>
          )}
        </p>
      </div>
    </>
  );
};
