import { Stack, Tab, Tabs } from '@mui/material';
import { BasicPage } from '../../components/templates/BasicPage';
import { useEffect, useState } from 'react';
import { s } from '../../strings/strings';
import { defaultMargin } from '../../styles/styles';
import { palette } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import { FavoritesRexsSection } from '../../components/favorites/FavoritesRexsSection';
import { FavoritesCausesSection } from '../../components/favorites/FavoritesCausesSection';
import { FavoritesActionsSection } from '../../components/favorites/FavoritesActionsSection';
import { CustomButton } from '../../components/common/CustomButton';
import trashIcon from '../../assets/icons/trash.svg';
import greenDownloadIcon from '../../assets/icons/green-download.svg';
import blueDownloadIcon from '../../assets/icons/blue-download.svg';
import yellowDownloadIcon from '../../assets/icons/yellow-download.svg';
import purpleDownloadIcon from '../../assets/icons/purple-download.svg';
import { ExportResultsModal } from '../../components/search/ExportResultsModal';
import { GridRowSelectionModel, useGridApiRef } from '@mui/x-data-grid';
import { useFavoriteRexMutations } from '../../hooks/useFavoriteRexMutations';
import { useFavoriteActionMutations } from '../../hooks/useFavoriteActionMutations';
import { useFavoriteCauseMutations } from '../../hooks/useFavoriteCauseMutations';
import { DeleteDialog } from '../../components/common/DeleteDialog';
import { useMainData } from '../../providers/MainDataProvider';
import { CustomTooltip } from '../../components/common/CustomTooltip';

const AvailableTabs = {
  REXS: s.favorites.tabs.rexs,
  CAUSES: s.favorites.tabs.causes,
  ACTIONS: s.favorites.tabs.actions,
};

export const MyFavoritesHomePage = () => {
  const { isTestEnvironment } = useMainData();
  const { deleteFavoriteRexs } = useFavoriteRexMutations();
  const { deleteFavoriteActions } = useFavoriteActionMutations();
  const { deleteFavoriteCauses } = useFavoriteCauseMutations();
  const apiRef = useGridApiRef();
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const [isLoadingDeletion, setIsLoadingDeletion] = useState<boolean>(false);
  const [isExportResultsModalOpen, setIsExportResultsModalOpen] =
    useState(false);

  const [selectedTab, setSelectedTab] = useState<string>(AvailableTabs.REXS);

  useEffect(() => {
    setRowSelectionModel([]);
  }, [selectedTab]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const getButtonBackgroundColor = () => {
    if (selectedTab === AvailableTabs.REXS)
      return isTestEnvironment ? palette.success50 : palette.darkBlue50;
    if (selectedTab === AvailableTabs.CAUSES) return palette.warning50;
    if (selectedTab === AvailableTabs.ACTIONS) return palette.violet50;
    return palette.darkBlue50;
  };

  const getButtonColor = () => {
    if (selectedTab === AvailableTabs.REXS)
      return isTestEnvironment ? palette.success700 : palette.darkBlue600;
    if (selectedTab === AvailableTabs.CAUSES) return palette.warning600;
    if (selectedTab === AvailableTabs.ACTIONS) return palette.violet600;
    return palette.darkBlue600;
  };

  const getDownloadIcon = () => {
    if (selectedTab === AvailableTabs.REXS)
      return isTestEnvironment ? greenDownloadIcon : blueDownloadIcon;
    if (selectedTab === AvailableTabs.CAUSES) return yellowDownloadIcon;
    if (selectedTab === AvailableTabs.ACTIONS) return purpleDownloadIcon;
    return blueDownloadIcon;
  };

  const deleteSelectedRows = () => {
    const deleteMutation =
      selectedTab === AvailableTabs.REXS
        ? deleteFavoriteRexs
        : selectedTab === AvailableTabs.CAUSES
        ? deleteFavoriteCauses
        : selectedTab === AvailableTabs.ACTIONS
        ? deleteFavoriteActions
        : null;

    if (!deleteMutation) return;
    setIsLoadingDeletion(true);

    deleteMutation
      .mutateAsync(rowSelectionModel?.map((id) => Number(id)))
      .finally(() => {
        setIsLoadingDeletion(false);
      });
  };

  return (
    <>
      <BasicPage>
        <div>
          <p
            style={{ ...fonts.bold30, margin: 0, marginBottom: defaultMargin }}
          >
            {s.favorites.title}
          </p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              style={{
                color: palette.darkBlue600,
                marginBottom: defaultMargin,
              }}
              TabIndicatorProps={{
                sx: {
                  backgroundColor: getButtonColor(),
                },
              }}
              sx={{
                '.Mui-selected': {
                  color: getButtonColor(),
                },
              }}
            >
              {[
                AvailableTabs.REXS,
                AvailableTabs.CAUSES,
                AvailableTabs.ACTIONS,
              ]?.map((tabName, index) => {
                return <Tab key={index} label={tabName} value={tabName} />;
              })}
            </Tabs>
            <Stack
              spacing={`${defaultMargin}px`}
              direction="row"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <DeleteDialog onDelete={deleteSelectedRows}>
                <CustomTooltip text={s.common.tooltip.deleteSelectedFavorites}>
                  <CustomButton
                    title={''}
                    loading={isLoadingDeletion}
                    loaderColor={palette.gray600}
                    style={{
                      ...fonts.medium14,
                      color: palette.gray600,
                      backgroundColor: palette.white,
                      border: `1px solid ${palette.gray200}`,
                      flexGrow: 0,
                    }}
                    iconSrc={trashIcon}
                  />
                </CustomTooltip>
              </DeleteDialog>
              <CustomButton
                title={s.favorites.exportResults}
                onClick={() => setIsExportResultsModalOpen(true)}
                style={{
                  ...fonts.medium14,
                  backgroundColor: getButtonBackgroundColor(),
                  border: `1px solid ${getButtonColor()}`,
                  color: getButtonColor(),
                }}
                iconSrc={getDownloadIcon()}
                iconPosition="right"
              />
            </Stack>
          </div>
          <div style={{}}>
            {selectedTab === AvailableTabs.REXS && (
              <FavoritesRexsSection
                apiRef={apiRef}
                rowSelectionState={[rowSelectionModel, setRowSelectionModel]}
              />
            )}
            {selectedTab === AvailableTabs.CAUSES && (
              <FavoritesCausesSection
                apiRef={apiRef}
                rowSelectionState={[rowSelectionModel, setRowSelectionModel]}
              />
            )}
            {selectedTab === AvailableTabs.ACTIONS && (
              <FavoritesActionsSection
                apiRef={apiRef}
                rowSelectionState={[rowSelectionModel, setRowSelectionModel]}
              />
            )}
          </div>
        </div>
      </BasicPage>
      <ExportResultsModal
        open={isExportResultsModalOpen}
        onClose={() => setIsExportResultsModalOpen(false)}
        apiRef={apiRef}
      />
    </>
  );
};
