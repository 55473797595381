import { PieChart } from "@mui/x-charts";
import { PieChartProps } from "@mui/x-charts/PieChart/PieChart";
import React from "react";
import { palette } from "../../../styles/colors";
import { fonts } from "../../../styles/fonts";

export const CustomPieChart: React.FC<PieChartProps> = (props) => {
  return (
    <>
      <PieChart
        width={400}
        height={200}
        colors={[
          palette.darkBlue500,
          palette.orange300,
          palette.teal800,
          palette.success200,
          palette.error400,
          palette.pink200,
          palette.violet400,
          palette.purple800,
          palette.gray600,
        ]}
        sx={{
          "--ChartsLegend-itemMarkSize": "10px",
          "--ChartsLegend-labelSpacing": "5px",
          "--ChartsLegend-rootSpacing": "10px",
          "--ChartsLegend-rootOffsetY": "0px",
          "& .MuiChartsLegend-label": {
            ...fonts.regular14,
            color: palette.gray600,
          },
        }}
        {...props}
      />
    </>
  );
};
