import React, { useState } from 'react';
import { s } from '../../strings/strings';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { CustomGrid } from '../common/CustomGrid';
import { useFavoriteCauseMutations } from '../../hooks/useFavoriteCauseMutations';
import bookmarkSlashIcon from '../../assets/icons/bookmark-slash.svg';
import { CustomButton } from '../common/CustomButton';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { AssociatedRexList } from '../common/AssociatedRexList';
import { useNavigate } from 'react-router-dom';
import { useMainData } from '../../providers/MainDataProvider';
import { CustomTooltip } from '../common/CustomTooltip';

interface FavoritesCausesSectionProps {
  style?: React.CSSProperties;
  apiRef: React.MutableRefObject<GridApiCommunity>;
  rowSelectionState: [
    GridRowSelectionModel,
    React.Dispatch<React.SetStateAction<GridRowSelectionModel>>
  ];
}

export const FavoritesCausesSection: React.FC<FavoritesCausesSectionProps> = ({
  style,
  apiRef,
  rowSelectionState,
}) => {
  const { primaryColors } = useMainData();
  const navigate = useNavigate();
  const { favoriteCauses, deleteFavoriteCause } = useFavoriteCauseMutations();
  const [isLoadingBookmarkId, setIsLoadingBookmarkId] = useState<number>();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: s.common.grid.id,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.id;
      },
    },
    {
      field: 'title',
      headerName: s.common.grid.title,
      flex: 6,
    },
    {
      field: 'associated_rexs',
      headerName: s.common.grid.associatedRexs,
      flex: 3,
      renderCell: (params) => {
        const rexIds =
          params?.row?.rexs?.map(
            (causeRex: { rex: { id: string } }) => causeRex?.rex?.id
          ) || [];
        return <AssociatedRexList ids={rexIds} />;
      },
    },
    {
      field: 'actions',
      headerName: '',
      flex: 0.5,
      renderCell: (params) => {
        const row = params.row;
        const causeId = row?.id;

        return (
          <CustomTooltip text={s.common.tooltip.removeFromFavorites}>
            <CustomButton
              title={''}
              style={{
                backgroundColor: 'transparent',
              }}
              loading={isLoadingBookmarkId === causeId}
              loaderColor={primaryColors.primary}
              iconSrc={bookmarkSlashIcon}
              iconPosition="right"
              onClick={() => {
                setIsLoadingBookmarkId(causeId);
                deleteFavoriteCause.mutateAsync(causeId).finally(() => {
                  setIsLoadingBookmarkId(undefined);
                });
              }}
            />
          </CustomTooltip>
        );
      },
    },
  ];

  return (
    <>
      <div style={{ ...style }}>
        <CustomGrid
          apiRef={apiRef}
          rows={favoriteCauses || []}
          columns={columns}
          rowSelectionState={rowSelectionState}
          onRowClick={(row) => navigate('/cause/' + row.id)}
        />
      </div>
    </>
  );
};
