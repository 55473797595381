import React, { useEffect, useState } from 'react';
import { CustomModal } from '../common/CustomModal';
import { fonts } from '../../styles/fonts';
import { palette } from '../../styles/colors';
import { s } from '../../strings/strings';
import { CustomButton } from '../common/CustomButton';
import {
  defaultMargin,
  largeMargin,
  mediumRadius,
  smallMargin,
  tinyMargin,
} from '../../styles/styles';
import { Divider, Grid, Stack } from '@mui/material';
import { AdvancedSearchItem } from '../search/AdvancedSearchItem';
import { CustomGrid } from '../common/CustomGrid';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { CustomTextInput } from '../common/CustomTextInput';
import arrowLeftIcon from '../../assets/icons/arrow-narrow-left.svg';
import { CustomDropdown } from '../common/CustomDropdown';
import { useActionMutations } from '../../hooks/useActionMutations';
import { actionStatus } from '../../constants';
import { getSearchColor } from '../../utils/main';
import { useMainData } from '../../providers/MainDataProvider';
import { Action, UpdateAction } from '../../types/common/api';

interface AddActionModalProps {
  open: boolean;
  onClose: () => void;
  rowSelectionState?: [
    GridRowSelectionModel,
    React.Dispatch<React.SetStateAction<GridRowSelectionModel>>
  ];
  actionToEdit?: Action;
  onBack?: () => void;
}

export const AddActionModal: React.FC<AddActionModalProps> = ({
  open,
  onClose,
  rowSelectionState,
  actionToEdit,
  onBack,
}) => {
  const [isAddingAction, setIsAddingAction] = useState(false);

  return (
    <>
      <CustomModal
        open={open}
        onClose={onClose}
        closeCross={false}
        style={{ minWidth: 950 }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {isAddingAction || actionToEdit ? (
            <AddActionContent
              onBack={onBack ? onBack : () => setIsAddingAction(false)}
              actionToEdit={actionToEdit}
            />
          ) : (
            <MainContent
              onAddAction={() => setIsAddingAction(true)}
              onClose={onClose}
              rowSelectionState={rowSelectionState}
            />
          )}
        </div>
      </CustomModal>
    </>
  );
};

interface MainContentProps {
  onAddAction: () => void;
  onClose: () => void;
  rowSelectionState?: [
    GridRowSelectionModel,
    React.Dispatch<React.SetStateAction<GridRowSelectionModel>>
  ];
}

const MainContent: React.FC<MainContentProps> = ({
  onAddAction,
  onClose,
  rowSelectionState,
}) => {
  const { isTestEnvironment } = useMainData();
  const { actions } = useActionMutations();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: s.modals.addAction.grid.number,
      flex: 1,
    },
    {
      field: 'description',
      headerName: s.modals.addAction.grid.description,
      flex: 1,
    },
    {
      field: 'created_at',
      headerName: s.modals.addAction.grid.creationDate,
      flex: 1,
    },
    {
      field: 'status',
      headerName: s.modals.addAction.grid.status,
      flex: 1,
    },
    {
      field: 'date_status',
      headerName: s.modals.addAction.grid.dateStatus,
      flex: 1,
    },
    {
      field: 'carrier_name',
      headerName: s.modals.addAction.grid.carrier,
      flex: 1,
    },
    {
      field: 'rex_count',
      headerName: s.modals.addAction.grid.rexCount,
      flex: 1,
      valueGetter: (params) => params?.row?._count?.rexs,
    },
  ];

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: defaultMargin,
          marginLeft: 0,
          marginRight: 0,
          flexGrow: 1,
          maxHeight: '75vh',
        }}
      >
        <p
          style={{
            ...fonts.bold20,
            textAlign: 'center',
            marginTop: 0,
          }}
        >
          {s.modals.addAction.title}
        </p>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: defaultMargin,
            marginBottom: smallMargin,
          }}
        >
          <p
            style={{
              ...fonts.bold18,
              margin: 0,
            }}
          >
            {s.modals.addAction.searchActions}
          </p>
          <CustomButton
            title={s.modals.addAction.createAction}
            onClick={onAddAction}
            style={{
              ...fonts.medium14,
              color: getSearchColor('rex', isTestEnvironment)?.border,
              backgroundColor: getSearchColor('rex', isTestEnvironment)
                ?.background,
              border: `1px solid ${
                getSearchColor('rex', isTestEnvironment)?.border
              }`,
              flexGrow: 0,
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <p
            style={{
              ...fonts.medium14,
              marginRight: defaultMargin,
              alignSelf: 'flex-start',
              marginTop: smallMargin,
            }}
          >
            {s.modals.addAction.filters.title}
          </p>
          <Stack
            direction="row"
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: smallMargin,
            }}
          >
            <AdvancedSearchItem
              title={s.modals.addAction.filters.number}
              type="number"
              color={getSearchColor('rex', isTestEnvironment)?.main}
            />
            <AdvancedSearchItem
              title={s.modals.addAction.filters.titleDescription}
              type="text"
              color={getSearchColor('rex', isTestEnvironment)?.main}
            />
            <AdvancedSearchItem
              title={s.modals.addAction.filters.creationDate}
              type="date"
              color={getSearchColor('rex', isTestEnvironment)?.main}
            />
            <AdvancedSearchItem
              title={s.modals.addAction.filters.status}
              type="text"
              color={getSearchColor('rex', isTestEnvironment)?.main}
            />
            <AdvancedSearchItem
              title={s.modals.addAction.filters.dateStatus}
              type="date"
              color={getSearchColor('rex', isTestEnvironment)?.main}
            />
            <AdvancedSearchItem
              title={s.modals.addAction.filters.approveDate}
              type="date"
              color={getSearchColor('rex', isTestEnvironment)?.main}
            />
            <AdvancedSearchItem
              title={s.modals.addAction.filters.startDate}
              type="date"
              color={getSearchColor('rex', isTestEnvironment)?.main}
            />
            <AdvancedSearchItem
              title={s.modals.addAction.filters.endDate}
              type="date"
              color={getSearchColor('rex', isTestEnvironment)?.main}
            />
            <AdvancedSearchItem
              title={s.modals.addAction.filters.effectiveDate}
              type="date"
              color={getSearchColor('rex', isTestEnvironment)?.main}
            />
            <AdvancedSearchItem
              title={s.modals.addAction.filters.name}
              type="text"
              color={getSearchColor('rex', isTestEnvironment)?.main}
            />
          </Stack>
        </div>
        <div
          style={{
            marginTop: smallMargin,
            width: '100%',
            flexShrink: 1,
            overflowY: 'auto',
          }}
        >
          <CustomGrid
            rows={actions || []}
            columns={columns}
            rowSelectionState={rowSelectionState}
          />
        </div>
      </div>
      <FooterButtons onBack={onClose} onAdd={onClose} />
    </>
  );
};

interface AddActionContentProps {
  onBack: () => void;
  actionToEdit?: Action;
}

const AddActionContent: React.FC<AddActionContentProps> = ({
  onBack,
  actionToEdit,
}) => {
  const { createAction, updateAction } = useActionMutations();

  const [isLoading, setIsLoading] = useState(false);

  const [description, setDescription] = useState('');
  const [carrierName, setCarrierName] = useState('');
  const [status, setStatus] = useState('');
  const [approvalDate, setApprovalDate] = useState('');
  const [commissioningDate, setCommissioningDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    if (actionToEdit) {
      setDescription(actionToEdit.description);
      setCarrierName(actionToEdit.carrier_name);
      setStatus(actionToEdit.status);
      setApprovalDate(actionToEdit.approval_date || '');
      setCommissioningDate(actionToEdit.commissioning_date || '');
      setStartDate(actionToEdit.start_date || '');
      setEndDate(actionToEdit.end_date || '');
    }
  }, [actionToEdit]);

  const onSubmit = () => {
    setIsLoading(true);

    if (actionToEdit) {
      const updatedFields: UpdateAction = {};

      if (description !== actionToEdit.description)
        updatedFields.description = description;
      if (carrierName !== actionToEdit.carrier_name)
        updatedFields.carrier_name = carrierName;
      if (status !== actionToEdit.status) updatedFields.status = status;
      if (approvalDate !== actionToEdit.approval_date)
        updatedFields.approval_date = approvalDate;
      if (commissioningDate !== actionToEdit.commissioning_date)
        updatedFields.commissioning_date = commissioningDate;
      if (startDate !== actionToEdit.start_date)
        updatedFields.start_date = startDate;
      if (endDate !== actionToEdit.end_date) updatedFields.end_date = endDate;

      updateAction
        .mutateAsync({
          id: actionToEdit.id,
          action: updatedFields,
        })
        .finally(() => {
          setIsLoading(false);
          onBack();
        });
    } else {
      createAction
        .mutateAsync({
          description,
          carrier_name: carrierName,
          status,
          approval_date: approvalDate,
          start_date: startDate,
          end_date: endDate,
          commissioning_date: commissioningDate,
        })
        .finally(() => {
          setIsLoading(false);
          onBack();
        });
    }
  };

  const isEdit = !!actionToEdit;

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: defaultMargin,
          marginLeft: 150,
          marginRight: 150,
        }}
      >
        <p
          style={{
            ...fonts.bold20,
            textAlign: 'center',
            marginTop: 0,
          }}
        >
          {isEdit ? s.modals.addAction.titleEdit : s.modals.addAction.title}
        </p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginBottom: smallMargin,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              marginTop: defaultMargin,
              marginBottom: largeMargin,
            }}
            onClick={onBack}
          >
            <img
              src={arrowLeftIcon}
              alt=""
              style={{ marginRight: tinyMargin }}
            />
            <p
              style={{
                ...fonts.medium14,
                color: palette.gray600,
                margin: 0,
              }}
            >
              {s.common.button.back}
            </p>
          </div>
          <p
            style={{
              ...fonts.bold18,
              margin: 0,
              marginBottom: smallMargin,
            }}
          >
            {isEdit
              ? s.modals.addAction.titleEdit
              : s.modals.addAction.createAction}
          </p>
          <CustomTextInput
            label={s.rex.newRex.form.description}
            placeholder={s.rex.newRex.form.description}
            multiline
            optional
            numberOfLines={2}
            maxLength={255}
            state={[description, setDescription]}
          />
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            style={{ marginTop: defaultMargin }}
          >
            <Grid item xs={6} style={styles.gridItem}>
              <CustomTextInput
                placeholder={s.common.placeholders.name}
                label={s.modals.addAction.form.name}
                state={[carrierName, setCarrierName]}
              />
            </Grid>
            <Grid item xs={6} style={styles.gridItem}>
              <CustomDropdown
                label={s.modals.addAction.form.status}
                placeholder={s.rex.newRex.placeholders.selectValue}
                state={[status, setStatus]}
                values={actionStatus.map((status, index) => ({
                  value: status,
                  label: status,
                }))}
              />
            </Grid>
          </Grid>
          <Divider
            style={{
              backgroundColor: palette.gray200,
              marginTop: defaultMargin,
              marginBottom: defaultMargin,
            }}
          />
          <Grid
            container
            rowSpacing={`${defaultMargin}px`}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6} style={styles.gridItem}>
              <CustomTextInput
                placeholder={s.common.placeholders.date}
                label={s.modals.addAction.form.approve}
                optional
                optionalText={s.modals.addAction.soRelevant}
                state={[approvalDate, setApprovalDate]}
                type="date"
              />
            </Grid>
            <Grid item xs={6} style={styles.gridItem}>
              <CustomTextInput
                placeholder={s.common.placeholders.date}
                label={s.modals.addAction.form.startDate}
                optional
                optionalText={s.modals.addAction.soRelevant}
                state={[startDate, setStartDate]}
                type="date"
              />
            </Grid>
            <Grid item xs={6} style={styles.gridItem}>
              <CustomTextInput
                placeholder={s.common.placeholders.date}
                label={s.modals.addAction.form.endDate}
                optional
                optionalText={s.modals.addAction.soRelevant}
                state={[endDate, setEndDate]}
                type="date"
              />
            </Grid>
            <Grid item xs={6} style={styles.gridItem}>
              <CustomTextInput
                placeholder={s.common.placeholders.date}
                label={s.modals.addAction.form.effectiveDate}
                optional
                optionalText={s.modals.addAction.soRelevant}
                state={[commissioningDate, setCommissioningDate]}
                type="date"
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <FooterButtons
        onBack={onBack}
        onAdd={onSubmit}
        confirmLoading={isLoading}
        confirmDisabled={!carrierName || !status}
        isEdit={isEdit}
      />
    </>
  );
};

interface FooterButtonsProps {
  onBack: () => void;
  onAdd: () => void;
  confirmLoading?: boolean;
  confirmDisabled?: boolean;
  isEdit?: boolean;
}

const FooterButtons: React.FC<FooterButtonsProps> = ({
  onBack,
  onAdd,
  confirmLoading,
  confirmDisabled,
  isEdit,
}) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          marginTop: defaultMargin + smallMargin,
          alignSelf: 'flex-end',
        }}
      >
        <CustomButton
          title={s.common.button.cancel}
          onClick={onBack}
          style={{
            marginRight: defaultMargin,
            paddingLeft: defaultMargin + smallMargin,
            paddingRight: defaultMargin + smallMargin,
            backgroundColor: 'transparent',
            color: palette.gray600,
          }}
        />
        <CustomButton
          title={
            isEdit
              ? s.modals.addAction.editAction
              : s.modals.addAction.addAction
          }
          onClick={onAdd}
          style={{
            marginRight: defaultMargin,
            paddingLeft: defaultMargin + smallMargin,
            paddingRight: defaultMargin + smallMargin,
          }}
          loading={confirmLoading}
          disabled={confirmDisabled}
        />
      </div>
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  actionItem: {
    border: `1px solid ${palette.gray200}`,
    paddingRight: smallMargin,
    paddingLeft: smallMargin,
    borderRadius: mediumRadius,
  },
};
