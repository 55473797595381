import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { s } from '../../strings/strings';
import React from 'react';

interface RexPublicationDialogProps {
  state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  waitingValidation: boolean;
  visibilityLevel: string;
  onClose?: () => void;
}

export const RexPublicationDialog: React.FC<RexPublicationDialogProps> = ({
  state,
  waitingValidation,
  visibilityLevel,
  onClose,
}) => {
  const [open, setOpen] = state;

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };
  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          {waitingValidation
            ? s.rexPublicationDialog.waitingValidationTitle
            : s.rexPublicationDialog.publishedTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {waitingValidation
              ? s.rexPublicationDialog.waitingValidationContent(visibilityLevel)
              : s.rexPublicationDialog.publishedContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {s.common.button.ok}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
