import { SearchType } from "../../types/common/main";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { GridEventListener } from "@mui/x-data-grid";
import { RexSearchResultGrid } from "./rex/RexSearchResultGrid";
import { ActionSearchResultGrid } from "./action/ActionSearchResultGrid";
import { CauseSearchResultGrid } from "./cause/CauseSearchResultGrid";

interface CustomSearchGridResultProps {
  apiRef?: React.MutableRefObject<GridApiCommunity>;
  onRowClick?: GridEventListener<"rowClick"> | undefined;
  searchType: SearchType;
  results: any[];
}

export const CustomSearchGridResult: React.FC<CustomSearchGridResultProps> = ({
  apiRef,
  onRowClick,
  searchType,
  results,
}) => {
  return (
    <>
      {searchType === "rex" && (
        <RexSearchResultGrid
          apiRef={apiRef}
          onRowClick={onRowClick}
          results={results}
        />
      )}
      {searchType === "cause" && (
        <CauseSearchResultGrid
          apiRef={apiRef}
          onRowClick={onRowClick}
          results={results}
        />
      )}
      {searchType === "action" && (
        <ActionSearchResultGrid
          apiRef={apiRef}
          onRowClick={onRowClick}
          results={results}
        />
      )}
    </>
  );
};
