import React from 'react';
import { s } from '../../../strings/strings';
import { GridColDef } from '@mui/x-data-grid';
import { CustomGrid } from '../../common/CustomGrid';
import { useRexsToPublishMutations } from '../../../hooks/useRexsToPublishMutations';
import { FlightPhase } from '../../../types/common/api';
import { FlightPhaseChip } from '../../common/chip/FlightPhaseChip';
import { ReportTypeChip } from '../../common/chip/ReportTypeChip';
import { useNavigate } from 'react-router-dom';
import { getEntitiesStatsParam } from '../../../utils/stats';

interface DashboardUnpublishedRexsProps {
  style?: React.CSSProperties;
  selectedEntityIdx?: string;
  entityChildren?: string[];
}

export const DashboardUnpublishedRexs: React.FC<
  DashboardUnpublishedRexsProps
> = ({ style, selectedEntityIdx, entityChildren }) => {
  const navigate = useNavigate();
  const { rexsToPublish } = useRexsToPublishMutations(
    getEntitiesStatsParam(selectedEntityIdx, entityChildren)
  );

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: s.common.grid.id,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.id;
      },
    },
    {
      field: 'title',
      headerName: s.common.grid.title,
      flex: 2,
    },
    {
      field: 'event_date',
      headerName: s.common.grid.date,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        const eventDateString = row?.event_date;
        const date = eventDateString?.split('-')?.reverse()?.join('/');
        return date;
      },
    },
    {
      field: 'flight_phase',
      headerName: s.rex.reportToProcess.grid.flightPhase,
      renderCell: (params) => {
        const row = params.row;
        const flightPhase: FlightPhase = row?.flight_phase;
        return flightPhase ? (
          <FlightPhaseChip flightPhase={flightPhase} />
        ) : (
          <p>N/A</p>
        );
      },
      flex: 1,
    },
    {
      field: 'rex_type',
      headerName: s.common.grid.rexType,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.rex_type ? (
          <ReportTypeChip reportType={row.rex_type} />
        ) : (
          <p>N/A</p>
        );
      },
    },
    {
      field: 'flight_type',
      headerName: s.common.grid.flightType,
      flex: 1,
    },
    {
      field: 'event_kind',
      headerName: s.common.grid.classification,
      flex: 1,
    },
  ];

  return (
    <>
      <div style={{ ...style }}>
        <CustomGrid
          headerTitle={s.dashboard.grids.unpublishedRex}
          rows={rexsToPublish || []}
          columns={columns}
          onRowClick={(row) => navigate('/rex/' + row.id)}
        />
      </div>
    </>
  );
};
