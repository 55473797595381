import { colors, palette } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import { smallMargin } from '../../styles/styles';
import { CustomChip } from './chip/CustomChip';

interface Props {
  ids: number[];
  maxDisplayed?: number;
}

export const AssociatedRexList = ({ ids, maxDisplayed = 2 }: Props) => {
  const idsToDisplay = ids.slice(0, maxDisplayed);

  return (
    <div
      style={{ display: 'flex', columnGap: smallMargin, alignItems: 'center' }}
    >
      {idsToDisplay.map((id) => (
        <span
          key={id}
          style={{
            ...fonts.semiBold14,
            color: colors.textMedium,
            textDecoration: 'underline',
          }}
        >
          {id}
        </span>
      ))}
      {ids.length > maxDisplayed && (
        <CustomChip
          title={`+${ids.length - maxDisplayed}`}
          colors={{
            textColor: colors.textMedium,
            backgroundColor: palette.gray100,
          }}
        />
      )}
    </div>
  );
};
